import React, { useContext, useEffect, useState } from "react";
import { Select } from "antd";
import _ from "lodash";
import { WebParameterContext } from "../../contexts/webParameter";

const ChartOrderBy = ({ parent, updateData, data, axis }) => {
  const { _lineSort_, _barSort_, _setLineSort_, _setBarSort_ } =
    useContext(WebParameterContext);

  const [order, setOrder] = useState("DEFAULT");
  useEffect(() => {
    if (parent === "Line chart") {
      processData(_lineSort_);
    } else if (parent === "Bar chart") {
      processData(_barSort_);
    }
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   processData(order);
  //   // eslint-disable-next-line
  // }, [axis]);

  const processData = (value) => {
    setOrder(value);

    let d = data;
    if (value === "ASC") {
      d = _.orderBy(data, [axis], ["asc"]);
    } else if (value === "DESC") {
      d = _.orderBy(data, [axis], ["desc"]);
    } else {
      d = data;
    }
    updateData(d);
  };

  const handleChange = (value) => {
    if (parent === "Line chart") {
      _setLineSort_(value);
    } else if (parent === "Bar chart") {
      _setBarSort_(value);
    }

    processData(value);
  };

  return (
    <>
      <label style={{ marginRight: 10 }}>Order by</label>
      <Select value={order} style={{ width: 120 }} onChange={handleChange}>
        <Select.Option value="DEFAULT">DEFAULT</Select.Option>
        <Select.Option value="ASC">ASC</Select.Option>
        <Select.Option value="DESC">DESC</Select.Option>
      </Select>
    </>
  );
};

export default ChartOrderBy;
