import React, {useContext, useEffect, useState} from "react";
import "./TableView.css";
// import Highlighter from "react-highlight-words";
// import { Table, Input, Space, Button } from "antd";
import { Table, ConfigProvider } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { WebParameterContext } from "../../contexts/webParameter";
import th_TH from "antd/es/locale/th_TH";
import { DataContext } from "../../contexts/data";

const TableView = ({ data, schema, updateSorter }) => {
  const { _tableSorter_ } = useContext(WebParameterContext);
  const { _fieldList_ } = useContext(DataContext);
  const [columnsList, setColumnList] = useState([])
  const [loading, setLoading] = useState(false);
  useEffect(()=>{
    setLoading(false)
    const columnsLst = [];
    let currentSorter = [..._tableSorter_]
    Object.keys(schema).forEach((key,idx) => {
      let getSorterIdx = _tableSorter_.findIndex(({columnKey}) => columnKey === key)
      if (key !== "uuid") {
        const column = {
          // title: key,
          key: key,
          dataIndex: key,
          width: 200,
          sortOrder: getSorterIdx !== -1 ? _tableSorter_[getSorterIdx].order: null,
          sorter: {
            multiple: idx+1
          },
          render:(text, record, index)=>{
            if(typeof text === "number" && !_fieldList_.includes(key))
              return new Intl.NumberFormat().format(+text)
            return text
          },
          title: ({ sortColumns }) => {
            const sortedColumn = sortColumns?.find(({ column }) => column.key === key);
            return (
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                {key}
                {sortedColumn ? (
                  sortedColumn.order === "ascend" ? (
                    <CaretUpOutlined className="custom-sorter sort-active" />
                  ) :
                  sortedColumn.order === "descend" ? (
                    <CaretDownOutlined className="custom-sorter sort-active" />
                  ) :
                  <span className="custom-column-sorter custom-column-sorter-full">
                    <span className="custom-column-sorter-inner">
                      <CaretUpOutlined className="custom-column-sorter-up"/>
                      <CaretDownOutlined className="custom-column-sorter-down"/>
                    </span>
                  </span>
                ) : null}
              </div>
            )
          },
          onHeaderCell: (column) => {
            return {
              onClick: () => {
                setLoading(true)
                let keyIdx = currentSorter.findIndex(({columnKey}) => columnKey === column.key)
                if(keyIdx !== -1){//update order or remove
                  if(column.sortOrder === "ascend"){
                    currentSorter[keyIdx]["order"] = "descend"
                  }
                  else{//if current click the sortOrder is descend it will be cancel sort then remove from array
                    currentSorter.splice(keyIdx,1)
                  }
                }else{//sort this column.key first time it's always ascend
                  currentSorter = [...currentSorter, {columnKey: column.key, order: "ascend"}]
                }
                updateSorter(currentSorter)
              }
            };
          }
        };
        columnsLst.push(column);
      }
    });
    setColumnList(columnsLst)
    // eslint-disable-next-line
  }, [schema, data])
  /*const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState(0);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`ค้นหา ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            ค้นหา
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            ล้าง
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });*/


  return (
    <ConfigProvider locale={th_TH}>
      <div className="TableView">
        <Table
          className="table-view-table"
          rowKey="uuid"
          columns={columnsList}
          dataSource={data}
          bordered
          pagination={false}
          scroll={{ y: "calc(100vh - 16em)", x: "100%" }}
          loading={loading}
        />
      </div>
    </ConfigProvider>
  );
};

export default React.memo(TableView);
