import React, { forwardRef } from "react";
import "./AppContent.css";
import { Layout } from "antd";

import DataContent from "../../pages/DataContent";
import MapContent from "../../pages/MapContent";
import ChartContent from "../../pages/ChartContent";
import SettingContent from "../../pages/SettingContent";

const { Content } = Layout;

const AppContent = forwardRef(({ tabIndex, dataInfo }, ref) => {
  return (
    <Content className="custom-content">
      <div
        className={
          tabIndex === 1 ? "custom-content-tab" : "hide custom-content-tab"
        }
      >
        <DataContent />
      </div>
      <div
        className={
          tabIndex === 2 ? "custom-content-tab" : "hide custom-content-tab"
        }
      >
        <MapContent ref={ref} dataInfo={dataInfo}/>
      </div>
      <div
        className={
          tabIndex === 3 ? "custom-content-tab" : "hide custom-content-tab"
        }
      >
        <ChartContent dataInfo={dataInfo} tabIndex={tabIndex} />
      </div>
      <div
        className={
          tabIndex === 4 ? "custom-content-tab" : "hide custom-content-tab"
        }
      >
        <SettingContent dataInfo={dataInfo}/>
      </div>
    </Content>
  );
});

export default AppContent;
