import React, { useContext, useEffect, useRef, useState } from "react";
import "./Scorecard.css";
import {Card, Row, Col, Button} from "antd"
import { EditOutlined, CheckOutlined } from "@ant-design/icons";
import { WebParameterContext } from "../../../contexts/webParameter";
import { DataContext } from "../../../contexts/data";

const Scorecard = ({data, yAxis}) =>{
    const {_embed_, _scoreCardLabel_, _setScorecardLabel_, _cardColor_} = useContext(WebParameterContext);
    const { _fieldList_ } = useContext(DataContext)
    const [resultData, setResultData] = useState([])
    const [compareKey, setCompareKey] = useState({})
    const inputRef = useRef([])
    useEffect(()=>{
        let result = [];
        if(data.length > 0){
            for (const [key, value] of Object.entries(data[0])) {
                if(_scoreCardLabel_.some(({attr})=>attr === key)){
                    for(let i = 0; i < _scoreCardLabel_.length; i++){
                        if(key === _scoreCardLabel_[i].attr){
                            result = [...result, {attr: key, value: value, editLabel: false, newLabel: _scoreCardLabel_[i].newLabel}]
                        }
                    }
                }
                else{
                    result = [...result, {attr: key, value: value, editLabel: false}]
                }
            }
        }

        //reduce yAxis for sort pattern
        //reduce yAxis for compare key
        const sortPattern = yAxis.reduce((a, v, i) =>{
            let obj
            if (v.agg !== "" && !v.content.includes(v.agg)) {
                obj = Object.assign(a, {
                    [`${v.agg}(${v.content})`]: i+1
                })
            }
            if (v.agg === "") {
                obj = Object.assign(a, {
                    [v.content]: i+1
                })
            }
            return obj
        },{})
        const newObj = yAxis.reduce((a, v, i) => {
            let obj
            if (v.agg !== "" && !v.content.includes(v.agg)) {
                obj = Object.assign(a, {
                    [`${v.agg}(${v.content})`]: v.content
                })
            }
            if (v.agg === "") {
                obj = Object.assign(a, {
                    [v.content]: v.content
                })
            }
            return obj
        }, {})
        setCompareKey(newObj)

        //sort as y axis sequence case yAxis arr contains agg
        // const sortPattern = yAxis.reduce((a, v, i) => ({ ...a, [v]: i+1}), {})
        result.sort(function(a, b){ return sortPattern[a.attr] - sortPattern[b.attr]; });
        setResultData(result)
        // eslint-disable-next-line
    },[data])

    const handleClickEdit = (item, is_save=false, idx) =>{
        let newList = []
        if(is_save){
            newList = resultData.map((elm, elm_idx)=>{
                if((elm.attr === item) && elm.editLabel){
                    elm.editLabel = false
                    if(inputRef.current[elm_idx].innerText !== ""){
                        elm.newLabel =  inputRef.current[elm_idx].innerText
                    }
                    else{
                        elm.newLabel = elm.attr
                        inputRef.current[elm_idx].innerText = elm.attr
                    }
                    return {...elm}
                }
                return {...elm, newLabel: elm.newLabel ?  elm.newLabel : elm.attr }
            })
            _setScorecardLabel_(newList)
        }else{
            newList = resultData.map((elm)=>{
                if(elm.attr === item){
                    return {...elm, editLabel: !elm.editLabel}
                }
                return elm
            })
        }
        setResultData(newList)
    }
    return (
        <Row gutter={[16, 16]} style={{paddingRight: '10px', marginRight: 0, marginLeft: 0}}>
            {
                resultData.map((item, index)=>(
                    <Col
                    flex="auto"
                        key={index}
                        style={{height:'100%',maxWidth: 'max-content'}}
                    >
                        <Card
                            style={{
                                backgroundColor: `rgba(${_cardColor_.background.r}, ${_cardColor_.background.g}, ${_cardColor_.background.b}, ${_cardColor_.background.a})`,
                                borderRadius: '5px',
                                height:'100%',
                            }}
                        >
                            {!_embed_
                            ? !item.editLabel ? <Button className="edit-content" onClick={()=>handleClickEdit(item.attr, false, index)}><EditOutlined /></Button> : <Button className="edit-content" onClick={()=>handleClickEdit(item.attr, true, index)}><CheckOutlined/></Button>
                            : null}
                            <div>
                                <div>
                                    <h2 className="card-label" style={{color: `rgba(${_cardColor_.title.r}, ${_cardColor_.title.g}, ${_cardColor_.title.b}, ${_cardColor_.title.a})`}} contentEditable={item.editLabel} suppressContentEditableWarning={true} ref={el => inputRef.current[index] = el}>{item.newLabel ? item.newLabel : item.attr}</h2>
                                </div>
                            </div>
                            <div style={{fontSize: '2.5rem',wordBreak: 'break-all', color: `rgba(${_cardColor_.number.r}, ${_cardColor_.number.g}, ${_cardColor_.number.b}, ${_cardColor_.number.a})`}}>
                                {_fieldList_.includes(compareKey[item.attr]) ? item.value : new Intl.NumberFormat().format(item.value)}
                            </div>
                        </Card>
                    </Col>
                ))
            }
        </Row>
    )
}
export default Scorecard