import React, { useState, useContext } from "react";
import "./SettingContent.css";
import { Card, Typography, Row, Col, Tabs, Button, message } from "antd";
import { ShareAltOutlined } from "@ant-design/icons";
import { WebParameterContext } from "../../contexts/webParameter";
import copy from "copy-to-clipboard";
import { getFilterStr, getSelectedChartAxis, getEditLabel, getSortTableStr } from "../../helpers";

// const { TabPane } = Tabs;
const { Paragraph } = Typography;

const SettingContent = ({ dataInfo }) => {
  const {
    _page_,
    _pageSize_,
    _mainFilter_,
    _chartFilter_,
    _selectedState_,
    _selectedStateFromShare_,
    _currBaseMap_,
    _chart_,
    _lineSort_,
    _barSort_,
    _location_,
    _scoreCardLabel_,
    _tableSorter_
  } = useContext(WebParameterContext);

  const [mapEmbedCode, setMapEmbedCode] = useState("");
  const [mapLink, setMapLink] = useState("");
  const [chartEmbedCode, setChartEmbedCode] = useState("");
  const [chartLink, setChartLink] = useState("");

  let path = window.location.pathname;
  path = path.replace("/", "");
  const source = path.substring(0, path.indexOf("/"));
  const id = path.substring(path.indexOf("/") + 1, path.length);

  const createMapEmbedCode = () => {
    const mapBase = `?map&base=${_currBaseMap_}`;
    const lat = _location_.lat !== "" ? `&lat=${_location_.lat}` : "";
    const lng = _location_.lng !== "" ? `&lng=${_location_.lng}` : "";
    const limit = `&limit=${_pageSize_}`;
    const offset = `&offset=${(_page_ - 1) * _pageSize_}`;
    const filterStr = getFilterStr(_mainFilter_, "main");
    let embed = `${window.location.origin}${window.location.pathname}${mapBase}${lat}${lng}${limit}${offset}${filterStr}`;

    embed = encodeURI(embed);

    const code = `<iframe src="${embed}" frameBorder="0"/>`;
    setMapEmbedCode(code);
  };
  const createMapLink = () => {
    const mapBase = `?map&base=${_currBaseMap_}`;
    const lat = _location_.lat !== "" ? `&lat=${_location_.lat}` : "";
    const lng = _location_.lng !== "" ? `&lng=${_location_.lng}` : "";
    const limit = `&limit=${_pageSize_}`;
    const offset = `&offset=${(_page_ - 1) * _pageSize_}`;
    const filterStr = getFilterStr(_mainFilter_, "main");
    let embed = `${window.location.origin}${window.location.pathname}${mapBase}${lat}${lng}${limit}${offset}${filterStr}`;

    embed = encodeURI(embed);

    const code = `${embed}`;
    setMapLink(code);
  };

  const createChartEmbedCode = () => {
    const chartFilterStr = getFilterStr(_chartFilter_, "chart");
    let selectedStateStr = getSelectedChartAxis(_selectedState_);
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("selected")) {
      selectedStateStr = getSelectedChartAxis(_selectedStateFromShare_);
    }
    let scoreCardLabel = "";
    if(_chart_ === "Scorecard"){
      scoreCardLabel = getEditLabel(_scoreCardLabel_)
    }

    const lineSort = _chart_ === "Line chart" ? `&ls=${_lineSort_}` : "";
    const barSort = _chart_ === "Bar chart" ? `&bs=${_barSort_}` : "";

    let embed = `${window.location.origin}${window.location.pathname}?chart&charttype=${_chart_}${lineSort}${barSort}${chartFilterStr}${selectedStateStr}${scoreCardLabel?scoreCardLabel:""}`;

    embed = encodeURI(embed);

    const code = `<iframe src="${embed}" frameBorder="0"/>`;
    setChartEmbedCode(code);
  };

  const createChartLink = () => {
    const chartFilterStr = getFilterStr(_chartFilter_, "chart");
    let selectedStateStr = getSelectedChartAxis(_selectedState_);
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("selected")) {
      selectedStateStr = getSelectedChartAxis(_selectedStateFromShare_);
    }
    let scoreCardLabel = "";
    if(_chart_ === "Scorecard"){
      scoreCardLabel = getEditLabel(_scoreCardLabel_)
    }

    const lineSort = _chart_ === "Line chart" ? `&ls=${_lineSort_}` : "";
    const barSort = _chart_ === "Bar chart" ? `&bs=${_barSort_}` : "";

    let embed = `${window.location.origin}${window.location.pathname}?chart&charttype=${_chart_}${lineSort}${barSort}${chartFilterStr}${selectedStateStr}${scoreCardLabel?scoreCardLabel:""}`;

    embed = encodeURI(embed);

    const code = `${embed}`;
    setChartLink(code);
  };

  const copySharableLink = () => {
    let sharedUrl = window.location.origin + window.location.pathname;
    const filterStr = getFilterStr(_mainFilter_, "main");
    const chartFilterStr = getFilterStr(_chartFilter_, "chart");

    const lineSort = _chart_ === "Line chart" ? `&ls=${_lineSort_}` : "";
    const barSort = _chart_ === "Bar chart" ? `&bs=${_barSort_}` : "";

    let selectedStateStr = getSelectedChartAxis(_selectedState_);
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("selected")) {
      selectedStateStr = getSelectedChartAxis(_selectedStateFromShare_);
    }
    let scoreCardLabel = "";
    if(_chart_ === "Scorecard"){
      scoreCardLabel = getEditLabel(_scoreCardLabel_)
    }
    const sortTableStr = getSortTableStr(_tableSorter_)

    sharedUrl += `?limit=${_pageSize_}&offset=${
      (_page_ - 1) * _pageSize_
    }${filterStr}&charttype=${_chart_}${lineSort}${barSort}${chartFilterStr}${selectedStateStr}${scoreCardLabel?scoreCardLabel:""}${sortTableStr?sortTableStr:""}`;
    sharedUrl = encodeURI(sharedUrl);

    copy(sharedUrl);
    message.success("คัดลอกลิงก์สำหรับแชร์แล้ว", 1);
  };

  return (
    <div className="SettingContent">
      <Row
        align="middle"
        justify="center"
        style={{ marginBottom: 10, marginTop: 10 }}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
          <Card title="ข้อมูลทั่วไป" style={{ textAlign: "left" }}>
            <Paragraph>
              <b>ชื่อ:</b> {dataInfo.value.package_title}[
              {dataInfo.value.resource_title}]
            </Paragraph>
            <Paragraph>
              <b>ที่มา:</b> {source}
            </Paragraph>
            <Paragraph>
              <b>ไอดี:</b> {id}
            </Paragraph>
            <Paragraph>
              <b>ลิงก์อ้างอิง:</b>{" "}
              <a href={dataInfo.value.ref_url}>{dataInfo.value.ref_url}</a>
            </Paragraph>
          </Card>
        </Col>
      </Row>
      <Row align="middle" justify="center" style={{ marginBottom: 10 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
          <Card title="การแชร์" style={{ textAlign: "left" }}>
            <Button
              type="primary"
              icon={<ShareAltOutlined />}
              onClick={copySharableLink}
            >
              คัดลอกลิงค์สำหรับแชร์เพลกราวด์
            </Button>
          </Card>
        </Col>
      </Row>
      <Row align="middle" justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
          <Card
            title="การฝัง"
            style={{ textAlign: "left" }}
            bodyStyle={{ paddingTop: 0 }}
          >
            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  label: "แผนที่",
                  key: 1,
                  style: {
                    textAlign: "left",
                    height: "100%",
                  },
                  children: (
                    <>
                      <Button
                        type="primary"
                        style={{ marginTop: 20 }}
                        onClick={createMapEmbedCode}
                      >
                        สร้างโค้ดสำหรับฝัง
                      </Button>
                      {mapEmbedCode !== "" && (
                        <Card style={{ marginTop: 10 }}>
                          <Paragraph copyable style={{ margin: 0 }}>
                            {mapEmbedCode}
                          </Paragraph>
                        </Card>
                      )}
                      <br />
                      <Button
                        type="primary"
                        style={{ marginTop: 20 }}
                        onClick={createMapLink}
                      >
                        ลิงค์สำหรับแสดงผล
                      </Button>
                      {mapLink !== "" && (
                        <Card style={{ marginTop: 10 }}>
                          <Paragraph copyable style={{ margin: 0 }}>
                            {mapLink}
                          </Paragraph>
                        </Card>
                      )}
                    </>
                  ),
                },
                {
                  label: "แผนภูมิ",
                  key: 2,
                  style: {
                    textAlign: "left",
                    height: "100%",
                  },
                  children: (
                    <>
                      <Button
                        type="primary"
                        style={{ marginTop: 20 }}
                        onClick={createChartEmbedCode}
                      >
                        สร้างโค้ดสำหรับฝัง
                      </Button>
                      {chartEmbedCode !== "" && (
                        <Card style={{ marginTop: 10 }}>
                          <Paragraph copyable style={{ margin: 0 }}>
                            {chartEmbedCode}
                          </Paragraph>
                        </Card>
                      )}
                      <br />
                      <Button
                        type="primary"
                        style={{ marginTop: 20 }}
                        onClick={createChartLink}
                      >
                        ลิงค์สำหรับแสดงผล
                      </Button>
                      {chartLink !== "" && (
                        <Card style={{ marginTop: 10 }}>
                          <Paragraph copyable style={{ margin: 0 }}>
                            {chartLink}
                          </Paragraph>
                        </Card>
                      )}
                    </>
                  ),
                },
              ]}
            >
              {/* <TabPane
                tab="แผนที่"
                key="1"
                style={{
                  textAlign: "left",
                  height: "100%",
                }}
              >
                <Button
                  type="primary"
                  style={{ marginTop: 20 }}
                  onClick={createMapEmbedCode}
                >
                  สร้างโค้ดสำหรับฝัง
                </Button>
                {mapEmbedCode !== "" && (
                  <Card style={{ marginTop: 10 }}>
                    <Paragraph copyable style={{ margin: 0 }}>
                      {mapEmbedCode}
                    </Paragraph>
                  </Card>
                )}
                <br />
                <Button
                  type="primary"
                  style={{ marginTop: 20}}
                  onClick={createMapLink}
                >
                  ลิงค์สำหรับแสดงผล
                </Button>
                { mapLink !== "" && (
                  <Card style={{ marginTop: 10 }}>
                    <Paragraph copyable style={{ margin: 0}}>
                      {mapLink}
                    </Paragraph>
                  </Card>
                )}
              </TabPane>
              <TabPane
                tab="แผนภูมิ"
                key="2"
                style={{ textAlign: "left", height: "100%" }}
              >
                <Button
                  type="primary"
                  style={{ marginTop: 20 }}
                  onClick={createChartEmbedCode}
                >
                  สร้างโค้ดสำหรับฝัง
                </Button>
                {chartEmbedCode !== "" && (
                  <Card style={{ marginTop: 10 }}>
                    <Paragraph copyable style={{ margin: 0 }}>
                      {chartEmbedCode}
                    </Paragraph>
                  </Card>
                )}
                <br />
                <Button
                  type="primary"
                  style={{ marginTop: 20}}
                  onClick={createChartLink}
                >
                  ลิงค์สำหรับแสดงผล
                </Button>
                { chartLink !== "" && (
                  <Card style={{ marginTop: 10 }}>
                    <Paragraph copyable style={{ margin: 0}}>
                      {chartLink}
                    </Paragraph>
                  </Card>
                )}
              </TabPane> */}
            </Tabs>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SettingContent;
