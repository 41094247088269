import React from "react";
import "./AppNavbar.css";
import { Menu, Layout } from "antd";
import {
  DatabaseOutlined,
  AreaChartOutlined,
  EnvironmentOutlined
} from "@ant-design/icons";

const { Header } = Layout;

const AppNavbar = ({ onChangeTab, showComponent, defaultOpenKey }) => {
  return (
    <>
      {showComponent && (
        <Header style={{ padding: "0 10px" }}>
          <div className="custom-navbar-xs-opend-logo">
            <img
              src={require("../../assets/images/logo.png")}
              alt="opend-logo"
            />
          </div>
          <div className="custom-navbar-xs-nectec-logo">
            <img
              src={require("../../assets/images/nectec.png")}
              alt="nectec-logo"
            />
          </div>
          <Menu
            theme="dark"
            defaultSelectedKeys={[defaultOpenKey]}
            mode="horizontal"
            onClick={(e) => onChangeTab(Number(e.key))}
            items={[
              { key: 1, icon: <DatabaseOutlined /> },
              { key: 3, icon: <AreaChartOutlined /> },
              { key: 2, icon: <EnvironmentOutlined /> },
              // {
              //   key: 4,
              //   icon: <SettingFilled />,
              //   style: {
              //     position: "fixed",
              //     right: 10,
              //     transition: "0.2s",
              //   },
              // },
            ]}
          >
            {/* <Menu.Item
              key="1"
              icon={<DatabaseOutlined />}
              onClick={() => onChangeTab(1)}
            />
            <Menu.Item
              key="3"
              icon={<AreaChartOutlined />}
              onClick={() => onChangeTab(3)}
            />
            <Menu.Item
              key="4"
              icon={<SettingFilled />}
              onClick={() => onChangeTab(4)}
              style={{
                position: "fixed",
                right: 10,
                transition: "0.2s",
              }}
            /> */}
          </Menu>
        </Header>
      )}
    </>
  );
};

export default AppNavbar;
