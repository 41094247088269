import React, { useState, useEffect, useMemo, useContext } from "react";
import * as styles from "./DynamicConditionFormItem";
import "./DynamicConditionFormItem.css";
import { Form, Input, Select, Button, Tooltip } from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
  FieldTimeOutlined,
  FieldNumberOutlined,
  FieldStringOutlined
} from "@ant-design/icons";
import { DataContext } from "../../contexts/data";

const DynamicConditionFormItem = ({updateForm, chart, updateMapField, updateFormInput, formValue}) => {
  const { _schema_ } = useContext(DataContext);
  const [options, setOptions] = useState({ options: {}, isUpdated: false });
  const [optionByType,setOptionByType] = useState([])
  const numberOption = [
    {name: "=", desc:"เท่ากับ"},
    {name: "<", desc:"น้อยกว่า"},
    {name: "<=", desc:"น้อยกว่าเท่ากับ"},
    {name: ">", desc:"มากกว่า"},
    {name: ">=", desc:"มากกว่าเท่ากับ"},
    {name: "!=", desc:"ไม่เท่ากับ"},
    {name: "Not Null", desc:"ไม่มีค่า"}
  ]
  const strOption = [
    {name: "Contains", desc:"ประกอบด้วย"},
    {name: "Not Contains", desc:"ไม่มี"},
    {name: "Equals", desc:"เท่ากับ"},
    {name: "Not Equals", desc:"ไม่เท่ากับ"},
    {name: "RegEx", desc:"Regular expression"},
    {name: "Not Null", desc:"ไม่มีค่า"}
  ]

  useEffect(() => {
    const getTypeSymbol = (type) =>{
      if(type === "number")
        return <FieldNumberOutlined style={{backgroundColor: "#002766",}} className="icon-type" />
      if(type === "date")
        return <FieldTimeOutlined style={{backgroundColor: "#391085"}} className="icon-type" />
      return <FieldStringOutlined style={{backgroundColor: "#189FF0"}} className="icon-type" />
    }
    const createOptions = () => {
      // let _options = Object.keys(_schema_.value);
      let _options = Object.entries(_schema_.value);
      const uniqueIndex = _options.findIndex(elm=> elm[0] === "unique");
      if (uniqueIndex > -1) {
        _options.splice(uniqueIndex, 1);
      }
      if(chart === "Heatmap"){
        let filterHeatmap = updateMapField.value.filter(item=>item.newContent?.toLowerCase() !== "province" && item.newContent?.toLowerCase() !== "district" && item.newContent?.toLowerCase() !== "sub_district" && item.content !== "unique")
        _options = filterHeatmap.map((item)=>{
          return [item.content,item.type]
        })
      }
      _options = _options.map((_option) => {
        return (
          <Select.Option key={_option[0]} value={_option[0]} title={_option[0]}>
            <span>{getTypeSymbol(_option[1])} <span>{_option[0]}</span></span>
          </Select.Option>
        );
      });
      setOptions({ options: _options, isUpdated: true });
    };
    if (_schema_.isUpdated) {
      createOptions();
    }
  }, [_schema_, chart, updateMapField]);

  useMemo(()=>{
    if(formValue?.length > 0){
      let _tempOption = []
      for(let i = 0; i < formValue.length; i++){
        if(formValue[i].col){
          let getType = _schema_.value[formValue[i].col]
          let isInt = getType === "number" || getType === "date" ? true : false
          let obj = {
            option: isInt ? numberOption : strOption,
            type: getType === "number" || getType === "date" ? "ระบุตัวเลข" : "ระบุข้อความ"
          }
          if(formValue[i].operator === "Not Null"){
            obj['disabled'] = true
          }
          _tempOption.push(obj)
        }
      }
      setOptionByType(_tempOption)
    }
    // eslint-disable-next-line
  }, [formValue])

  return useMemo(() => {
    const handleChange = (idx, value) =>{
      updateForm(idx)
      let getType = _schema_.value[value]
      var isInt = getType === "number" || getType === "date" ? true : false
      var _optionByType = JSON.parse(JSON.stringify(optionByType))
      // const exist = _optionByType.filter(f => f.idx === idx);
      if(_optionByType[idx]){
        _optionByType[idx].option = isInt ? numberOption : strOption
        _optionByType[idx].type = getType === "number" || getType === "date" ? "ระบุตัวเลข" : "ระบุข้อความ"
      }else{
        _optionByType.push({
          // idx: idx,
          option: isInt ? numberOption : strOption,
          type: getType === "number" || getType === "date" ? "ระบุตัวเลข" : "ระบุข้อความ"
        })
      }
      setOptionByType(_optionByType)
    }
    const removeOption = (index) =>{
      var _optionByType = JSON.parse(JSON.stringify(optionByType))
      _optionByType.splice(index, 1)
      setOptionByType(_optionByType)
    }
    const handleOperandChange = (index, value) =>{
      updateFormInput(index)
      var _optionByType = JSON.parse(JSON.stringify(optionByType))
      if(value === "Not Null"){
        _optionByType[index].disabled = true
      }
      else{
        _optionByType[index].disabled = false
      }
      setOptionByType(_optionByType)
    }
    return (
      <div className="ConditionQueryForm">
        {options.isUpdated ? (
          <Form.List name="ConditionForm" >
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <div key={field.key}>
                    {index > 0 &&
                      <div className="ant-row" style={{marginBottom: '10px'}}>
                        <Form.Item
                          name={[index, "logic"]}
                          initialValue="AND"
                          className="ant-col ant-col-8"
                          style={{marginBottom: 0}}
                        >
                          <Select dropdownStyle={styles.dropdownStyle}>
                            <Select.Option value='AND'>AND</Select.Option>
                            <Select.Option value='OR'>OR</Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                    }
                    <div className="ant-row" style={{marginBottom: '10px'}}>
                        <Form.Item
                          name={[index, "col"]}
                          rules={[
                            { required: true, message: "กรุณาระบุเงื่อนไข" },
                          ]}
                          className="ant-col ant-col-8"
                          style={{marginBottom: 0}}
                        >
                          <Select showSearch dropdownStyle={styles.dropdownStyle} onChange={(value)=>handleChange(index, value)}>
                            {options.options}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[index, "operator"]}
                          rules={[
                            { required: true, message: "กรุณาระบุเงื่อนไข" },
                          ]}
                          className="ant-col ant-col-7"
                          style={{paddingLeft:'5px', marginBottom: 0}}
                        >{
                          optionByType.length > index
                          ?
                          <Select dropdownStyle={styles.dropdownStyle} onChange={(value)=>handleOperandChange(index, value)}>
                            {
                              optionByType[index].option.map((items, idx)=>(
                                <Select.Option value={items.name} key={idx} onClear>
                                  <Tooltip placement="left" title={items.desc} ><div>{items.name}</div></Tooltip>
                                </Select.Option>
                              ))
                            }
                          </Select>
                          :
                          <Select dropdownStyle={styles.dropdownStyle}>
                          </Select>

                          }

                        </Form.Item>
                        <Form.Item
                          name={[index, "condition"]}
                          rules={[
                            { required: optionByType.length > index ? !optionByType[index].disabled : true, message: "กรุณาระบุเงื่อนไข" },
                          ]}
                          style={{paddingLeft:'5px', marginBottom: 0}}
                          className="ant-col ant-col-8"
                        >
                          <Input type="text" allowClear placeholder={optionByType.length > index ? optionByType[index].type : ""} disabled={optionByType.length > index ? optionByType[index].disabled : false}></Input>
                        </Form.Item>
                      {fields.length > 0 ? (
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(field.name);
                            removeOption(index);
                          }}
                          className="ant-col ant-col-1"
                          style={{marginTop: '5px'}}
                        />
                      ) : null}
                    </div>
                    </div>
                  ))}
                  <Form.Item style={styles.addButtonFormItem}>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={styles.addButton}
                    >
                      <PlusOutlined /> เพิ่มเงื่อนไข
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        ) : (
          <LoadingOutlined style={styles.loadingIcon} />
        )}
      </div>
    );
  }, [options, _schema_, optionByType, setOptionByType, numberOption, strOption, updateForm, updateFormInput]);
};

export default DynamicConditionFormItem;
