import React from "react";
import "./MapGroupingForm.css";
import * as styles from "./MapGroupingForm";
import { Form, Input, Button, Collapse } from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import ColorPicker from "../ColorPicker";
import MapSelectDataGroupToggle from "../MapSelectDataGroupToggle";

const MapGroupingForm = ({ onFinishForm }) => {
  const defaultColor = "#ffa39e";
  const delBtn = (field, remove) => (
    <MinusCircleOutlined
      className="dynamic-delete-button"
      onClick={() => {
        remove(field.name);
      }}
    />
  );

  const onFinish = (values) => {
    onFinishForm(values);
  };
  const [mapGroupForm] = Form.useForm();

  const onChangeColorComplete = (index, color) => {
    const lst = mapGroupForm.getFieldsValue("DynamicMapGroupingFormList");
    lst["DynamicMapGroupingFormList"][index]["color"] = color;
    mapGroupForm.setFieldsValue({
      DynamicMapGroupingFormList: lst["DynamicMapGroupingFormList"],
    });
  };

  // const onChangeGroupColorComplete = (index, color) => {
  //   const lst = mapGroupForm.getFieldsValue("DynamicMapGroupingFormList");
  //   lst["groupColor"] = color;
  //   mapGroupForm.setFieldsValue({
  //     DynamicMapGroupingFormList: lst["DynamicMapGroupingFormList"],
  //   });
  // };

  return (
    <div className="MapGroupingForm">
      <Form name="mapGroupForm" form={mapGroupForm} onFinish={onFinish}>
        <Form.Item name="groupName">
          <Input placeholder="ชื่อกลุ่ม" />
        </Form.Item>

        {/* <Form.Item>
          <ColorPicker
            defaultColor={defaultColor}
            width={"100%"}
            onChangeColorComplete={onChangeGroupColorComplete}
          />
        </Form.Item> */}

        <div id="scrollbar-style" className="map-grouping-container">
          <Form.List name="DynamicMapGroupingFormList">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <Collapse
                      key={field.key}
                      defaultActiveKey={[index]}
                      bordered={false}
                      expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                      )}
                      expandIconPosition="left"
                    >
                      <Collapse.Panel
                        header={`กลุ่มย่อย ${index + 1}`}
                        key={index}
                        extra={delBtn(field, remove)}
                      >
                        <div>
                          <Form.Item
                            name={[index, "name"]}
                            rules={[
                              { required: true, message: "กรุณาระบุชื่อ" },
                            ]}
                            style={styles.nameInputFormItem}
                          >
                            <Input placeholder="ชื่อ" />
                          </Form.Item>

                          <Form.Item>
                            <ColorPicker
                              defaultColor={defaultColor}
                              width={"100%"}
                              index={index}
                              onChangeColorComplete={onChangeColorComplete}
                            />
                          </Form.Item>

                          <Form.Item>
                            <MapSelectDataGroupToggle form={mapGroupForm} index={index}/>
                          </Form.Item>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  ))}

                  <Form.Item style={styles.addBtnFormItem}>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={styles.addBtn}
                    >
                      <PlusOutlined /> เพิ่มกลุ่มย่อย
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            ตกลง
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default MapGroupingForm;
