import React, { useState, useEffect } from "react";
import { SketchPicker } from 'react-color'

const ColorSketchPicker = ({defaultColor, updateColor, displayPicker}) => {
    const [color, setColor] = useState(defaultColor);
    const [displayColorPicker, setDisplayColorPicker] = useState(false)
    
    useEffect(() => {
        setColor(defaultColor)
        updateColor(defaultColor);
        // eslint-disable-next-line
    }, [defaultColor]);

    useEffect(()=>{
        setDisplayColorPicker(false)
    },[displayPicker])
    const handleChange = (color) => {
        setColor(color.rgb)
        updateColor(color.rgb)
    };
    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker)
    };
    const handleClose = () => {
        setDisplayColorPicker(false)
    };
    const style = {
        color: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
        },
        swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
        },
        popover: {
            position: 'absolute',
            zIndex: '2',
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
    }
    return (
        <div>
            <div style={style.swatch} onClick={ handleClick }>
                <div style={style.color } />
            </div>
            {
            displayColorPicker ?
                <div style={style.popover}>
                    <div style={style.cover} onClick={ handleClose }
                    />
                    <SketchPicker color={color} onChange={handleChange } />
                </div>
            : null
            }
        </div>
    )
}

export default ColorSketchPicker