import React, { useState, useEffect, useContext } from "react";
import {
  Chart,
  Line,
  Point,
  Tooltip,
  Slider,
  Legend,
  Axis
} from "bizcharts";
import { WebParameterContext } from "../../../contexts/webParameter";
import ChartOrderBy from "../../ChartOrderBy";
import { getFormatNumber } from "../../../utils/formatNumber";
import DataSet from "@antv/data-set";
import { DataContext } from "../../../contexts/data";
import _ from "lodash"

const titleX = {
  style: {
    fontSize: 14,
    textAlign: "center",
    fill: "#777",
    // fontWeight: "bold",
    fontFamily: "Prompt, sans-serif"
  },
  offset: 50,
};
const titleY = {
  style: {
    fontSize: 14,
    textAlign: "center",
    fill: "#777",
    // fontWeight: "bold",
    fontFamily: "Prompt, sans-serif"
  },
};

const LineChart2 = ({ data, stateX, stateY }) => {
  const { _embed_, _chartInfoEmbed_, _showAxisLabel_ } = useContext(WebParameterContext);
  const { _fieldList_ } = useContext(DataContext);
  const [defaultData, setDefaultData] = useState([]);
  const [d, setD] = useState([]);
  const transpose = false
  const [position, setPosition] = useState();
  const hasExcludeFieldX = stateX.some(({content}) => _fieldList_.includes(content))
  const hasExcludeFieldY = stateY.some(({content}) => _fieldList_.includes(content))

  const [x0TypeNumber, setX0TypeNumber] = useState(false)
  const [x1TypeNumber, setX1TypeNumber] = useState(false)
  const [yTypeNumber, setYTypeNumber] = useState(false)
  const [xAxis, setXAxis] = useState([]);
  const [yAxis, setYAxis] = useState([]);

  const updateData = (_d) => {
    setD(_d);
  };

  useEffect(() => {
    const xAxisTemp = [];
    const yAxisTemp = [];
    stateX.forEach((item) => {
      if (item.agg !== "" && !item.content.includes(item.agg)) {
        xAxisTemp.push(`${item.agg}(${item.content})`);
      }
      if (item.agg === "") {
        xAxisTemp.push(item.content);
      }
    });
    setXAxis(xAxisTemp)
    stateY.forEach((item) => {
      if (item.agg !== "" && !item.content.includes(item.agg)) {
        yAxisTemp.push(`${item.agg}(${item.content})`);
      }
      if (item.agg === "") {
        yAxisTemp.push(item.content);
      }
    });
    setYAxis(yAxisTemp)
    setX0TypeNumber(data.some(item=>typeof item[xAxisTemp[0]] === "number"))
    let sortedData = data
    if(data.some(item=>typeof item[xAxisTemp[0]] === "number"))
      sortedData = _.orderBy(data, [xAxisTemp[0]], ["asc"]);
    const ds = new DataSet();
    const dv = ds.createView().source(sortedData);
    dv.transform({
      type: 'rename',
      map:{
        [xAxisTemp[0]]: 'x', //rename x axis key as x(new key)
        [xAxisTemp[1]]: 'name',
        [yAxisTemp[0]]: 'y'
      }
    })
    .transform({//map x value to string and y value isNaN
      type: 'map',
      callback: (obj) => {
        obj.x = String(obj.x)
        obj.y = obj.y && typeof obj.y === "number" ? Number(obj.y) : obj.y
        obj.name = xAxisTemp[1] ? String(obj.name) : String(yAxisTemp[0])
        obj.nameYAxis = yAxisTemp[0]
        return obj
      }
    });
    setYTypeNumber(dv.rows.some(({y}) => typeof y === "number"))
    setX1TypeNumber(dv.rows.some(({name}) => !isNaN(name)))
    if(xAxisTemp.length === 1){
      setDefaultData(dv.rows)
    }
    else{
      setD(dv.rows)
    }
    setPosition("x*y")
    // eslint-disable-next-line
  }, [data, stateX, stateY]);
  const scale = {
    x: {
      alias: xAxis[0],
      range: [0, 1],
    },
    y: {
      alias: yAxis[0],
      nice: true,
      min: 0,
    },
  };
  const sliderFormatter = (val) =>{
    if((!isNaN(val) || val === "" || val === "null") && x0TypeNumber && !hasExcludeFieldX) return getFormatNumber(val === "" || val === "null" ? 0 : +val)
    return val
  }
  return (
    <>
      <div style={{ textAlign: "start", marginBottom: 5 }}>
      {(xAxis.length === 1 && defaultData.length > 0) &&
        <ChartOrderBy
          parent="Line chart"
          updateData={updateData}
          data={defaultData}
          axis="y"
        />
      }
      </div>
      <Chart
        autoFit
        height={
          _embed_
            ? _chartInfoEmbed_
              ? "calc(100vh - 262px)"
              : "calc(100vh - 132px)"
            : "calc(100vh - 262px)"
        }
        width={"calc(100% - 20px)"}
        data={d}
        scale={scale}
        appendPadding={[15, 5, 10, 0]}
      >
        <Axis
          name="x"
          title={_embed_ ? _showAxisLabel_ ? titleX : "" : titleX}
          tickLine={{
            style: {
              lineWidth: 1,
              stroke: "#000",
            },
            length: 5,
          }}
          label={{
            formatter: val => `${(!isNaN(val) || val === "" || val === "null") && x0TypeNumber && !hasExcludeFieldX ? getFormatNumber(val === "" || val === "null" ? 0 : +val) : val}`
          }}
        />
        <Axis
          name="y"
          title={_embed_ ? _showAxisLabel_ ? titleY : "" : titleY}
          label={{
            formatter: val => `${!isNaN(val) && yTypeNumber && !hasExcludeFieldY ? getFormatNumber(+val) : val}`
          }}
        />
        <Legend position="top-left" />
        <Line position={position} size={2} color="name" tooltip={['name*y', (name, value) => {
          return {
            value: `${!isNaN(value) && yTypeNumber && !hasExcludeFieldY ? new Intl.NumberFormat().format(+value) : value}`,
            name: !isNaN(name) && x1TypeNumber && !hasExcludeFieldX ? new Intl.NumberFormat().format(+name) : name
          }
        }]} />
        <Point position={position} size={4} color="name" style={{lineWidth: 1}} tooltip={['name*y', (name, value) => {
          return {
            value: `${!isNaN(value) && yTypeNumber && !hasExcludeFieldY ? new Intl.NumberFormat().format(+value) : value}`,
            name: !isNaN(name) && x1TypeNumber && !hasExcludeFieldX ? new Intl.NumberFormat().format(+name) : name
          }
        }]}/>
        <Tooltip showCrosshairs>
          {(title,items) => {
            return <div>
                    <ul className="g2-tooltip-list" style={{textAlign: 'left'}}>
                      <li className="g2-tooltip-list-item">
                        <span className="g2-tooltip-name">{xAxis[0]}</span> :
                        <span className="g2-tooltip-value">{(!isNaN(title) || title === "" || title === "null") && x0TypeNumber && !hasExcludeFieldX ? new Intl.NumberFormat().format(title === "" || title === "null" ? 0 : +title) : title}</span>
                      </li>
                      <li className="g2-tooltip-list-item">
                        <span className="g2-tooltip-marker" style={{background: items[0].color, width: '8px', height: '8px', borderRadius: '50%', display: 'inline-block', marginRight: '8px'}}></span>
                        <span className="g2-tooltip-name">{items[0].name}</span> :
                        <span className="g2-tooltip-value">{items[0].value}</span>
                      </li>
                    </ul>
                  </div>
          }}
        </Tooltip>
        <Slider start={0} end={1} formatter={(val)=>sliderFormatter(val)}/>
      </Chart>
    </>
  );
};

export default LineChart2;
