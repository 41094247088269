import React, { useContext, useEffect, useState } from "react";
import { Select } from "antd";
import { WebParameterContext } from "../../contexts/webParameter";

const BarChartType = ({updateType}) => {
  const {_barChartType_, _setBarChartType_} = useContext(WebParameterContext)
  const [type, setType] = useState("STACK");
  useEffect(()=>{
    setType(_barChartType_)
    updateType(_barChartType_)
    // eslint-disable-next-line
  }, [_barChartType_])
  const handleChange = (value) => {
    _setBarChartType_(value)
      // setType(value)
      // updateType(value)
  };
  return (
    <>
      <label style={{ marginLeft: 10, marginRight: 10 }}>Type</label>
      <Select value={type} style={{ width: 120 }} onChange={handleChange}>
        <Select.Option value="STACK">STACK</Select.Option>
        <Select.Option value="GROUP">GROUP</Select.Option>
      </Select>
    </>
  );
};

export default BarChartType;
