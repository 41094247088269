import React, { useState } from "react";
import { Button } from "antd";
import TableSelectDataModal from "../TableSelectDataModal";

const MapSelectDataGroupToggle = ({ form, index }) => {
  const [visible, setVisible] = useState(false);
  const toggleModal = () => {
    setVisible(!visible);
  };
  return (
    <>
      <Button type="primary" onClick={toggleModal}>
        เลือกข้อมูล
      </Button>
      <TableSelectDataModal
        visible={visible}
        modalVisibility={toggleModal}
        form={form}
        index={index}
      />
    </>
  );
};

export default MapSelectDataGroupToggle;
