import React, { useEffect, useMemo, useContext } from "react";
import "./Heatmap.css";
import * as L from "leaflet";
import { latLng } from "leaflet";
import "leaflet/dist/leaflet.css";
import { WebParameterContext } from "../../../contexts/webParameter";
import { getFormatNumber } from "../../../utils/formatNumber";
import { Button } from "antd";
import { DataContext } from "../../../contexts/data";

const Heatmap = ({dataGeo, yAxis, updateHeatmapGeo, minMaxValue}) =>{
    const mapRef = React.useRef(null);
    const {_mapLevel_, _embed_} = useContext(WebParameterContext);
    const {_fieldList_} = useContext(DataContext);
    useEffect(()=>{
        var geojson
        
        function getColor(count) {
            if (typeof count !== "undefined") {
                let min = minMaxValue.min
                let max = minMaxValue.max
                if (!max) {
                  return "rgba(65,102,226,0.05)";
                }
                if (!min) {
                  min = 0;
                }
                let amount = max - min;
                let color_percent = 0;
                if (count - min !== 0) {
                  color_percent = (count - min) / amount;
                }
                color_percent += 0.15;
                if (color_percent > 1) {
                  color_percent = 1;
                }
                if(min === max)
                    color_percent = 1
                return "rgba(65,102,226," + color_percent + ")";
            } else {
                return "rgba(65,102,226,0.05)";
            }
            // if(count !== undefined){
            //     return count > 2000  ? 'rgba(65,102,226, 1)' :
            //     count > 1000  ? 'rgba(65,102,226, .85)' :
            //     count > 500  ? 'rgba(65,102,226, .7)' :
            //     count > 200   ? 'rgba(65,102,226, .55)' :
            //     count > 100   ? 'rgba(65,102,226,.4)' :
            //                   'rgba(65,102,226,.25)';
            // }
            // else{
            //     return "rgba(65,102,226,0.05)";
            // }
            //}
        }
        function style(feature) {
            let data_amount = 0
            data_amount = feature.properties.count_item
            return {
                fillColor: getColor(data_amount),
                weight: 2,
                opacity: 1,
                color: 'white',
                dashArray: '3',
                fillOpacity: 0.7
            };
        }
        function resetHighlight(e) {
            geojson.resetStyle(e.target);
        }
        function highlightFeature(e) {
            var layer = e.target;
            layer.setStyle({
                weight: 4,
                color: '#FFF',
                dashArray: '',
            });
            let tooltip_var=""
            if(_mapLevel_.level === "region"){
                tooltip_var = layer.bindTooltip(
                                `<div class="text-left heatmap-tooltip"><b>${layer.feature.properties.NAME_TH}</b> ${yAxis.length === 1 ? `<p>${yAxis[0]} ${layer.feature.properties.count_item ? `: ${_fieldList_.includes(layer.feature.properties.attr_name) ? layer.feature.properties.count_item : !isNaN(layer.feature.properties.count_item) ? new Intl.NumberFormat().format(layer.feature.properties.count_item) : layer.feature.properties.count_item}` : ': ไม่มีข้อมูล'}</p>`:""}</div>`
                            );
            }
            if(_mapLevel_.level === "region_province"){
                tooltip_var = layer.bindTooltip(
                                `<div class="text-left heatmap-tooltip"><b>${layer.feature.properties.PROV_NAM_T}</b> ${yAxis.length === 1 ? `<p>${yAxis[0]} ${layer.feature.properties.count_item ? `: ${_fieldList_.includes(layer.feature.properties.attr_name) ? layer.feature.properties.count_item : !isNaN(layer.feature.properties.count_item) ? new Intl.NumberFormat().format(layer.feature.properties.count_item) : layer.feature.properties.count_item}` : ': ไม่มีข้อมูล'}</p>`:""}</div>`
                            );
            }
            else if(_mapLevel_.level === "country"){
                tooltip_var = layer.bindTooltip(
                                `<div class="text-left heatmap-tooltip"><b>${layer.feature.properties.PROV_NAM_T}</b> ${yAxis.length === 1 ? `<p>${yAxis[0]} ${layer.feature.properties.count_item ? `: ${_fieldList_.includes(layer.feature.properties.attr_name) ? layer.feature.properties.count_item : !isNaN(layer.feature.properties.count_item) ? new Intl.NumberFormat().format(layer.feature.properties.count_item) : layer.feature.properties.count_item}` : ': ไม่มีข้อมูล'}</p>`:""}</div>`
                            );
            }
            else if(_mapLevel_.level === "province"){
                tooltip_var = layer.bindTooltip(
                                `<div class="text-left heatmap-tooltip"><b>${layer.feature.properties.AP_TN}</b> ${yAxis.length === 1 ? `<p>${yAxis[0]} ${layer.feature.properties.count_item ? `: ${_fieldList_.includes(layer.feature.properties.attr_name) ? layer.feature.properties.count_item : !isNaN(layer.feature.properties.count_item) ? new Intl.NumberFormat().format(layer.feature.properties.count_item) : layer.feature.properties.count_item}` : ': ไม่มีข้อมูล'}</p>`:""}</div>`
                            );
            }
            else if(_mapLevel_.level === "amphoe" || _mapLevel_.level === "tambol"){
                tooltip_var = layer.bindTooltip(
                                `<div class="text-left heatmap-tooltip"><b>${layer.feature.properties.T_NAME_T}</b> ${yAxis.length === 1 ? `<p>${yAxis[0]} ${layer.feature.properties.count_item ? `: ${_fieldList_.includes(layer.feature.properties.attr_name) ? layer.feature.properties.count_item : !isNaN(layer.feature.properties.count_item) ? new Intl.NumberFormat().format(layer.feature.properties.count_item) : layer.feature.properties.count_item}` : ': ไม่มีข้อมูล'}</p>`:""}</p></div>`
                            );
            }
            layer.bringToFront();
            tooltip_var.openTooltip();
        }
        function renderNewMap(e) {
            // mapRef.current.fitBounds(e.target.getBounds());
            let setNewMapLevel = ""
            //check current level to access another level
            if(_mapLevel_.level === "region" && dataGeo.length > 1 ){
                    setNewMapLevel = "region_province"
            }
            else if((_mapLevel_.level === "country" || _mapLevel_.level === "region_province") && dataGeo.length > 1){
                setNewMapLevel = "province"
            }
            else if(_mapLevel_.level === "province" && dataGeo.length > 1){
                setNewMapLevel = "amphoe"
            }
            else if((_mapLevel_.level === "amphoe" || _mapLevel_.level === "tambol") && dataGeo.length > 1){
                setNewMapLevel = "tambol"
            }
            if(setNewMapLevel){
                updateHeatmapGeo(e.target.feature.properties, setNewMapLevel, _mapLevel_.mode)
            }
        }

        function onEachFeature(feature, layer) {
            layer.on({
                mouseover: highlightFeature,
                mouseout: resetHighlight,
                click: renderNewMap
            });
        }
        function getLat(dataMap) {
            let sumLat = 0;
            let centroidKey = _mapLevel_.level !== "region" ? "CENTROID_LATITUDE" : "ycoord"
            if (dataMap && Array.isArray(dataMap)) {
                //check only region_province lat lng key if not match set new one
                if(_mapLevel_.level === "region_province" && !dataMap.some(({properties}) => properties[centroidKey])){
                    centroidKey = "ycoord"
                }
                for (let item of dataMap) {
                    sumLat += item.properties[centroidKey];
                }
            } else {
                if(_mapLevel_.level === "region_province" && !dataMap.properties[centroidKey]){
                    centroidKey = "ycoord"
                }
                sumLat = dataMap.properties[centroidKey];
            }
            sumLat = sumLat / dataMap.length;
            return Number(sumLat.toFixed(6));
        }
        function getLng(dataMap) {
            let sumLng = 0;
            let centroidKey = _mapLevel_.level !== "region" ? "CENTROID_LONGITUDE" : "xcoord"
            if (dataMap && Array.isArray(dataMap)) {
                //check only region_province lat lng key if not match set new one
                if(_mapLevel_.level === "region_province" && !dataMap.some(({properties}) => properties[centroidKey])){
                    centroidKey = "xcoord"
                }
                for (let item of dataMap) {
                    sumLng += item.properties[centroidKey];
                }
            } else {
                if(_mapLevel_.level === "region_province" && !dataMap.properties[centroidKey]){
                    centroidKey = "xcoord"
                }
                sumLng = dataMap.properties[centroidKey];
            }
            sumLng = sumLng / dataMap.length;
            return Number(sumLng.toFixed(6));
        }
        function getMinMaxLatLng(dataMap) {
            var maxLat = -90,
              maxLng = -180,
              minLat = 90,
              minLng = 180;
            var tenth_latlng, data_each_coor;
            if (dataMap && Array.isArray(dataMap)) {
              for (let item of dataMap) {
                for (let item2 of item.geometry.coordinates[0]) {
                  tenth_latlng = [item2[0]];
      
                  for (let i = 0; i < 99; i++) {
                    data_each_coor = item2[Math.round((item2.length / 100) * i)];
                    if (data_each_coor && typeof data_each_coor != "undefined") {
                      tenth_latlng.push(data_each_coor);
                    }
                  }
      
                  for (let item3 of tenth_latlng) {
                    if (
                      item3 &&
                      typeof item3[0] != "undefined" &&
                      typeof item3[1] != "undefined"
                    ) {
                      if (item3[1] > maxLat) maxLat = item3[1];
                      if (item3[0] > maxLng) maxLng = item3[0];
                      if (item3[1] < minLat) minLat = item3[1];
                      if (item3[0] < minLng) minLng = item3[0];
                    }
                  }
                }
              }
            }
            return {
              maxLat: maxLat,
              maxLng: maxLng,
              minLat: minLat,
              minLng: minLng,
            };
        }
        /* function handleBackEvent(){
            if(_mapLevel_.level === "region" && _mapLevel_.properties?.NAME_TH){
                updateHeatmapGeo({}, "region")
            }
            else if(_mapLevel_.level === "province"){
                updateHeatmapGeo({}, "country")
            }
            else if(_mapLevel_.level === "amphoe"){
                updateHeatmapGeo({PROV_CODE: _mapLevel_.properties.PV_CODE, PROV_NAM_T: _mapLevel_.properties.PV_TN}, "province")
            }
            else if(_mapLevel_.level === "tambol"){
                updateHeatmapGeo({PV_CODE: _mapLevel_.properties.P_CODE, PV_TN: _mapLevel_.properties.P_NAME_T, AP_CODE: _mapLevel_.properties.AM_CODE, AP_TN: _mapLevel_.properties.A_NAME_T}, "amphoe")
            }
        } */
        let center_m
        if(_mapLevel_.level === "country" || (_mapLevel_.level === "region" && Object.keys(_mapLevel_.properties).length === 0)){
            center_m = latLng(13.088706, 100.276598); // Latlng'centroid of Thailand
        }else {
            var lat = getLat(dataGeo),
            lng = getLng(dataGeo);
            center_m = [lat, lng];
        }
        //check map container before render
        // var mapContainer = L.DomUtil.get('heatmap');
        // if(mapContainer != null){
        //     mapContainer._leaflet_id = null;
        // }
        document.getElementById('heatmap-wrapper').innerHTML = "<div id='heatmap' style='width: 100%; height: 100%;'></div>";
        mapRef.current = L.map("heatmap", {
            zoomSnap: 0.2,
            zoomDelta: 0.2,
            scrollWheelZoom: false,
            keyboard: false,
            doubleClickZoom: false,
            zoomAnimation: false,
            fadeAnimation: false,
            attributionControl: false,
        });
        mapRef.current.setView(center_m,5.5)
        const minMaxLatLng = new Promise((resolve) => {
            var var_temp = getMinMaxLatLng(dataGeo);
            resolve(var_temp);
        });
        minMaxLatLng.then((data) => {
            var _bound = L.latLngBounds(
                [data.maxLat, data.maxLng],
                [data.minLat, data.minLng]
            );
            if(_mapLevel_.level === "region" && Object.keys(_mapLevel_.properties).length === 0){
                _bound = L.latLngBounds(
                    [20.4646029, 105.6228277],
                    [5.6195023, 97.3444315]
                );
            }
            mapRef.current.fitBounds(_bound);
        });
        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            opacity: 0,
            maxZoom: 19,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        })
        geojson = L.geoJson(dataGeo, {style: style, onEachFeature: onEachFeature}).addTo(mapRef.current);
        /* if(_mapLevel_.level !== "country" && Object.keys(_mapLevel_.properties).length !== 0){
            var legendbackbtn = L.control({position: 'bottomleft'});
            legendbackbtn.onAdd = function(){
                var button = L.DomUtil.create('button', 'map-back-btn ant-btn ant-btn-default')
                button.textContent = "ย้อนกลับ";
                L.DomEvent.on(
                    button,
                    "click",
                    (e) => {
                        handleBackEvent()
                    },
                    this
                );
                return button
            }
            legendbackbtn.addTo(mapRef.current);
        } */
        // if(hasRegionField){
            /* var legendSwitch = L.control({position: 'topright'});
            legendSwitch.onAdd = function(){
                var modebutton = L.DomUtil.create('button', 'map-back-btn ant-btn ant-btn-default map-mode')
                modebutton.textContent = _mapLevel_.mode === "country" ? "แสดงภูมิภาค" : "แสดงทั้งประเทศ";
                L.DomEvent.on(
                    modebutton,
                    "click",
                    (e) => {
                        updateHeatmapGeo({}, _mapLevel_.mode === "country" ? "region" : "country", _mapLevel_.mode === "country" ? "region" : "country")
                    },
                    this
                );
                return modebutton
            }
            legendSwitch.addTo(mapRef.current); */
        // }
        let checkHasValue = dataGeo.some(({properties}) => properties?.count_item)
        if(checkHasValue && !(minMaxValue.max === 0 && minMaxValue.min === 0)){
            var legend = L.control({position: 'bottomright'});
            legend.onAdd = function (map) {

                var div = L.DomUtil.create('div', 'info legend')
                if(minMaxValue.max === minMaxValue.min){
                    div.innerHTML +=` <div>
                        <div style="display:flex;">
                        <div style="width: 18px; height: 18px; background:`+getColor(minMaxValue.max)+`;
                            border-radius: .2rem; opacity: 0.8"></div>
                            <div style="position: relative;padding-left: 3px;">
                            <div>`+getFormatNumber(minMaxValue.max)+`</div>
                            </div>
                        </div>
                    </div>`
                }else{
                div.innerHTML +=`
                    <div>
                        <div style="display:flex;">
                        <div style="width: 18px; height: 120px; background: rgb(65,102,226);
                            background: -moz-linear-gradient(180deg, rgba(65,102,226,1) 0%, rgba(65,102,226,0.15) 100%);
                            background: -webkit-linear-gradient(180deg, rgba(65,102,226,1) 0%, rgba(65,102,226,0.15) 100%);
                            background: linear-gradient(180deg, rgba(65,102,226,1) 0%, rgba(65,102,226,0.15) 100%);
                            border-radius: .2rem; opacity: 0.8"></div>
                            <div style="position: relative;padding-left: 3px;">
                                <div>`+getFormatNumber(minMaxValue.max)+`</div>
                                <div style="position: absolute; bottom: 0; line-height: 1">`+getFormatNumber(minMaxValue.min)+`</div>
                            </div>
                        </div>
                    </div>`
                }

                return div;
            };

            legend.addTo(mapRef.current);
        }
    },[dataGeo, _mapLevel_, yAxis, updateHeatmapGeo, minMaxValue, _fieldList_])


    return useMemo(() => {
        const handleBackEventFromTitle = (toLevel) =>{
            let obj = {}
            if(toLevel === "region"){
                updateHeatmapGeo({}, "region", "region")
            }
            else if(toLevel === "country"){
                updateHeatmapGeo({}, "country", "country")
            }
            else if(toLevel === "region_province"){
                updateHeatmapGeo({NAME_TH: _mapLevel_.properties.REGION_NAME}, "region_province", _mapLevel_.mode)
            }
            else if(toLevel === "province"){
                if(_mapLevel_.level === "amphoe"){
                    obj = {
                        PROV_CODE: _mapLevel_.properties.PV_CODE,
                        PROV_NAM_T: _mapLevel_.properties.PV_TN
                    }
                    if(_mapLevel_.mode === "region")
                        obj["REGION_NAME"] = _mapLevel_.properties.REGION_NAME
                }
                else if(_mapLevel_.level === "tambol"){
                    obj = {
                        PROV_CODE: _mapLevel_.properties.P_CODE,
                        PROV_NAM_T: _mapLevel_.properties.P_NAME_T
                    }
                    if(_mapLevel_.mode === "region")
                        obj["REGION_NAME"] = _mapLevel_.properties.REGION_NAME
                }
                updateHeatmapGeo(obj, "province", _mapLevel_.mode)
            }
            else if(toLevel === "amphoe"){
                if(_mapLevel_.level === "tambol"){
                    obj = {
                        PV_CODE: _mapLevel_.properties.P_CODE,
                        PV_TN: _mapLevel_.properties.P_NAME_T,
                        AP_CODE: _mapLevel_.properties.AM_CODE,
                        AP_TN: _mapLevel_.properties.A_NAME_T
                    }
                    if(_mapLevel_.mode === "region")
                        obj["REGION_NAME"] = _mapLevel_.properties.REGION_NAME
                    updateHeatmapGeo(obj, "amphoe", _mapLevel_.mode)
                }
            }
        }
        let maxMapLevel = "country"
        const searchParams = new URLSearchParams(window.location.search);
        if(searchParams.has("level") && _embed_){
            var currUrl = JSON.parse(decodeURIComponent(searchParams.get("level")))
            maxMapLevel = currUrl.level
        }
        return (
            <>
                <div id="map-wrapper">
                    <div id="heatmap-wrapper"></div>
                    <div className="map-title">
                        {
                            (maxMapLevel === "country" || maxMapLevel === "region") &&
                        <div>
                            {
                                _mapLevel_.level !== "country" &&  Object.keys(_mapLevel_.properties).length > 0?
                                <span className="clickable" onClick={()=>handleBackEventFromTitle(_mapLevel_.mode === "region" ? "region" : "country")}>ทั้งประเทศ</span>
                                :
                                <span className="normal-text">ทั้งประเทศ</span>
                            }
                        </div>
                        }
                        {
                            (maxMapLevel === "region" || maxMapLevel === "region_province" || maxMapLevel === "country") &&
                            <>
                            {
                                ((_mapLevel_.level === "region_province" || _mapLevel_.level === "province" || _mapLevel_.level === "amphoe" || _mapLevel_.level === "tambol") && _mapLevel_.mode === "region") &&
                                <div>
                                    {(maxMapLevel === "country" || maxMapLevel === "region")  && <span className="separate-icon">&rsaquo;</span>}
                                    {_mapLevel_.level === "region_province" ?
                                    <span className="normal-text">{_mapLevel_.properties.NAME_TH}</span>
                                    :
                                    <span className="clickable" onClick={()=>handleBackEventFromTitle("region_province")}>{_mapLevel_.properties.REGION_NAME}</span>
                                    }
                                </div>
                            }
                            </>
                        }
                        {
                            (maxMapLevel === "region" || maxMapLevel === "region_province" || maxMapLevel === "province" || maxMapLevel === "country") &&
                            <>
                            {
                                ((_mapLevel_.level === "province" || _mapLevel_.level === "amphoe" || _mapLevel_.level === "tambol") ) &&
                                <div>
                                    {(maxMapLevel === "country" || maxMapLevel === "region" || maxMapLevel === "region_province") && <span className="separate-icon">&rsaquo;</span>}
                                    {_mapLevel_.level === "province" ?
                                    <span className="normal-text">{_mapLevel_.properties.PROV_NAM_T}</span>
                                    :
                                    <span className="clickable" onClick={()=>handleBackEventFromTitle("province")}>{_mapLevel_.level === "amphoe" ? _mapLevel_.properties.PV_TN : _mapLevel_.properties.P_NAME_T}</span>
                                    }
                                </div>
                            }
                            </>
                        }
                        {/* {
                            ((_mapLevel_.level === "province" || _mapLevel_.level === "amphoe" || _mapLevel_.level === "tambol") && _mapLevel_.mode === "country" ) &&
                            <div>
                                <span className="separate-icon">&rsaquo;</span>
                                {_mapLevel_.level === "province" ?
                                <span className="normal-text">{_mapLevel_.properties.PROV_NAM_T}</span>
                                :
                                <span className="clickable" onClick={()=>handleBackEventFromTitle("province")}>{_mapLevel_.level === "amphoe" ? _mapLevel_.properties.PV_TN : _mapLevel_.properties.P_NAME_T}</span>
                                }
                            </div>
                        } */}
                        {
                            (maxMapLevel === "region" || maxMapLevel === "region_province" || maxMapLevel === "province" || maxMapLevel === "amphoe" || maxMapLevel === "country") &&
                            <>
                            {
                                ((_mapLevel_.level === "amphoe" || _mapLevel_.level === "tambol") ) &&
                                <div>
                                    {(maxMapLevel !== "amphoe" && maxMapLevel !== "tambol") && <span className="separate-icon">&rsaquo;</span>}
                                    {
                                        _mapLevel_.level === "amphoe" ?
                                        <span className="normal-text">{_mapLevel_.properties.AP_TN}</span>
                                        :
                                        <span className="clickable" onClick={()=>handleBackEventFromTitle("amphoe")}>{_mapLevel_.properties.A_NAME_T}</span>
                                    }
                                </div>
                            }
                            </>
                        }
                        {
                            _mapLevel_.level === "tambol" &&
                            <div>
                                {(maxMapLevel !== "tambol") &&<span className="separate-icon">&rsaquo;</span>}
                                <span className="normal-text">{_mapLevel_.properties.T_NAME_T}</span>
                            </div>
                        }
                    </div>
                    {
                        !_embed_ &&
                        <div className="map-mode-wrapper">
                            <Button className={_mapLevel_.mode === "region" ? "region-mode active-mode" : "region-mode disabled-mode"} {...(_mapLevel_.mode === "country" && {onClick:() =>updateHeatmapGeo({}, "region", "region")})}>ภูมิภาค</Button>
                            <Button className={_mapLevel_.mode === "country" ? "province-mode active-mode disabled" : "province-mode disabled-mode"} {...(_mapLevel_.mode === "region" && {onClick:() =>updateHeatmapGeo({}, "country", "country")})}>จังหวัด</Button>
                        </div>
                    }
                </div>
            </>
        )
    },[_mapLevel_, updateHeatmapGeo, _embed_])
}
export default Heatmap