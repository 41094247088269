import React, { useMemo, useContext } from "react";
import "./CustomPagination.css";
import { Pagination, ConfigProvider } from "antd";
import { WebParameterContext } from "../../contexts/webParameter";
import { DataContext } from "../../contexts/data";
import th_TH from "antd/es/locale/th_TH";

const CustomPagination = ({ size, simple }) => {
  const { _setPage_, _setPageSize_, _page_, _pageSize_ } = useContext(WebParameterContext);
  const { _data_ } = useContext(DataContext);

  return useMemo(() => {
    const onPageChange = (p, ps) => {
    _setPage_(p);
  }
    const onPageSizeChange = (p, ps) => {
      _setPageSize_(ps);
    };
    return (
      <ConfigProvider locale={th_TH}>
        <div className="CustomPagination">
          {_data_.isUpdated ? (
            <div>
              <Pagination
                total={_data_.numData}
                showTotal={(total, range) =>
                  `${new Intl.NumberFormat().format(range[0])}-${new Intl.NumberFormat().format(range[1])} จาก ${new Intl.NumberFormat().format(total)}`
                }
                pageSize={_pageSize_}
                current={_page_}
                onChange={onPageChange}
                onShowSizeChange={onPageSizeChange}
                size={size}
                simple={simple}
              />
            </div>
          ) : null}
        </div>
      </ConfigProvider>
    );
    // eslint-disable-next-line
  }, [_data_, _setPage_, _setPageSize_, size, simple]);
};

export default CustomPagination;
