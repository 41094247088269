import React, { useContext, useMemo, useEffect } from "react";
import "./DataContent.css";
import { Card } from "antd";

import { WebParameterContext } from "../../contexts/webParameter";
import { DataContext } from "../../contexts/data";

import axios from "axios";
import { useHistory, useParams } from "react-router-dom";

import TableView from "../../components/TableView";
import MainFilter from "../../components/MainFilter";

const DataContent = () => {
  const { _baseUrl_, _page_, _pageSize_, _filter_, _sorter_, _setSorter_, _setTableSorter_, _tableSorter_, _embed_, _mainFilter_ } = useContext(
    WebParameterContext
  );
  const { _data_, _setData_, _schema_, _isMapping_, _utfMapping_ } = useContext(
    DataContext
  );

  const history = useHistory();
  const { dsname, datasetId } = useParams();

  useEffect(() => {
    const callGetData = async () => {
      try {
        let data = await getData(
          _isMapping_.value
            ? `${
                _baseUrl_.value
              }/graphql?id=${datasetId}&dsname=${dsname}&query={records(${_filter_}${_sorter_}LIMIT:"${_pageSize_}", OFFSET:"${
                (_page_ - 1) * _pageSize_
              }")${_schema_.schemaStrMapped}numData{result}}`
            : `${
                _baseUrl_.value
              }/graphql?id=${datasetId}&dsname=${dsname}&query={records(${_filter_}${_sorter_}LIMIT:"${_pageSize_}", OFFSET:"${
                (_page_ - 1) * _pageSize_
              }")${_schema_.schemaStr}numData{result}}`
        );

        if (_isMapping_.value) {
          data = dataMapping(data, _utfMapping_);
        }
        _setData_(data);

      } catch (error) {
        console.log("error >> ", error);
        history.push(window.location.pathname + "/error");
      }
    };

    if (
      _schema_.isUpdated &&
      _baseUrl_.isUpdated &&
      _isMapping_.isUpdated &&
      _utfMapping_.isUpdated
    ) {
      callGetData();
    }
  }, [
    _schema_,
    _setData_,
    _page_,
    _pageSize_,
    _baseUrl_,
    _filter_,
    _utfMapping_,
    _isMapping_,
    history,
    _sorter_,
    dsname,
    datasetId
  ]);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("sorter")) {
      updateSorter(_tableSorter_)
    }
    // eslint-disable-next-line
  }, [_isMapping_]);
  
  const updateSorter = (sorter) =>{
    if(sorter.length > 0){
      let tableSort = []
      let tableOrder = "ORDER: ["
      tableSort = [...tableSort, ...sorter]
      for (let i = 0; i < sorter.length; i++) {
        let field = sorter[i].columnKey
        if (_isMapping_.value) {
          field = "";
          Buffer.from(sorter[i].columnKey, "utf-8").forEach((hex) => {
            field += `_${hex.toString(16).toUpperCase()}`;
          });
        }
        tableOrder += `{field: "${field}", type: "${sorter[i].order === 'ascend' ? "ASC" : "DESC"}"}${i < sorter.length - 1 ? ",":"]"}`
      }
      _setSorter_(tableOrder)
      _setTableSorter_(tableSort)
    }
    else{
      _setSorter_("")
      _setTableSorter_([])
    }
  }
  return useMemo(() => {
    return (
      <>
        <div id="console">
              {/* <div
                id={
                  breakpoint === "xxl"
                    ? "console-buttons"
                    : "console-buttons-mobile"
                }
              >
                {breakpoint === "xxl" ? (
                  <Radio.Group
                    value={mode}
                    onChange={handleFuncChange}
                    buttonStyle="solid"
                    style={{ height: 54 }}
                  >
                    <Radio.Button
                      value="data"
                      style={{
                        height: 54,
                        width: 54,
                      }}
                    >
                      <TableOutlined style={{ fontSize: 24, marginTop: 14 }} />
                    </Radio.Button>
                    <Radio.Button
                      value="map"
                      style={{
                        height: 54,
                        width: 54,
                      }}
                    >
                      <EnvironmentOutlined
                        style={{ fontSize: 24, marginTop: 14 }}
                      />
                    </Radio.Button>
                  </Radio.Group>
                ) : (
                  <Dropdown overlay={menu}>
                    <Button
                      style={{
                        height: 54,
                        width: 54,
                      }}
                      icon={iconMode}
                    />
                  </Dropdown>
                )}
              </div> */}
          {((!_embed_) || (_embed_ && _mainFilter_.length > 0)) &&
            <div id="console-filter">
              <MainFilter />
            </div>
          }
        </div>
        <div className="DataContent">
          {_data_.isUpdated && _schema_.isUpdated ? (
            <Card className="data-content-card-bg">
              <TableView data={_data_.value} schema={_schema_.value} updateSorter={updateSorter}/>
            </Card>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </>
    );
    // eslint-disable-next-line
  }, [_data_, _schema_]);
};

export default DataContent;

const getData = async (url) => {
  return await axios
    .get(url)
    .then(async (res) => {
      const data = await res.data.data.records;
      const numData = await res.data.data.numData.result;

      return { value: data, numData: numData, isUpdated: true };
    })
    .catch((error) => console.log("error >> ", error));
};

const dataMapping = (data, utfMapping) => {
  const newData = [];
  data.value.forEach((item) => {
    let mapped = Object.keys(item).reduce((acc, key) => {
      acc[utfMapping.value[key]] = item[key];
      return acc;
    }, {});
    newData.push(mapped);
  });
  data.value = newData;
  return data;
};
