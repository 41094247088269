export const getFilterStr = (filter, type) => {
  let filterStr = "&mainfilter="
  if(type === "chart")
    filterStr = "&chartfilter="
  if(type === "map")
    filterStr = "&mapfilter="
  if(type === "heatmap")
    filterStr = "&heatmapft="
  if (filter.length !== 0) {
    filter.forEach((item) => {
      filterStr += `${encodeURIComponent(JSON.stringify(item))}|`;
    });
    filterStr = filterStr.substring(0, filterStr.length - 1);
  } else {
    filterStr = "";
  }
  return filterStr;
};

export const getSelectedChartAxis = (selState) => {
  const newSelectedState = {};
  for (const [k, v] of Object.entries(selState)) {
    k !== "itemsA" && k !== "itemsAStatic" &&
      (newSelectedState[k] = v.map(
        (item) =>{
        if(k === "itemsAHeatmap"){
          return `${item['content']}${item["newContent"] ? `<${item["newContent"]}>` : ""}`
        }
        else if(k === "selectedYHeatmap"){
          return `${item["content"]}${item["newContent"] ? `<${item["newContent"]}>` : ""}${item["agg"] === "" ? "" : `:${item["agg"]}`}`
        }else{
          return `${item["content"]}${item["agg"] === "" ? "" : `:${item["agg"]}`}`
        }

      })
    );
  }
  const newSelectedStateStr = JSON.stringify(newSelectedState);
  return `&selected=${encodeURIComponent(newSelectedStateStr)}`;
};

export const getSelectedAnotherChartAxis = (selState, chart) => {
  const newSelectedState = {};
  for (const [k, v] of Object.entries(selState)) {
    let conditionCheck = chart !== "Scorecard" ? k !== "itemsA" : k === "selectedY"
    conditionCheck &&
      (newSelectedState[k] = v.map(
        (item) =>{
          return `${item["content"]}${item["agg"] === "" ? "" : `:${item["agg"]}`}`
      })
    );
  }
  const newSelectedStateStr = JSON.stringify(newSelectedState);
  return `&${chart.toLowerCase()}=${encodeURIComponent(newSelectedStateStr)}`;
};
export const getSelectedHeatmapChartAxis = (selState) => {
  const newSelectedState = {};
  for (const [k, v] of Object.entries(selState)) {
    k !== "selectedX"&&
      (newSelectedState[k] = v.map(
        (item) =>{
        if(k === "itemsA"){
          return `${item['content']}${item["newContent"] ? `<${item["newContent"]}>` : ""}`
        }
        else if(k === "selectedY"){
          return `${item["content"]}${item["newContent"] ? `<${item["newContent"]}>` : ""}${item["agg"] === "" ? "" : `:${item["agg"]}`}`
        }
        else{
          return `${item["content"]}${item["agg"] === "" ? "" : `:${item["agg"]}`}`
        }

      })
    );
  }
  const newSelectedStateStr = JSON.stringify(newSelectedState);
  return `&heatmap=${encodeURIComponent(newSelectedStateStr)}`;
};

export const getEditLabel = (lst) =>{
  if(lst.length > 0){
    let newMapData = lst.map((item)=> `${item["attr"]}${item["newLabel"] ? `:${item["newLabel"]}` : ""}`)
    const labelStr = JSON.stringify(newMapData)
    return `&label=${encodeURIComponent(labelStr)}`
  }
  return null

}

export const getSortTableStr = (lst) =>{
  if(lst.length > 0){
    let newMapData = lst.map((item)=> `${item.columnKey}:${item.order}`)
    const labelStr = JSON.stringify(newMapData)
    return `&sorter=${encodeURIComponent(labelStr)}`
  }
  return null

}

export const getHeatmapMapLevel = (data) =>{
  let levelObj = {
    level: data.level,
    mode: data.mode,
    properties: {}
  }
  if(data.level === "province"){
    levelObj["properties"] = {
      PROV_CODE: data.properties.PROV_CODE
    }
    if(data.mode === "region"){
      levelObj["properties"]["REGION_NAME"] = data.properties.REGION_NAME
    }
  }
  else if(data.level === "amphoe"){
    levelObj["properties"] = {
      PV_CODE: data.properties.PV_CODE,
      AP_CODE: data.properties.AP_CODE
    }
    if(data.mode === "region"){
      levelObj["properties"]["REGION_NAME"] = data.properties.REGION_NAME
    }
  }
  else if(data.level === "tambol"){
    levelObj["properties"] = {
      P_CODE: data.properties.P_CODE,
      AM_CODE: data.properties.AM_CODE,
      TAM_CODE: data.properties.TAM_CODE
    }
    if(data.mode === "region"){
      levelObj["properties"]["REGION_NAME"] = data.properties.REGION_NAME
    }
  }
  else if(data.level === "region_province"){
    if(data.properties?.NAME_TH){
      levelObj["properties"] = {
        NAME_TH: data.properties.NAME_TH
      }
    }
  }
  let levelStr = JSON.stringify(levelObj)
  return `&level=${encodeURIComponent(levelStr)}`
}
