export function getFormatNumber(num) {
    if (num >= 1000000000000) {//1ล้านล้าน
        return `${new Intl.NumberFormat().format(
          (num / 1000000000000).toFixed(2).replace(/\.0$/, ""),
          2
        )}T`;
    }
    else if (num >= 1000000000) {//1พันล้าน
        return `${new Intl.NumberFormat().format(
            (num / 1000000000).toFixed(2).replace(/\.0$/, ""),
            2
        )}B`;
    }
    else if (num >= 1000000) {//1million
        return `${new Intl.NumberFormat().format((num / 1000000).toFixed(2).replace(/\.0$/, ""), 2)}M`;
    }
    else if (num >= 100000 || num >= 10000 || num >= 1000) {
        return `${new Intl.NumberFormat().format((num / 1000).toFixed(2).replace(/\.0$/, ""), 2)}K`;
    }
    else {
        return new Intl.NumberFormat().format(num, 0);
    }
}