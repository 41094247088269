import React, { useContext, useState } from "react";
import "./ChartContent.css";
import { Tooltip, Button, Typography, message } from "antd";
import { RollbackOutlined, ShareAltOutlined, EditOutlined } from "@ant-design/icons";

import { WebParameterContext } from "../../contexts/webParameter";

import ChartLayout from "../../components/ChartLayout";
import SettingModal from "../../components/SettingModal";

import copy from "copy-to-clipboard";
import { getFilterStr, getSelectedAnotherChartAxis, getSelectedHeatmapChartAxis, getEditLabel, getSortTableStr, getHeatmapMapLevel } from "../../helpers";
import ShareSetting from "../../components/ShareSetting";

const ChartContent = ({ dataInfo, tabIndex }) => {
  const {
    _embed_,
    _page_,
    _pageSize_,
    _mainFilter_,
    _chartFilter_,
    _lineSort_,
    _barSort_,
    _chart_,
    _location_,
    _scoreCardLabel_,
    _tableSorter_,
    _mapFilter_,
    _tooltipField_,
    _limitOffsetMap_,
    _mapLevel_,
    _chartHeatmapFilter_,
    _barChartType_,
    _utmLocation_,
    _cardColor_,
    _selectedLbState_,
    _selectedPieState_,
    _selectedBarState_,
    _selectedScatterTreemapState_,
    _selectedScorecardState_,
    _selectedHeatmapState_,
    _selectedSunburstState_,
    _selectedLbStateFromShare_,
    _selectedPieStateFromShare_,
    _selectedBarStateFromShare_,
    _selectedScatterTreemapStateFromShare_,
    _selectedScorecardStateFromShare_,
    _selectedHeatmapStateFromShare_,
    _selectedSunburstStateFromShare_,
    _sharedTitle_,
    _showBack_,
    _showEdit_,
    _titleFont_,
    _currBaseMap_,
    _currentMapLayer_,
    _shareSetting_
  } = useContext(WebParameterContext);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const copySharableLink = (mode) => {
    let sharedUrl = window.location.origin + window.location.pathname;
    const filterStr = getFilterStr(_mainFilter_, "main");
    const chartFilterStr = getFilterStr(_chartFilter_, "chart");
    const mapFilterStr = getFilterStr(_mapFilter_, "map");
    const heatmapFilter = getFilterStr(_chartHeatmapFilter_, "heatmap");

    const lineSort = `&ls=${_lineSort_}`;
    const barSort = `&bs=${_barSort_}`;
    const barType = `&bt=${_barChartType_}`;

    const lat = _location_.lat !== "" ? `&lat=${encodeURIComponent(_location_.lat)}` : "";
    const lng = _location_.lng !== "" ? `&lng=${encodeURIComponent(_location_.lng)}` : "";
    const utm = _utmLocation_.x !== "" && _utmLocation_.y !== "" && _utmLocation_.zone !== "" ? `&utm=${encodeURIComponent(JSON.stringify(_utmLocation_))}` : "";
    const mapBase = `&base=${_currBaseMap_}`;

    let selectedLbStr = getSelectedAnotherChartAxis(_selectedLbState_, "linebar");
    let selectedPieStr = getSelectedAnotherChartAxis(_selectedPieState_, "pie");
    let selectedBarStr = getSelectedAnotherChartAxis(_selectedBarState_, "bar");
    let selectedScatterStr = getSelectedAnotherChartAxis(_selectedScatterTreemapState_, "scatter");//scatter and treemap use the same sate but separate for support share version before
    let selectedTreemapStr = getSelectedAnotherChartAxis(_selectedScatterTreemapState_, "treemap");
    let selectedScorecardStr = getSelectedAnotherChartAxis(_selectedScorecardState_, "scorecard");
    let selectedSunburstStr = getSelectedAnotherChartAxis(_selectedSunburstState_, "sunburst");
    let selectedHeatmapStr = getSelectedHeatmapChartAxis(_selectedHeatmapState_)
    const mapLevelStr = getHeatmapMapLevel(_mapLevel_)

    let scoreCardLabel = getEditLabel(_scoreCardLabel_)
    let color = `&color=${JSON.stringify([_cardColor_.background, _cardColor_.title, _cardColor_.number])}`

    const searchParams = new URLSearchParams(window.location.search);
    //also handle when share from shared link
    if(searchParams.has("linebar")) {
      selectedLbStr = getSelectedAnotherChartAxis(_selectedLbStateFromShare_, "linebar");
    }
    if(searchParams.has("pie")) {
      selectedPieStr = getSelectedAnotherChartAxis(_selectedPieStateFromShare_, "pie");
    }
    if(searchParams.has("bar") ) {
      selectedBarStr = getSelectedAnotherChartAxis(_selectedBarStateFromShare_, "bar");
    }
    if(searchParams.has("scatter")) {
      selectedScatterStr = getSelectedAnotherChartAxis(_selectedScatterTreemapStateFromShare_, "scatter");
    }
    if(searchParams.has("treemap")) {
      selectedTreemapStr = getSelectedAnotherChartAxis(_selectedScatterTreemapStateFromShare_, "treemap");
    }
    if(searchParams.has("scorecard")) {
      selectedScorecardStr = getSelectedAnotherChartAxis(_selectedScorecardStateFromShare_, "scorecard");
    }
    if(searchParams.has("sunburst")) {
      selectedSunburstStr = getSelectedAnotherChartAxis(_selectedSunburstStateFromShare_, "sunburst");
    }
    if(searchParams.has("heatmap")) {
      selectedHeatmapStr = getSelectedHeatmapChartAxis(_selectedHeatmapStateFromShare_);
    }
    const sortTableStr = getSortTableStr(_tableSorter_)

    sharedUrl += `?t=3&limit=${_pageSize_}&offset=${
      (_page_ - 1) * _pageSize_
    }${filterStr}${mapFilterStr}&charttype=${_chart_}${lineSort}${barSort}${barType}${chartFilterStr}${heatmapFilter}${selectedLbStr}${selectedPieStr}${selectedBarStr}${selectedScatterStr}${selectedTreemapStr}${selectedScorecardStr}${selectedHeatmapStr}${selectedSunburstStr}${mapBase}${lat}${lng}${utm}${scoreCardLabel?scoreCardLabel:""}${color}${sortTableStr?sortTableStr:""}${_tooltipField_.length > 0 ? `&field=${encodeURIComponent(JSON.stringify(_tooltipField_))}` : ""}&mlimit=${_limitOffsetMap_.limit}&moffset=${_limitOffsetMap_.offset}${mapLevelStr}&layer=${encodeURIComponent(_currentMapLayer_)}`;
    if(mode === "edit")
      sharedUrl += "&from_shared=true"
    sharedUrl = encodeURI(sharedUrl);
    if(mode === "copy"){
      copy(sharedUrl);
      message.success("คัดลอกลิงก์สำหรับแชร์แล้ว", 1);
    }
    else{
      window.open(sharedUrl, "_blank")
    }
  };
  const updateFullscreenMode = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <div className="ChartContent">
        <>
          {((_showBack_ || _showEdit_ || _sharedTitle_ !== "") && !isFullscreen) &&
          <div className="wrap-header">
            <div className="header-button">
            { _showBack_ &&
              <Tooltip placement="bottomLeft" title={dataInfo.value.ref_url}>
                <Button
                  icon={<RollbackOutlined style={{ marginTop: 3 }} />}
                  type="primary"
                  ghost
                  size="small"
                  href={dataInfo.value.ref_url}
                  target="_blank"
                  style={{ marginTop: -12, marginRight: 5, marginLeft: 5 }}
                />
              </Tooltip>
            }
              {!_embed_ ?
              <Tooltip placement="bottomLeft" title="แชร์หน้านี้">
                <Button
                  icon={<ShareAltOutlined />}
                  type="primary"
                  ghost
                  size="small"
                  onClick={()=>copySharableLink("copy")}
                  style={{ marginTop: -12, marginRight: 5 }}
                />
              </Tooltip>
              :
              _showEdit_ &&
              <Tooltip placement="bottomLeft" title="กลับไปยังหน้า playground">
                <Button
                  icon={<EditOutlined />}
                  type="primary"
                  ghost
                  size="small"
                  onClick={()=>copySharableLink("edit")}
                  style={{ marginTop: -12, marginRight: 5, marginLeft: !_showBack_ && 5 }}
                />
              </Tooltip>
              }
              {!_embed_ &&
                <SettingModal dataInfo={dataInfo} appType="Chart"/>
              }
            </div>
            <Tooltip
              placement="bottom"
              title={`${dataInfo.value.package_title} (${dataInfo.value.resource_title})`}
            >
              <Typography.Title
                ellipsis
                level={3}
                style={{ width: "100%", paddingLeft: (!_showBack_ && !_showEdit_) && 5, fontSize: _titleFont_ && 18, fontWeight: _titleFont_ && 'normal' }}
              >
                {_sharedTitle_ ?? `${dataInfo.value.package_title} (${dataInfo.value.resource_title})` }
                {/* {`${dataInfo.value.package_title} (${dataInfo.value.resource_title})`} */}
              </Typography.Title>
            </Tooltip>
          </div>
          }
          {
            _embed_ && _shareSetting_ &&
            <ShareSetting dataInfo={dataInfo} updateFullscreenMode={updateFullscreenMode} copySharableLink={copySharableLink}/>
          }
          <ChartLayout isFullscreen={isFullscreen} tabIndex={tabIndex}/>
        </>
    </div>
  );
};

export default ChartContent;
