import React, { useState, useMemo } from "react";
import "./TableSelectData.css";
import { Table, Input, Space, Button, Pagination, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const TableSelectData = ({
  data,
  schema,
  form,
  onChangePageOrPageSize,
  index,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState(0);
  const [_selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [_selectedRows, setSelectedRows] = useState([]);

  useMemo(() => {
    const lst = form.getFieldsValue("DynamicMapGroupingFormList");
    const srk = lst["DynamicMapGroupingFormList"][index]["row"];
    const d = lst["DynamicMapGroupingFormList"][index]["data"];
    if (srk !== undefined) {
      setSelectedRowKeys(srk);
    }
    if (d !== undefined) {
      setSelectedRows(d);
    }
  }, [form, index]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const lst = form.getFieldsValue("DynamicMapGroupingFormList");
      const trueSelectedRows = selectedRows.filter(item => item !== undefined).concat(_selectedRows);
      lst["DynamicMapGroupingFormList"][index]["data"] = trueSelectedRows;
      lst["DynamicMapGroupingFormList"][index]["row"] = selectedRowKeys;
      form.setFieldsValue({
        DynamicMapGroupingFormList: lst["DynamicMapGroupingFormList"],
      });
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`ค้นหา ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            ค้นหา
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            ล้าง
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const createTableColumns = (_schema) => {
    const columnsLst = [];

    Object.keys(_schema).forEach((key) => {
      if (key !== "uuid") {
        const column = {
          title: key,
          key: key,
          dataIndex: key,
          width: 200,
          sorter: (a, b) =>
            (isNaN(a[key]) && isNaN(b[key])) === false
              ? a[key] - b[key]
              : a[key].length - b[key].length,
          ...getColumnSearchProps(key),
        };
        columnsLst.push(column);
      }
    });
    return columnsLst;
  };

  const columns = createTableColumns(schema.value);

  return (
    <div className="TableSelectData">
      <Table
        className={
          data.value.length < 10
            ? "table-select-data-prevent-resize"
            : "table-select-data"
        }
        rowKey="uuid"
        rowSelection={{
          selectedRowKeys: _selectedRowKeys,
          preserveSelectedRowKeys: true,
          fixed: true,
          ...rowSelection,
        }}
        columns={columns}
        size="small"
        dataSource={data.value}
        sticky={true}
        bordered
        pagination={false}
        scroll={{ x: "100%", y: 500 }}
        locale={{
          emptyText: (
            <span>
              <p className="table-select-data-empty-text">ไม่มีข้อมูล</p>
            </span>
          ),
        }}
      />
      <Row justify="center" style={{ marginTop: 10 }}>
        <Col span={5}>
          <Pagination
            simple
            total={data.numData}
            defaultCurrent={1}
            defaultPageSize={10}
            onChange={onChangePageOrPageSize}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TableSelectData;
