import React from "react";
import "./AppFooter.css";
import { Layout, Row, Col } from "antd";
// import { FullscreenOutlined } from "@ant-design/icons";
import CustomPagination from "../../components/CustomPagination";

const { Footer } = Layout;

const AppFooter = ({ breakpoint }) => {
  return (
    <Footer
      className="custom-footer"
    >
      <Row>
        <Col
          span={breakpoint === "xs" || breakpoint === "sm" ? 20 : 22}
          offset={breakpoint === "xs" || breakpoint === "sm" ? 0 : 1}
        >
          <CustomPagination
            simple={breakpoint === "xs" || breakpoint === "sm" ? true : false}
          />
        </Col>
        {/* <Col
          span={breakpoint === "xs" || breakpoint === "sm" ? 4 : 1}
          style={{ textAlign: "center" }}
        >
          {tabIndex === 2 && (
            <Tooltip placement="top" title="เต็มหน้าจอ">
              <Button
                icon={<FullscreenOutlined />}
                shape="circle"
                size="small"
                style={{ marginTop: 12 }}
                onClick={onFullscreen}
              />
            </Tooltip>
          )}
        </Col> */}
      </Row>
    </Footer>
  );
};

export default AppFooter;
