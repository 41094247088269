import React from "react";
import { Result, Button } from "antd";

const ErrorWarning = () => {
  return (
    <Result
      status="warning"
      title="Something went wrong. Please try again."
      extra={[
        <Button
          key="main"
          href="https://gdcatalog.go.th"
        >
          กลับไปยังเว็บไซต์หลัก
        </Button>,
        <Button
          type="primary"
          key="reload"
          href={window.location.href.replace("/error", "")}
        >
          ลองอีกครั้ง
        </Button>,
      ]}
    />
  );
};

export default ErrorWarning;
