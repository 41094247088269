import React, { useContext, useState } from "react";
// import { WebParameterContext } from "../../../contexts/webParameter";

import _ from "lodash";
import Highcharts from "highcharts";
import {
  HighchartsChart,
  HighchartsProvider,
  XAxis,
  YAxis,
  SunburstSeries,
  Tooltip,
} from "react-jsx-highcharts";
import addSunburstModule from 'highcharts/modules/sunburst';
import { getFormatNumber } from "../../../utils/formatNumber";
import { DataContext } from "../../../contexts/data";
addSunburstModule(Highcharts);

const formatChildrenData = (parentObj, childGroup) => {
  let result = [];
  if (childGroup.length !== 0) {
    childGroup.forEach((item, index) => {
      const obj = {
        id: `${parentObj.id}_${index}`,
        name: item["name"],
        value: item["value"],
        parent: parentObj.id,
        data_type: typeof item["name"]
      };
      result.push(obj);

      if (item["children"].length !== 0) {
        const childResult = formatChildrenData(obj, item["children"]);
        result = result.concat(childResult);
      }
    });
  }
  return result;
};

const formatData = (data) => {
  const colours = Highcharts.getOptions().colors;
  let formattedData = [];
  data.forEach((g1Item, g1Index) => {
    if(g1Item["value"] > 0){
      const g1Obj = {
        id: `id_${g1Index}`,
        name: g1Item["name"],
        value: g1Item["value"],
        color: colours[g1Index % 10],
        data_type: typeof g1Item["name"]
      };

      const childrenFormatted = formatChildrenData(g1Obj, g1Item["children"]);
      if (childrenFormatted.length !== 0) {
        formattedData = formattedData.concat(childrenFormatted);
      }

      formattedData.push(g1Obj);
    }
  });
  return formattedData;
};

const SunburstChart = ({ data, stateX, stateY }) => {
  // const { _embed_, _chartInfoEmbed_ } = useContext(WebParameterContext);
  const {_fieldList_} = useContext(DataContext)

  const [treeData, setTreeData] = React.useState([]);
  // const xHasAgg = stateX.some(({agg})=>agg!=="")
  // const yHasAgg = stateY.some(({agg})=>agg!=="")
  const hasExcludeFieldX = stateX.some(({content}) => _fieldList_.includes(content))
  const hasExcludeFieldY = stateY.some(({content}) => _fieldList_.includes(content))
  const [yTypeNumber, setYTypeNumber] = useState(false)

  React.useEffect(() => {
    const fields = [];
    const valueFields = [];
    stateX.forEach((item) => {
      if (item.agg !== "" && !item.content.includes(item.agg)) {
        fields.push(`${item.agg}(${item.content})`);
      }
      if (item.agg === "") {
        fields.push(item.content);
      }
    });
    stateY.forEach((item) => {
      if (item.agg !== "" && !item.content.includes(item.agg)) {
        valueFields.push(`${item.agg}(${item.content})`);
      }
      if (item.agg === "") {
        valueFields.push(item.content);
      }
    });
    setYTypeNumber(data.some(item=>typeof item[valueFields[0]] === "number"))
    const grouping = (d, fs) => {
      const checkType = d.some(item=> typeof item[fs[0]] === "number")
      const g = _.groupBy(d, fs[0]);
      const gl = [];
      for (const [k, v] of Object.entries(g)) {
        const obj = {};
        obj["name"] = checkType && (!isNaN(k) || k === "" || k === "null") ? Number(k === "" || k === "null" ? 0 : k) : k;
        let sumValue = 0;
        v.forEach((element) => {
          if(typeof element[valueFields[0]] === "number")
            sumValue += element[valueFields[0]];
        });
        obj["value"] = sumValue;
        const newFs = fs.slice(1);
        if (newFs.length > 0) {
          obj["children"] = grouping(v, newFs);
        } else {
          obj["children"] = [];
        }
        gl.push(obj);
      }

      return gl;
    };

    const group = grouping(data, fields);
    const d = formatData(group);
    setTreeData(d);
  }, [data, stateX, stateY]);

  // const levels = [
  //   {
  //     level: 1,
  //     dataLabels: {
  //       enabled: true,
  //     },
  //     borderWidth: 3,
  //   },
  // ];

  const levels = [{
    level: 1,
    levelIsConstant: false,
    borderWidth: 3,
  }, {
      level: 2,
      colorByPoint: true,
  },
  {
      level: 3,
      colorVariation: {
          key: 'brightness',
          to: -0.5
      },
  }, {
      level: 4,
      colorVariation: {
          key: 'brightness',
          to: 0.5
      },
  }]
  const tooltipFormatter = function () {
    return `${!isNaN(this.point.options.name) && this.point.data_type === "number" && !hasExcludeFieldX ? new Intl.NumberFormat().format(+this.point.options.name) : this.point.options.name}: ${!isNaN(this.point.value) && this.point.value && yTypeNumber && !hasExcludeFieldY ? new Intl.NumberFormat().format(+this.point.value) : this.point.value}`;
  };
  const chartOptions = {
    height: (document.querySelector(".chart-container-layout").clientHeight - 45)+"px"
  };
  const plotOptions = {
    series: {
      turboThreshold: 1000000,
    },
  };

  return (
    <div className="SunburstChart">
      {treeData.length !== 0 && (
        <HighchartsProvider Highcharts={Highcharts} style={{height: '100%'}}>
          <HighchartsChart chart={chartOptions} plotOptions={plotOptions} accessibility={{enabled: false}} >
            <XAxis/>
            <YAxis>
              <SunburstSeries
                data={treeData}
                allowTraversingTree
                borderRadius={3}
                cursor="pointer"
                animationLimit={1000}
                dataLabels={{
                  // enabled: false,
                  formatter: function() {
                    var key = this.point.options.name
                    return !isNaN(key) && this.point.data_type === "number" && !hasExcludeFieldX ? getFormatNumber(key) : key
                  }
                }}
                levelIsConstant={false}
                allowChartUpdate={false}
                levels={levels}
              />
            </YAxis>

            <Tooltip formatter={tooltipFormatter} />
          </HighchartsChart>
        </HighchartsProvider>
      )}
    </div>
  );
};

export default SunburstChart;
