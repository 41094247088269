import React, { useState, useContext, useEffect } from "react";
import { Select, Card, Button, TreeSelect, message } from "antd";
import { CaretUpOutlined, GlobalOutlined } from "@ant-design/icons";
import { WebParameterContext } from "../../contexts/webParameter";

const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;

const LatLngSelect = ({
  schema,
  isMapping,
  utfMapping,
  updateLatLong,
  breakpoint,
  updateTooltipSelected,
  currentMode
}) => {
  const {
    _location_,
    _setLocation_,
    _embed_,
    _tooltipField_,
    _setTooltipField_,
    _utmLocation_,
    _setUtmLocation_
  } = useContext(WebParameterContext);
  const [show, setShow] = useState(true);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [value, setValue] = useState([]);
  const [treeData, setTreeData] = useState([])
  const [xVal, setXVal] = useState(null);
  const [yVal, setYVal] = useState(null);
  const [zoneVal, setZoneVal] = useState(null);

  useEffect(() => {
    if(currentMode === "LatLng"){
      if (_location_.lat !== "" && _location_.lng !== "") {
        setLat(_location_.lat);
        setLng(_location_.lng);
        updateLatLong({ lat: _location_.lat, lng: _location_.lng });
      }
      let hasLatKey, hasLngKey
      if(isMapping.value){
        hasLatKey = Object.values(utfMapping.value).find(element => element.toLowerCase() === "latitude" || element.toLowerCase() === "lat" || element.toLowerCase() === "late")
        hasLngKey = Object.values(utfMapping.value).find(element => element.toLowerCase() === "longitude" || element.toLowerCase() === "long")
      }else{
        hasLatKey = Object.keys(schema.value).find(element => element.toLowerCase() === "latitude" || element.toLowerCase() === "lat" || element.toLowerCase() === "late")
        hasLngKey = Object.keys(schema.value).find(element => element.toLowerCase() === "longitude" || element.toLowerCase() === "long")
      }
      if(hasLatKey && hasLngKey){
        setLat(hasLatKey)
        setLng(hasLngKey)
        updateLatLong({lat: hasLatKey, lng: hasLngKey})
        _setLocation_({lat: hasLatKey, lng: hasLngKey});
      }else{
        updateLatLong({lat: "", lng: ""})
      }
    }
    else{//set default utm
      if (_utmLocation_.x !== "" && _utmLocation_.y !== "" && _utmLocation_.zone !== "") {
        setXVal(_utmLocation_.x);
        setYVal(_utmLocation_.y);
        setZoneVal(_utmLocation_.zone);
        updateLatLong({ x: _utmLocation_.x, y: _utmLocation_.y, zone: _utmLocation_.zone});
      }
      let hasXKey, hasYKey, hasZoneKey
      if(isMapping.value){
        hasXKey = Object.values(utfMapping.value).find(element => element.toLowerCase() === "x" || element.toLowerCase() === "easting" || element.toLowerCase() === "east")
        hasYKey = Object.values(utfMapping.value).find(element => element.toLowerCase() === "y" || element.toLowerCase() === "northing" || element.toLowerCase() === "north")
        hasZoneKey = Object.values(utfMapping.value).find(element => element.toLowerCase() === "zone")
      }else{
        hasXKey = Object.keys(schema.value).find(element => element.toLowerCase() === "x" || element.toLowerCase() === "easting" || element.toLowerCase() === "east")
        hasYKey = Object.keys(schema.value).find(element => element.toLowerCase() === "y" || element.toLowerCase() === "northing" || element.toLowerCase() === "north")
        hasZoneKey = Object.keys(schema.value).find(element => element.toLowerCase() === "zone")
      }
      if(hasXKey && hasYKey && hasZoneKey){
        setXVal(hasXKey)
        setYVal(hasYKey)
        setZoneVal(hasZoneKey)
        updateLatLong({x: hasXKey, y: hasYKey, zone: hasZoneKey})
        _setUtmLocation_({x: hasXKey, y: hasYKey, zone: hasZoneKey})
      }else{
        updateLatLong({x: "", y: "", zone: ""})
      }
    }
    let res
    if(isMapping.value){
      res = Object.values(utfMapping.value).filter((item)=>item !== "uuid").map((val)=>{
        return {title: val, value: `${val}`, key: val}
      })
    }
    else{
      res = Object.keys(schema.value).filter((item)=>item !== "uuid").map((val)=>{
        return {title: val, value: `${val}`, key: val}
      })
    }

    setTreeData(res)
    if(_tooltipField_.length > 0){
      setValue(_tooltipField_)
      _setTooltipField_(_tooltipField_)
      updateTooltipSelected(_tooltipField_)
    }else{
      let arr = isMapping.value ? Object.values(utfMapping.value).filter((item)=>item !== "uuid") : Object.keys(schema.value).filter((item)=>item !== "uuid")
      setValue([arr[0]])
      _setTooltipField_([arr[0]])
      updateTooltipSelected([arr[0]])
    }
    // eslint-disable-next-line
  }, [currentMode])

  const onChangLat = (val) => {
    setLat(val);
  };

  const updateLocalLatLong = () => {
    if(currentMode === "LatLng"){
      if(value.length > 0){
        updateLatLong({ lat, lng });
        _setLocation_({ lat, lng });
      }
    }
    else{
      if(value.length > 0){
        updateLatLong({ x: xVal, y: yVal, zone: zoneVal });
        _setUtmLocation_({ x: xVal, y: yVal, zone: zoneVal })
      }
    }
    _setTooltipField_(value)
    updateTooltipSelected(value)
    message.success("ตั้งค่าสำเร็จ", 3);
  };

  const onChangLng = (val) => {
    setLng(val);
  };

  const showHideLatLngSelect = () => {
    setShow(!show);
  };
  const onChange = (newValue) => {
    setValue(newValue);
  };
  const onChangUtmX = (val) =>{
    setXVal(val)
  }
  const onChangUtmY = (val) =>{
    setYVal(val)
  }
  const onChangUtmZone = (val) =>{
    setZoneVal(val)
  }
  const tProps = {
    treeData,
    value,
    onChange,
    allowClear: true,
    showArrow: true,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'กรุณาเลือก',
    style: {
      width: '100%',
    },
    showSearch: false,
  };
  
  return (
    <div>
      {show ? (
        <Card
          className="LatLngSelect"
          bodyStyle={{ padding: 7 }}
          style={_embed_ ? { display: "none" } : {
            borderRadius: 5,
            position: "absolute",
            top: 50,
            left: 55,
            zIndex: 999,
            maxWidth: '350px'
          }}
        >
          <div style={{display: 'flex'}}>
            <div>
              <Button
                shape="circle"
                size="small"
                icon={<CaretUpOutlined style={{ marginTop: 3 }} />}
                style={{ marginRight: 6 }}
                onClick={showHideLatLngSelect}
              />
            </div>
            <div>
              {
                currentMode === "LatLng" ?
              <div style={{marginBottom: '10px'}} >
                <Select
                  style={{ width: '50%', minWidth: '100px' }}
                  placeholder="Latitude"
                  value={lat === "" ? null : lat}
                  onChange={onChangLat}
                >
                  {isMapping.value
                    ? Object.values(utfMapping.value).map((val) => (
                        <Option value={val} key={val}>
                          {val}
                        </Option>
                      ))
                    : Object.keys(schema.value).map((key) => (
                        <Option value={key} key={key}>
                          {key}
                        </Option>
                      ))}
                </Select>
                <Select
                  style={{ width: '50%', minWidth: '100px' }}
                  placeholder="Longitude"
                  value={lng === "" ? null : lng}
                  onChange={onChangLng}
                >
                  {isMapping.value
                    ? Object.values(utfMapping.value).map((val) => (
                        <Option value={val} key={val}>
                          {val}
                        </Option>
                      ))
                    : Object.keys(schema.value).map((key) => (
                        <Option value={key} key={key}>
                          {key}
                        </Option>
                      ))}
                </Select>
              </div>
              :
              <div style={{marginBottom: '10px'}} >
                <Select
                  style={{ width: '33.33%', minWidth: '100px' }}
                  placeholder="Zone"
                  value={zoneVal === "" ? null : zoneVal}
                  onChange={onChangUtmZone}
                >
                  {isMapping.value
                    ? Object.values(utfMapping.value).map((val) => (
                        <Option value={val} key={val}>
                          {val}
                        </Option>
                      ))
                    : Object.keys(schema.value).map((key) => (
                        <Option value={key} key={key}>
                          {key}
                        </Option>
                      ))}
                </Select>
                <Select
                  style={{ width: '33.33%', minWidth: '100px' }}
                  placeholder="X"
                  value={xVal === "" ? null : xVal}
                  onChange={onChangUtmX}
                >
                  {isMapping.value
                    ? Object.values(utfMapping.value).map((val) => (
                        <Option value={val} key={val}>
                          {val}
                        </Option>
                      ))
                    : Object.keys(schema.value).map((key) => (
                        <Option value={key} key={key}>
                          {key}
                        </Option>
                      ))}
                </Select>
                <Select
                  style={{ width: '33.33%', minWidth: '100px' }}
                  placeholder="Y"
                  value={yVal === "" ? null : yVal}
                  onChange={onChangUtmY}
                >
                  {isMapping.value
                    ? Object.values(utfMapping.value).map((val) => (
                        <Option value={val} key={val}>
                          {val}
                        </Option>
                      ))
                    : Object.keys(schema.value).map((key) => (
                        <Option value={key} key={key}>
                          {key}
                        </Option>
                      ))}
                </Select>
              </div>
              }
              <div style={{marginBottom: '10px'}}>
                <div style={{textAlign: 'left'}}>Tooltip</div>
                <TreeSelect status={value.length < 1 && "error"} {...tProps}/>
                <div style={{color: '#ff7875', textAlign: 'left', fontSize: '12px'}}>{value.length < 1 && "กรุณาเลือก"}</div>
              </div>
              <div style={{display:'flex',justifyContent:'end'}}>
                <Button
                  type="primary"
                  onClick={updateLocalLatLong}
                  style={{ marginLeft: 10 }}
                  disabled={value.length === 0}
                >
                  ตกลง
                </Button>
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <Card
          className="LatLngSelect"
          bodyStyle={{ padding: 7 }}
          style={{
            borderRadius: 5,
            position: "absolute",
            top: 50,
            left: 55,
            zIndex: 999,
          }}
        >
          <Button
            shape="circle"
            size="small"
            icon={<GlobalOutlined style={{ marginTop: 4 }} />}
            onClick={showHideLatLngSelect}
          />
        </Card>
      )}
    </div>
  );
};

export default LatLngSelect;
