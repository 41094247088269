import React from "react";
import "./AppSider.css";
import { Menu, Layout } from "antd";
import {
  DatabaseOutlined,
  AreaChartOutlined,
  EnvironmentOutlined
} from "@ant-design/icons";

const { Sider } = Layout;
// const menuItems = [
//   {

//   }
// ]
// const onClick = (e) => {
//   console.log("click ", e);
// };

const AppSider = ({
  collapsed,
  onCollapse,
  onChangeTab,
  showComponent,
  defaultOpenKey,
}) => {
  return (
    <>
      {showComponent && (
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
          <div
            className={
              collapsed
                ? "custom-sider-opend-logo-small"
                : "custom-sider-opend-logo-big"
            }
          >
            <img
              src={require("../../assets/images/logo.png")}
              alt="opend-logo"
            />
          </div>
          <div
            className={
              collapsed
                ? "custom-sider-nectec-logo-small"
                : "custom-sider-nectec-logo-big"
            }
          >
            <img
              src={require("../../assets/images/nectec_logo_white.png")}
              alt="nectec-logo"
            />
          </div>
          <Menu
            onClick={(e) => onChangeTab(Number(e.key))}
            theme="dark"
            defaultSelectedKeys={[defaultOpenKey]}
            mode="inline"
            items={[
              { key: 1, label: "ข้อมูล", icon: <DatabaseOutlined /> },
              { key: 3, label: "กราฟ", icon: <AreaChartOutlined /> },
              // {
              //   key: 4,
              //   label: "ตั้งค่า",
              //   icon: <SettingFilled />,
              //   style: {
              //     position: "relative",
              //     width: collapsed ? 80 : 200,
              //     transition: "0.2s",
              //   },
              // },
              { key: 2, label: "แผนที่", icon: <EnvironmentOutlined /> },
            ]}
          >
            {/* <Menu.Item
              key="1"
              icon={<DatabaseOutlined />}
              onClick={() => onChangeTab(1)}
            >
              ข้อมูล
            </Menu.Item>
            <Menu.Item
              key="3"
              icon={<AreaChartOutlined />}
              onClick={() => onChangeTab(3)}
            >
              กราฟ
            </Menu.Item>
            <Menu.Item
              key="4"
              icon={<SettingFilled />}
              onClick={() => onChangeTab(4)}
              style={{
                position: "relative",
                width: collapsed ? 80 : 200,
                transition: "0.2s",
              }}
            >
              ตั้งค่า
            </Menu.Item> */}
          </Menu>
          <div className={`web-version ${!collapsed ? 'is-collapsed' : ''}`}>เวอร์ชัน 2.0</div>
        </Sider>
      )}
    </>
  );
};

export default AppSider;
