import React, { useState, useContext, useEffect } from "react";
import "./MainFilter.css";
import { Card, Button, Modal, Form, Row, Col, Tag } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { WebParameterContext } from "../../contexts/webParameter";
import DynamicConditionFormItem from "../../components/DynamicConditionFormItem";
import { DataContext } from "../../contexts/data";

const MainFilter = () => {
  const { _embed_, _setFilter_, _setMainFilter_, _mainFilter_ } =
    useContext(WebParameterContext);
  const { _isMapping_ } = useContext(DataContext);

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [tags, setTags] = useState([]);
  const [formField, setFormField] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("mainfilter")) {
      localUpdateFilter(_mainFilter_);
      form.setFieldsValue({
        ConditionForm: _mainFilter_,
      });
    }
    // eslint-disable-next-line
  }, [_isMapping_]);

  const onShow = () => {
    const { ConditionForm } = form.getFieldsValue()
    setFormField(ConditionForm)
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const localUpdateFilter = (conditionList) => {
    if(conditionList?.length > 0){
      let AND_Condition = "AND: ["
      let OR_Condition = "OR: ["
      let filter = ""
      if(conditionList[0].logic)
        delete conditionList[0].logic
      for (let i = 0; i < conditionList.length; i++) {
        let col = conditionList[i]["col"];
        if (_isMapping_.value) {
          col = "";
          Buffer.from(conditionList[i]["col"], "utf-8").forEach((hex) => {
            col += `_${hex.toString(16).toUpperCase()}`;
          });
        }
        let operator
        if (conditionList[i]["operator"] === "Contains") {
          operator = `CONTAINS(${conditionList[i]["condition"]})`;
        } else if (conditionList[i]["operator"] === "Equals") {
          operator = `EQUALS(${conditionList[i]["condition"]})`;
        } else if (conditionList[i]["operator"] === "Not Equals") {
          operator = `NOTEQUALS(${conditionList[i]["condition"]})`;
        } else if (conditionList[i]["operator"] === "RegEx") {
          operator = `REGEX(${conditionList[i]["condition"]})`;
        } else if (conditionList[i]["operator"] === "Not Contains") {
          operator = `NOTCONTAINS(${conditionList[i]["condition"]})`;
        } else if (conditionList[i]["operator"] === "Not Null") {
          operator = `NOTNULL`;
        } else {
          operator = `${conditionList[i]["operator"]}${conditionList[i]["condition"]}`;
        }
        if(conditionList.length === 1){
          AND_Condition += `{field: "${col}",operator: "${operator}"}`
          filter += `${AND_Condition}]`
        }else{
          if(conditionList[i]['logic'] && conditionList[i]['logic'] === "OR"){
            OR_Condition += `{field: "${col}",operator: "${operator}"},`
          }
          else if(conditionList[i]['logic'] && conditionList[i]['logic'] === "AND"){
            AND_Condition += `{field: "${col}",operator: "${operator}"},`
          }
          else{
            //check index 1 logic is or ? and
            if(conditionList[1]['logic'] === "OR"){
              OR_Condition += `{field: "${col}",operator: "${operator}"},`
            }
            else{
              AND_Condition += `{field: "${col}",operator: "${operator}"},`
            }
          }
        }
      }
      if(conditionList.some(({logic}) => logic === "AND")){
        filter += `${AND_Condition}]${conditionList.some(({logic}) => logic === "OR")?',':''}`
      }
      if(conditionList.some(({logic}) => logic === "OR")){
        filter += `${OR_Condition}]`
      }
      _setFilter_(filter);
      _setMainFilter_(conditionList);
      setTags(conditionList);
    }
    else{
      _setFilter_("");
      _setMainFilter_([]);
      setTags([]);
    }
    /*let uniqueColList = [];
    if (conditionList !== undefined && conditionList.length !== 0) {
      uniqueColList = [
        ...new Set(conditionList.map((condition) => condition.col)),
      ];
    }

    let uniqueColListUtf = [];
    if (_isMapping_.value) {
      uniqueColList.forEach((item) => {
        let str = "";
        Buffer.from(item, "utf-8").forEach((hex) => {
          str += `_${hex.toString(16).toUpperCase()}`;
        });
        uniqueColListUtf.push(str);
      });
      uniqueColList = uniqueColListUtf;
    }

    if (uniqueColList.length !== 0) {
      let filters = "";

      uniqueColList.forEach((uCol) => {
        let filter = `${uCol}:"`;

        for (let i = 0; conditionList.length > i; i++) {
          let c = conditionList[i]["col"];

          if (_isMapping_.value) {
            c = "";
            Buffer.from(conditionList[i]["col"], "utf-8").forEach((hex) => {
              c += `_${hex.toString(16).toUpperCase()}`;
            });
          }

          if (c === uCol) {
            if (conditionList[i]["operator"] === "Contains") {
              filter += `CONTAINS(${conditionList[i]["condition"]}),`;
            } else if (conditionList[i]["operator"] === "Equals") {
              filter += `EQUALS(${conditionList[i]["condition"]}),`;
            } else if (conditionList[i]["operator"] === "Not Equals") {
              filter += `NOTEQUALS(${conditionList[i]["condition"]}),`;
            } else if (conditionList[i]["operator"] === "RegEx") {
              filter += `REGEX(${conditionList[i]["condition"]}),`;
            } else if (conditionList[i]["operator"] === "Not Contains") {
              filter += `NOTCONTAINS(${conditionList[i]["condition"]}),`;
            } else {
              filter += `${conditionList[i]["operator"]}${conditionList[i]["condition"]},`;
            }
          }
        }
        filter = filter.replace(/,$/, "");
        filter += '"';
        filters += filter;
      });
      _setFilter_(filters);
      _setMainFilter_(conditionList);
      setTags(conditionList);
    } else {
      _setFilter_("");
      _setMainFilter_([]);
      setTags([]);
    }*/
  };

  const onSubmitForm = (form) => {
    const conditionList = form["ConditionForm"];
    localUpdateFilter(conditionList);
    setVisible(false);
  };

  const handleClose = () => {
    // const _tags = tags.filter((tag) => tag !== removedTag);
    form.setFieldsValue({
      ConditionForm: [],
    });
    localUpdateFilter([]);
  };
  const updateForm = (idx) =>{
    const { ConditionForm } = form.getFieldsValue()
    Object.assign(ConditionForm[idx], { operator: null, condition: null })
    form.setFieldsValue({ ConditionForm })
  }
  const updateFormInput = (idx) => {
    const { ConditionForm } = form.getFieldsValue()
    if(ConditionForm[idx].operator === "Not Null"){
      Object.assign(ConditionForm[idx], { condition: null })
      form.setFieldsValue({ ConditionForm })
      //also remove validate
      form.validateFields([['ConditionForm', idx,'condition']])
    }
  }
  const forMap = (tag) => {
    // const tagElem = (
    //   <Tag
    //     closable={!_embed_}
    //     onClose={(e) => {
    //       e.preventDefault();
    //       handleClose(tag);
    //     }}
    //     style={{ padding: 4, marginBottom: 3 }}
    //   >
    //     <span><span>{tag.col}</span> <b>{tag.operator}</b> {tag.condition}</span>
    //   </Tag>
    // );
    return (
        <span key={`${JSON.stringify(tag)}-${tags.indexOf(tag)}`}>
          {tag.logic && <span style={{color: '#ba0126',paddingRight: '5px',paddingLeft: '5px'}}>{tag.logic}</span>}
          <span style={{color: "#0f1ae8"}}>{tag.col}</span> <b style={{color: '#743f32'}}>{tag.operator}</b> {tag.condition}
        </span>
    );
  };


  const tagChild = tags.map(forMap);

  return (
    <Card
      bodyStyle={{ padding: "12px 0 0 5px", border: "1px solid #f0f0f0" }}
      style={{ textAlign: "start" }}
    >
      <Row wrap={false} align="top">
        <Col flex="none">
          <div><Button onClick={onShow} style={{ height: 30 }} disabled={_embed_}>
            ฟิลเตอร์
          </Button></div>
        </Col>
        <Col flex="auto">
          <div
            id="main-filter-horizontal-scrollbar-style"
            className="main-filter-tags"
          >{
            tags.length > 0 &&
            <Tag
              closable={!_embed_}
              onClose={(e) => {
                e.preventDefault();
                handleClose();
              }}
              style={{ padding: 4, marginBottom: 3 }}
            >
              {tagChild}
            </Tag>
          }
          </div>
        </Col>
      </Row>
      {/* <Row align="top">
        <Col
          span={_embed_ ? 3 : 1}
          style={_embed_ ? { marginRight: 0 } : { marginRight: 10 }}
        >
          <Button onClick={onShow} style={{ height: 30 }} disabled={_embed_}>
            ฟิลเตอร์
          </Button>
        </Col>
        <Col span={_embed_ ? 20 : 22}>
          <div
            id="main-filter-horizontal-scrollbar-style"
            className="main-filter-tags"
          >
            {tagChild}
          </div>
        </Col>
      </Row> */}
      <Modal
        className="main-filter-filter-modal"
        closeIcon={<CloseCircleOutlined />}
        open={visible}
        onCancel={onClose}
        footer={false}
        bodyStyle={{ paddingTop: 40, paddingLeft: 20 }}
        forceRender
        width={620}
      >
        <Row>
          <Col
            xs={{
              span: 24,
            }}
            md={{
              span: 3,
            }}
          >
            <p style={{ marginTop: 5 }}>ฟิลเตอร์</p>
          </Col>
          <Col
            xs={{
              span: 24,
            }}
            md={{
              span: 21,
            }}
          >
            <Form
              form={form}
              onFinish={onSubmitForm}
              size="small"
            >
              <Form.Item style={{ width: "100%", marginBottom: 0 }}>
                <div
                  id="main-filter-scrollbar-style"
                  className="main-filter-wrap-dynamic-item"
                >
                  <DynamicConditionFormItem formValue={formField} updateForm={updateForm} updateFormInput={updateFormInput} />
                </div>
              </Form.Item>

              <Form.Item style={{ padding: '5px 0px', textAlign: "right", marginTop: 10, marginBottom: 0 }}>
                <Button type="primary" htmlType="submit">
                  ค้นหา
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </Card>
  );
};

export default MainFilter;
