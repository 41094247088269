import React, { useEffect, useContext, useState, useMemo } from "react";
import "./ChartLayout.css";
import { Row, Col, Spin } from "antd";
import {
  FieldStringOutlined,
  FieldNumberOutlined,
  FieldTimeOutlined
} from "@ant-design/icons";
import _ from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { DataContext } from "../../contexts/data";
import { WebParameterContext } from "../../contexts/webParameter";
import ChartFilter from "../../components/ChartFilter";
import ChartButtonMenu from "../../components/ChartButtonMenu";
import ChartChip from "../../components/ChartChip";
import ChartComponent from "../../components/ChartComponent";
// import ChartHeatmapFilter from "../../components/ChartHeatmapFilter";
import MapFieldModal from "../MapFieldModal/index"
import Swal from 'sweetalert2'

const ChartLayout = ({ isFullscreen, tabIndex }) => {
  const {
    _schema_,
    _isMapping_,
    _utfMapping_,
    _breakpoint_,
    _lbData_,
    _setLbData_,
    _pieData_,
    _setPieData_,
    _barData_,
    _setBarData_,
    _scatterTreemapData_,
    _setScatterTreemapData_,
    _scorecardData_,
    _setScorecardData_,
    _heatmapData_,
    _setHeatmapData_,
    _sunburstData_,
    _setSunburstData_
  } =
    useContext(DataContext);
  const {
    _baseUrl_,
    _embed_,
    _chartInfoEmbed_,
    _chartType_,
    _chart_,
    _setChart_,
    _scoreCardLabel_,
    _setScorecardLabel_,
    _setMapLevel_,
    _mapLevel_,
    _selectedLbState_,
    _setSelectedLbState_,
    _selectedPieState_,
    _setSelectedPieState_,
    _selectedBarState_,
    _setSelectedBarState_,
    _selectedScatterTreemapState_,
    _setSelectedScatterTreemapState_,
    _selectedScorecardState_,
    _setSelectedScorecardState_,
    _selectedHeatmapState_,
    _setSelectedHeatmapState_,
    _selectedSunburstState_,
    _setSelectedSunburstState_,
    _setSelectedLbStateFromShare_,
    _setSelectedPieStateFromShare_,
    _setSelectedBarStateFromShare_,
    _setSelectedScatterTreemapStateFromShare_,
    _setSelectedScorecardStateFromShare_,
    _setSelectedHeatmapStateFromShare_,
    _setSelectedSunburstStateFromShare_,
    _sharedTitle_,
    _showBack_,
    _showEdit_
  } = useContext(WebParameterContext);

  const { dsname, datasetId } = useParams();
  const history = useHistory();

  const [data, setData] = useState({ value: [], numData: 0, isUpdated: false });
  const [dataScore, setDataScore] = useState({ value: [], numData: 0, isUpdated: false });
  const [filters, setFilters] = useState("");
  const [heatmapFilter, setHeatmapFilters] = useState("");
  const [heatmapFiltersObj, setHeatmapFiltersObj] = useState([]);
  const [shouldRender, setShouldRender] = useState(true);
  const [chart, setChart] = useState("Line chart");
  const aggNumberAvailableList = ["AVG","MIN","MAX","SUM","MED","COUNT","COUNT_D"]
  const aggStringAvailableList = ["COUNT","COUNT_D"]
  const [geoJson, setGeoJson] = useState([]);
  const [shapeRender, setShapeRender] = useState(true);
  const [mapFieldStatus, setMapFieldStatus] = useState(false);
  const [chartLoading, setChartLoading] = useState(false);
  const [isFilterUpdate, setIsFilterUpdate] = useState(true);
  const [newMapSchema, setNewMapSchema] = useState({
    update: false,
    value: []
  })
  const [mapLevel, setMapLevel] = useState({
    mode: "country",
    level: "country",
    properties: {}
  })
  const [selectedXHeatmap, setSelectedXHeatmap] = useState([])
  const [lbState, setLbState] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  // const [pieBarState, setPieBarState] = useState({
  //   itemsA: [],
  //   selectedX: [],
  //   selectedY: [],
  // })
  const [pieState, setPieState] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  const [barState, setBarState] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  const [scatterTreemapState, setScatterTreemapState] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  const [scorecardState, setScorecardState] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  const [heatmapState, setHeatmapState] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  const [sunburstState, setSunburstState] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
  })
  const [currentState, setCurrentState] = useState({
    itemsA: [],
    selectedX: [],
    selectedY: [],
    update: false
  })//store current chart obj value state
  const [hasFilterChangeLb, setHasFilterChangeLb] = useState(false)
  // const [hasFilterChangePieBar, setHasFilterChangePieBar] = useState(false)
  const [hasFilterChangePie, setHasFilterChangePie] = useState(false)
  const [hasFilterChangeBar, setHasFilterChangeBar] = useState(false)
  const [hasFilterChangeScatterTreemap, setHasFilterChangeScatterTreemap] = useState(false)
  const [hasFilterChangeSunburst, setHasFilterChangeSunburst] = useState(false)
  const [hasFilterChangeScorecard, setHasFilterChangeScorecard] = useState(false)
  const [disableXAxis, setDisableXAxis] = useState(false)

  //check tab index to rerender sunburst chart when share map link and chart is sunburst
  useEffect(()=>{
    const searchParams = new URLSearchParams(window.location.search);
    //trigger render sunburst chart with the sunburst data state loaded before, in case of share link and current page is map and charttype is sunburst
    if(tabIndex === 3 && searchParams.has("t") && searchParams.get("t") === "2" && chart === "Sunburst chart"){
      setCurrentState({...sunburstState, update: false})
      setData({..._sunburstData_, isUpdated: true})
      setIsFilterUpdate(false)
    }
    // eslint-disable-next-line
  }, [tabIndex])
  //set chart type from embed
  useEffect(() => {
    if (_chartType_ !== "") {
      _setChart_(_chartType_);
      setChart(_chartType_);
    }
  }, [_chartType_, _setChart_]);
  //set selected data from embed
  useEffect(() => {
    if (_schema_.isUpdated) {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has("linebar") || searchParams.has("pie") || searchParams.has("bar") || searchParams.has("scatter") || searchParams.has("treemap") || searchParams.has("scorecard") || searchParams.has("heatmap") || searchParams.has("sunburst")) {//from shared link
        let stateArray = []
        if(searchParams.has("linebar")){
          stateArray.push({..._selectedLbState_, chart: "linebar"})
        }
        if(searchParams.has("scatter") || searchParams.has("treemap")){
          stateArray.push({..._selectedScatterTreemapState_, chart: "scatter"})
        }

        let selYScorecard = [];
        let selYHeatmap = []
        let attrItemsHeatmap = []
        const newAggNumberAvailableList = [...aggNumberAvailableList, "no function"]
        const newAggStringAvailableList = [...aggStringAvailableList, "no function"]
        for(let arrElm of stateArray){//can repeat y attr.
          let selX = [];
          let selY = [];
          const selYUrl = arrElm.selectedY.map((item) => {
            const obj = {};
            if (item.includes(":")) {
              obj["content"] = item.substring(0, item.indexOf(":"));
              obj["agg"] = item.substring(item.indexOf(":") + 1, item.length);
            } else {
              obj["content"] = item.substring(0, item.length);
              obj["agg"] = "";
            }
            return obj;
          });
          const attrItem = [...getItems(_schema_.value)]
          selYUrl.forEach((it, yIdx) => {
            attrItem.forEach((item) => {
              let newY = {}
              if (item["content"] === it["content"]) {
                newY = {...item, id: `${(Date.now()+yIdx).toString()}:${it['content']}`, agg: it["agg"]}
                if(it.agg === "")
                  newY.noFunction = true
                selY.push(setColorToItem(newY));
              }
            });
          });
          const selXUrl = arrElm.selectedX.map((item) => {
            const obj = {};
            if (item.includes(":")) {
              obj["content"] = item.substring(0, item.indexOf(":"));
              obj["agg"] = item.substring(item.indexOf(":") + 1, item.length);
            } else {
              obj["content"] = item.substring(0, item.length);
              obj["agg"] = "";
            }
            return obj;
          });

          selXUrl.forEach((it) => {
            attrItem.forEach((item) => {
              if (item["content"] === it["content"]) {
                item["agg"] = it["agg"];
                selX.push(setColorToItem(item));
              }
            });
          });
          //get all attr of dataset and filter out item in selX and filter out with selY attr
          const currentAttr = [...getItems(_schema_.value)].filter((item) => !selX.some(({content})=> content === item.content))
          for (let i = currentAttr.length - 1; i >= 0; i--) {
            const currentSelectedYUrl = selYUrl.filter(elm=>elm.content === currentAttr[i].content)
            if((currentAttr[i].type === "number" || currentAttr[i].type === "date") && (currentSelectedYUrl.length === newAggNumberAvailableList.length)){
              currentAttr.splice(i,1)
            }
            else if((currentAttr[i].type === "string") && (currentSelectedYUrl.length === newAggStringAvailableList.length)){
              currentAttr.splice(i,1)
            }
          }
          if(arrElm.chart === "linebar"){
            //set for normal shared only
            if(!searchParams.has("from_shared")){
              setLbState({
                itemsA: currentAttr,
                selectedX: selX,
                selectedY: selY,
              })
              _setSelectedLbStateFromShare_({
                itemsA: currentAttr,
                selectedX: selX,
                selectedY: selY,
              })
            }
            if(chart === "Line chart" || chart === "Bar chart"){
              if(searchParams.has("from_shared")){
                const all_attributes = getItems(_schema_.value)
                const curr_selectedY = [...selY]
                let newItemsAPie = [...currentAttr]
                let newItemsXPie = [...selX]
                let newItemsYPie = [...selY]
                let newItemsABar = [...currentAttr]
                let newItemsXBar = [...selX]
                let newItemsYBar = [...selY]
                let newItemsALb = [...currentAttr]
                let newItemsXLb = [...selX]
                let newItemsYLb = [...selY]
                let newItemsAScatter = [...currentAttr]
                let newItemsXScatter = [...selX]
                let newItemsYScatter = [...selY]
                let newItemsASunburst = [...currentAttr]
                let newItemsXSunburst = [...selX]
                let newItemsYSunburst = [...selY]
                //pie&bar2 has 1 y
                newItemsYPie = curr_selectedY.toSpliced(1)
                newItemsYBar = curr_selectedY.toSpliced(1)
                //attribute of pie&bar2 not in x and y
                newItemsAPie = all_attributes.filter(({content})=> !newItemsYPie.some(item => item.content === content) && !newItemsXPie.some(item => item.content === content))
                newItemsABar = all_attributes.filter(({content})=> !newItemsYBar.some(item => item.content === content) && !newItemsXBar.some(item => item.content === content))
                //sunburst
                newItemsYSunburst = curr_selectedY.toSpliced(1)
                newItemsASunburst = all_attributes.filter(({content})=> !newItemsYSunburst.some(item => item.content === content) && !newItemsXSunburst.some(item => item.content === content))
                //axis change call api again, reorder in attribute section will not trigger api call
                //set this to pie,bar and scatter,treemap to trigger call api when chart change
                setHasFilterChangePie(true)
                setHasFilterChangeBar(true)
                setHasFilterChangeScatterTreemap(true)
                setHasFilterChangeSunburst(true)
                //set state & statefromshare(when generate share link from shared link) to all chart
                setLbState(
                  {
                    itemsA: newItemsALb,
                    selectedX: newItemsXLb,
                    selectedY: newItemsYLb,
                  }
                )
                _setSelectedLbStateFromShare_(
                  {
                    itemsA: newItemsALb,
                    selectedX: newItemsXLb,
                    selectedY: newItemsYLb,
                  }
                )
                setPieState({
                  itemsA: newItemsAPie,
                  selectedX: newItemsXPie,
                  selectedY: newItemsYPie
                })
                _setSelectedPieStateFromShare_({
                  itemsA: newItemsAPie,
                  selectedX: newItemsXPie,
                  selectedY: newItemsYPie
                })
                setBarState({
                  itemsA: newItemsABar,
                  selectedX: newItemsXBar,
                  selectedY: newItemsYBar
                })
                _setSelectedBarStateFromShare_({
                  itemsA: newItemsABar,
                  selectedX: newItemsXBar,
                  selectedY: newItemsYBar
                })
                setSunburstState({
                  itemsA: newItemsASunburst,
                  selectedX: newItemsXSunburst,
                  selectedY: newItemsYSunburst
                })
                _setSelectedSunburstStateFromShare_({
                  itemsA: newItemsASunburst,
                  selectedX: newItemsXSunburst,
                  selectedY: newItemsYSunburst
                })
                setScatterTreemapState({
                  itemsA: newItemsAScatter,
                  selectedX: newItemsXScatter,
                  selectedY: newItemsYScatter
                })
                _setSelectedScatterTreemapStateFromShare_({
                  itemsA: newItemsAScatter,
                  selectedX: newItemsXScatter,
                  selectedY: newItemsYScatter
                })
              }
              setCurrentState({
                itemsA: currentAttr,
                selectedX: selX,
                selectedY: selY,
                update: false //to prevent call api twice cuz chartfilter will update to true later
              })
            }
          }
          else if(arrElm.chart === "scatter"){
            if(!searchParams.has("from_shared")){
              setScatterTreemapState({
                itemsA: currentAttr,
                selectedX: selX,
                selectedY: selY,
              })
              _setSelectedScatterTreemapStateFromShare_({
                itemsA: currentAttr,
                selectedX: selX,
                selectedY: selY,
              })
            }
            if(chart === "Scatter chart" || chart === "Treemap chart"){
              if(searchParams.has("from_shared")){
                const curr_selectedA = [...currentAttr]
                const curr_selectedX = [...selX]
                const curr_selectedY = [...selY]
                const all_attributes = getItems(_schema_.value)
                let newItemsAPie = [...currentAttr]
                let newItemsXPie = [...selX]
                let newItemsYPie = [...selY]
                let newItemsABar = [...currentAttr]
                let newItemsXBar = [...selX]
                let newItemsYBar = [...selY]
                let newItemsALb = [...currentAttr]
                let newItemsXLb = [...selX]
                let newItemsYLb = [...selY]
                let newItemsAScatter = [...currentAttr]
                let newItemsXScatter = [...selX]
                let newItemsYScatter = [...selY]
                let newItemsASunburst = [...currentAttr]
                let newItemsXSunburst = [...selX]
                let newItemsYSunburst = [...selY]
                //line&bar has 1 x
                newItemsXLb = curr_selectedX.toSpliced(1)
                //find the rest of x if x more than 1 to push x back to attribute of line&bar
                const tempX = curr_selectedX.filter(({content}) => !newItemsXLb.some(item=> item.content === content))
                newItemsALb = [...curr_selectedA, ...tempX]
                //if current x more than 2 get only first two and set to pie&bar2 x, pie&bar2 has only 1 y, attribute not in x & y as it was
                if(curr_selectedX.length > 2)
                  newItemsXBar = curr_selectedX.toSpliced(2)
                newItemsYBar = curr_selectedY.toSpliced(1)
                newItemsYPie = curr_selectedY.toSpliced(1)
                newItemsXPie = curr_selectedX.toSpliced(1)
                newItemsAPie = all_attributes.filter(({content})=> !newItemsYPie.some(item => item.content === content) && !newItemsXPie.some(item => item.content === content))
                newItemsABar = all_attributes.filter(({content})=> !newItemsYBar.some(item => item.content === content) && !newItemsXBar.some(item => item.content === content))
                //sunburst
                newItemsYSunburst = curr_selectedY.toSpliced(1)
                newItemsASunburst = all_attributes.filter(({content})=> !newItemsYSunburst.some(item => item.content === content) && !newItemsXSunburst.some(item => item.content === content))
                setHasFilterChangeLb(true)
                setHasFilterChangePie(true)
                setHasFilterChangeBar(true)
                setHasFilterChangeSunburst(true)
                //set state & statefromshare(when generate share link from shared link) to all chart
                setLbState(
                  {
                    itemsA: newItemsALb,
                    selectedX: newItemsXLb,
                    selectedY: newItemsYLb,
                  }
                )
                _setSelectedLbStateFromShare_(
                  {
                    itemsA: newItemsALb,
                    selectedX: newItemsXLb,
                    selectedY: newItemsYLb,
                  }
                )
                setPieState({
                  itemsA: newItemsAPie,
                  selectedX: newItemsXPie,
                  selectedY: newItemsYPie
                })
                _setSelectedPieStateFromShare_({
                  itemsA: newItemsAPie,
                  selectedX: newItemsXPie,
                  selectedY: newItemsYPie
                })
                setBarState({
                  itemsA: newItemsABar,
                  selectedX: newItemsXBar,
                  selectedY: newItemsYBar
                })
                _setSelectedBarStateFromShare_({
                  itemsA: newItemsABar,
                  selectedX: newItemsXBar,
                  selectedY: newItemsYBar
                })
                setSunburstState({
                  itemsA: newItemsASunburst,
                  selectedX: newItemsXSunburst,
                  selectedY: newItemsYSunburst
                })
                _setSelectedSunburstStateFromShare_({
                  itemsA: newItemsASunburst,
                  selectedX: newItemsXSunburst,
                  selectedY: newItemsYSunburst
                })
                setScatterTreemapState({
                  itemsA: newItemsAScatter,
                  selectedX: newItemsXScatter,
                  selectedY: newItemsYScatter
                })
                _setSelectedScatterTreemapStateFromShare_({
                  itemsA: newItemsAScatter,
                  selectedX: newItemsXScatter,
                  selectedY: newItemsYScatter
                })
              }
              setCurrentState({
                itemsA: currentAttr,
                selectedX: selX,
                selectedY: selY,
                update: false
              })
            }
          }
        }
        //for pie chart
        if(searchParams.has("pie")){
          const allItem = getItems(_schema_.value);
          let selX = [];
          let selY = [];
          let atts;
          const selXUrl = _selectedPieState_.selectedX.map((item) => {
            const obj = {};
            if (item.includes(":")) {
              obj["content"] = item.substring(0, item.indexOf(":"));
              obj["agg"] = item.substring(item.indexOf(":") + 1, item.length);
            } else {
              obj["content"] = item.substring(0, item.length);
              obj["agg"] = "";
            }
            return obj;
          });

          selXUrl.forEach((it) => {
            allItem.forEach((item) => {
              if (item["content"] === it["content"]) {
                item["agg"] = it["agg"];
                selX.push(setColorToItem(item));
              }
            });
          });

          const selYUrl = _selectedPieState_.selectedY.map((item) => {
            const obj = {};
            if (item.includes(":")) {
              obj["content"] = item.substring(0, item.indexOf(":"));
              obj["agg"] = item.substring(item.indexOf(":") + 1, item.length);
            } else {
              obj["content"] = item.substring(0, item.length);
              obj["agg"] = "";
            }
            return obj;
          });

          selYUrl.forEach((it, yIdx) => {
            allItem.forEach((item) => {
              if (item["content"] === it["content"]) {
                item["agg"] = it["agg"];
                item["id"] = `${(Date.now()+yIdx).toString()}:${it['content']}`
                if(it.agg === "")
                  item.noFunction = true
                selY.push(setColorToItem(item));
              }
            });
          });
          const xy = selX.concat(selY);
          atts = allItem.filter((item) => !xy.includes(item));
          if(!searchParams.has("from_shared")){
            setPieState({
              itemsA: atts,
              selectedX: selX,
              selectedY: selY,
            })
            _setSelectedPieStateFromShare_({
              itemsA: atts,
              selectedX: selX,
              selectedY: selY,
            })
          }
          if(chart === "Pie chart"){
            if(searchParams.has("from_shared")){
              const all_attributes = getItems(_schema_.value)
              let newItemsAPie = [...atts]
              let newItemsXPie = [...selX]
              let newItemsYPie = [...selY]
              let newItemsABar = [...atts]
              let newItemsXBar = [...selX]
              let newItemsYBar = [...selY]
              let newItemsALb = [...atts]
              let newItemsXLb = [...selX]
              let newItemsYLb = [...selY]
              let newItemsAScatter = [...atts]
              let newItemsXScatter = [...selX]
              let newItemsYScatter = [...selY]
              let newItemsASunburst = [...atts]
              let newItemsXSunburst = [...selX]
              let newItemsYSunburst = [...selY]
              //attribute of line&bar and scatter not in x (y at current chart always has only 1)
              newItemsALb = all_attributes.filter(({content})=> !newItemsXLb.some(item => item.content === content))
              newItemsAScatter = all_attributes.filter(({content})=> !newItemsXScatter.some(item => item.content === content))
              setHasFilterChangeLb(true)
              setHasFilterChangeScatterTreemap(true)
              setHasFilterChangeBar(true)
              setHasFilterChangeSunburst(true)

              //set state & statefromshare(when generate share link from shared link) to all chart
              setLbState(
                {
                  itemsA: newItemsALb,
                  selectedX: newItemsXLb,
                  selectedY: newItemsYLb,
                }
              )
              _setSelectedLbStateFromShare_(
                {
                  itemsA: newItemsALb,
                  selectedX: newItemsXLb,
                  selectedY: newItemsYLb,
                }
              )
              setPieState({
                itemsA: newItemsAPie,
                selectedX: newItemsXPie,
                selectedY: newItemsYPie
              })
              _setSelectedPieStateFromShare_({
                itemsA: newItemsAPie,
                selectedX: newItemsXPie,
                selectedY: newItemsYPie
              })
              setBarState({
                itemsA: newItemsABar,
                selectedX: newItemsXBar,
                selectedY: newItemsYBar
              })
              _setSelectedBarStateFromShare_({
                itemsA: newItemsABar,
                selectedX: newItemsXBar,
                selectedY: newItemsYBar
              })
              setSunburstState({
                itemsA: newItemsASunburst,
                selectedX: newItemsXSunburst,
                selectedY: newItemsYSunburst
              })
              _setSelectedSunburstStateFromShare_({
                itemsA: newItemsASunburst,
                selectedX: newItemsXSunburst,
                selectedY: newItemsYSunburst
              })
              setScatterTreemapState({
                itemsA: newItemsAScatter,
                selectedX: newItemsXScatter,
                selectedY: newItemsYScatter
              })
              _setSelectedScatterTreemapStateFromShare_({
                itemsA: newItemsAScatter,
                selectedX: newItemsXScatter,
                selectedY: newItemsYScatter
              })
            }
            setCurrentState({
              itemsA: atts,
              selectedX: selX,
              selectedY: selY,
              update: false
            })
          }
        }
        //for bar chart
        if(searchParams.has("bar")){
          const allItem = getItems(_schema_.value);
          let selX = [];
          let selY = [];
          let atts;
          const selXUrl = _selectedBarState_.selectedX.map((item) => {
            const obj = {};
            if (item.includes(":")) {
              obj["content"] = item.substring(0, item.indexOf(":"));
              obj["agg"] = item.substring(item.indexOf(":") + 1, item.length);
            } else {
              obj["content"] = item.substring(0, item.length);
              obj["agg"] = "";
            }
            return obj;
          });

          selXUrl.forEach((it) => {
            allItem.forEach((item) => {
              if (item["content"] === it["content"]) {
                item["agg"] = it["agg"];
                selX.push(setColorToItem(item));
              }
            });
          });

          const selYUrl = _selectedBarState_.selectedY.map((item) => {
            const obj = {};
            if (item.includes(":")) {
              obj["content"] = item.substring(0, item.indexOf(":"));
              obj["agg"] = item.substring(item.indexOf(":") + 1, item.length);
            } else {
              obj["content"] = item.substring(0, item.length);
              obj["agg"] = "";
            }
            return obj;
          });

          selYUrl.forEach((it, yIdx) => {
            allItem.forEach((item) => {
              if (item["content"] === it["content"]) {
                item["agg"] = it["agg"];
                item["id"] = `${(Date.now()+yIdx).toString()}:${it['content']}`
                if(it.agg === "")
                  item.noFunction = true
                selY.push(setColorToItem(item));
              }
            });
          });
          const xy = selX.concat(selY);
          atts = allItem.filter((item) => !xy.includes(item));
          if(!searchParams.has("from_shared")){//set for normal shared
            setBarState({
              itemsA: atts,
              selectedX: selX,
              selectedY: selY,
            })
            _setSelectedBarStateFromShare_({
              itemsA: atts,
              selectedX: selX,
              selectedY: selY,
            })
          }
          if(chart === "Bar chart2" || chart === "Line chart2"){
            //axis attr. from current chart is bar chart2
            //note: line chart2 use the same as bar chart2
            if(searchParams.has("from_shared")){
              const curr_selectedX = [...selX]
              const all_attributes = getItems(_schema_.value)
              let newItemsAPie = [...atts]
              let newItemsXPie = [...selX]
              let newItemsYPie = [...selY]
              let newItemsABar = [...atts]
              let newItemsXBar = [...selX]
              let newItemsYBar = [...selY]
              let newItemsALb = [...atts]
              let newItemsXLb = [...selX]
              let newItemsYLb = [...selY]
              let newItemsAScatter = [...atts]
              let newItemsXScatter = [...selX]
              let newItemsYScatter = [...selY]
              let newItemsASunburst = [...atts]
              let newItemsXSunburst = [...selX]
              let newItemsYSunburst = [...selY]
              //line&bar has 1 x
              newItemsXLb = curr_selectedX.toSpliced(1)
              newItemsXPie = curr_selectedX.toSpliced(1)
              newItemsAPie = all_attributes.filter(({content})=> !newItemsXPie.some(item => item.content === content) && !newItemsYPie.some(item=> item.content === content))
              //attribute of line&bar and scatter not in x (y at current chart always has only 1)
              newItemsALb = all_attributes.filter(({content})=> !newItemsXLb.some(item => item.content === content))
              newItemsAScatter = all_attributes.filter(({content})=> !newItemsXScatter.some(item => item.content === content))
              setHasFilterChangeLb(true)
              setHasFilterChangeScatterTreemap(true)
              setHasFilterChangePie(true)
              setHasFilterChangeSunburst(true)
              //set state & statefromshare(when generate share link from shared link) to all chart
              setLbState(
                {
                  itemsA: newItemsALb,
                  selectedX: newItemsXLb,
                  selectedY: newItemsYLb,
                }
              )
              _setSelectedLbStateFromShare_(
                {
                  itemsA: newItemsALb,
                  selectedX: newItemsXLb,
                  selectedY: newItemsYLb,
                }
              )
              setPieState({
                itemsA: newItemsAPie,
                selectedX: newItemsXPie,
                selectedY: newItemsYPie
              })
              _setSelectedPieStateFromShare_({
                itemsA: newItemsAPie,
                selectedX: newItemsXPie,
                selectedY: newItemsYPie
              })
              setBarState({
                itemsA: newItemsABar,
                selectedX: newItemsXBar,
                selectedY: newItemsYBar
              })
              _setSelectedBarStateFromShare_({
                itemsA: newItemsABar,
                selectedX: newItemsXBar,
                selectedY: newItemsYBar
              })
              setSunburstState({
                itemsA: newItemsASunburst,
                selectedX: newItemsXSunburst,
                selectedY: newItemsYSunburst
              })
              _setSelectedSunburstStateFromShare_({
                itemsA: newItemsASunburst,
                selectedX: newItemsXSunburst,
                selectedY: newItemsYSunburst
              })
              setScatterTreemapState({
                itemsA: newItemsAScatter,
                selectedX: newItemsXScatter,
                selectedY: newItemsYScatter
              })
              _setSelectedScatterTreemapStateFromShare_({
                itemsA: newItemsAScatter,
                selectedX: newItemsXScatter,
                selectedY: newItemsYScatter
              })
            }
            setCurrentState({
              itemsA: atts,
              selectedX: selX,
              selectedY: selY,
              update: false
            })
          }
        }
        
        //for sunburst chart
        if(searchParams.has("sunburst")){
          const allItem = getItems(_schema_.value);
          let selX = [];
          let selY = [];
          let atts;
          const selXUrl = _selectedSunburstState_.selectedX.map((item) => {
            const obj = {};
            if (item.includes(":")) {
              obj["content"] = item.substring(0, item.indexOf(":"));
              obj["agg"] = item.substring(item.indexOf(":") + 1, item.length);
            } else {
              obj["content"] = item.substring(0, item.length);
              obj["agg"] = "";
            }
            return obj;
          });

          selXUrl.forEach((it) => {
            allItem.forEach((item) => {
              if (item["content"] === it["content"]) {
                item["agg"] = it["agg"];
                selX.push(setColorToItem(item));
              }
            });
          });

          const selYUrl = _selectedSunburstState_.selectedY.map((item) => {
            const obj = {};
            if (item.includes(":")) {
              obj["content"] = item.substring(0, item.indexOf(":"));
              obj["agg"] = item.substring(item.indexOf(":") + 1, item.length);
            } else {
              obj["content"] = item.substring(0, item.length);
              obj["agg"] = "";
            }
            return obj;
          });

          selYUrl.forEach((it, yIdx) => {
            allItem.forEach((item) => {
              if (item["content"] === it["content"]) {
                item["agg"] = it["agg"];
                item["id"] = `${(Date.now()+yIdx).toString()}:${it['content']}`
                if(it.agg === "")
                  item.noFunction = true
                selY.push(setColorToItem(item));
              }
            });
          });
          const xy = selX.concat(selY);
          atts = allItem.filter((item) => !xy.includes(item));
          if(!searchParams.has("from_shared")){
            setSunburstState({
              itemsA: atts,
              selectedX: selX,
              selectedY: selY,
            })
            _setSelectedSunburstStateFromShare_({
              itemsA: atts,
              selectedX: selX,
              selectedY: selY,
            })
          }
          if(chart === "Sunburst chart"){
            if(searchParams.has("from_shared")){
              const curr_selectedX = [...selX]
              const all_attributes = getItems(_schema_.value)
              let newItemsAPie = [...atts]
              let newItemsXPie = [...selX]
              let newItemsYPie = [...selY]
              let newItemsABar = [...atts]
              let newItemsXBar = [...selX]
              let newItemsYBar = [...selY]
              let newItemsALb = [...atts]
              let newItemsXLb = [...selX]
              let newItemsYLb = [...selY]
              let newItemsAScatter = [...atts]
              let newItemsXScatter = [...selX]
              let newItemsYScatter = [...selY]
              let newItemsASunburst = [...atts]
              let newItemsXSunburst = [...selX]
              let newItemsYSunburst = [...selY]
              //line&bar has 1 x
              newItemsXLb = curr_selectedX.toSpliced(1)
              newItemsXPie = curr_selectedX.toSpliced(1)
              newItemsAPie = all_attributes.filter(({content})=> !newItemsXPie.some(item => item.content === content) && !newItemsYPie.some(item=> item.content === content))
              //attribute of line&bar and scatter not in x (y at current chart always has only 1)
              newItemsALb = all_attributes.filter(({content})=> !newItemsXLb.some(item => item.content === content))
              newItemsAScatter = all_attributes.filter(({content})=> !newItemsXScatter.some(item => item.content === content))
              //bar chart2
              if(curr_selectedX.length > 2)
                newItemsXBar = curr_selectedX.toSpliced(2)
              newItemsABar = all_attributes.filter(({content})=> !newItemsYBar.some(item => item.content === content) && !newItemsXBar.some(item => item.content === content))
        
              setHasFilterChangeLb(true)
              setHasFilterChangeScatterTreemap(true)
              setHasFilterChangePie(true)
              setHasFilterChangeBar(true)
              //set state & statefromshare(when generate share link from shared link) to all chart
              setLbState(
                {
                  itemsA: newItemsALb,
                  selectedX: newItemsXLb,
                  selectedY: newItemsYLb,
                }
              )
              _setSelectedLbStateFromShare_(
                {
                  itemsA: newItemsALb,
                  selectedX: newItemsXLb,
                  selectedY: newItemsYLb,
                }
              )
              setPieState({
                itemsA: newItemsAPie,
                selectedX: newItemsXPie,
                selectedY: newItemsYPie
              })
              _setSelectedPieStateFromShare_({
                itemsA: newItemsAPie,
                selectedX: newItemsXPie,
                selectedY: newItemsYPie
              })
              setBarState({
                itemsA: newItemsABar,
                selectedX: newItemsXBar,
                selectedY: newItemsYBar
              })
              _setSelectedBarStateFromShare_({
                itemsA: newItemsABar,
                selectedX: newItemsXBar,
                selectedY: newItemsYBar
              })
              setSunburstState({
                itemsA: newItemsASunburst,
                selectedX: newItemsXSunburst,
                selectedY: newItemsYSunburst
              })
              _setSelectedSunburstStateFromShare_({
                itemsA: newItemsASunburst,
                selectedX: newItemsXSunburst,
                selectedY: newItemsYSunburst
              })
              setScatterTreemapState({
                itemsA: newItemsAScatter,
                selectedX: newItemsXScatter,
                selectedY: newItemsYScatter
              })
              _setSelectedScatterTreemapStateFromShare_({
                itemsA: newItemsAScatter,
                selectedX: newItemsXScatter,
                selectedY: newItemsYScatter
              })
            }
            setCurrentState({
              itemsA: atts,
              selectedX: selX,
              selectedY: selY,
              update: false
            })
          }
        }
        
        //for scorecard use this
        if(searchParams.has("scorecard")){
          const selYScorecardUrl = _selectedScorecardState_.selectedY.map((item) => {
            const obj = {};
            if (item.includes(":")) {
              obj["content"] = item.substring(0, item.indexOf(":"));
              obj["agg"] = item.substring(item.indexOf(":") + 1, item.length);
            } else {
              obj["content"] = item.substring(0, item.length);
              obj["agg"] = "";
            }
            return obj;
          });

          const attrScorecard = [...getItems(_schema_.value)]
          selYScorecardUrl.forEach((it, yIdx) => {
            attrScorecard.forEach((item) => {
              let newY = {}
              if (item["content"] === it["content"]) {
                newY = {...item, id: `${(Date.now()+yIdx).toString()}:${it['content']}`, agg: it["agg"]}
                selYScorecard.push(setColorToItem(newY));
              }
            });
          });
          const currentAttrScorecard = [...getItems(_schema_.value)]//for new attr of scorecard
          for (let i = currentAttrScorecard.length - 1; i >= 0; i--) {
            const currentSelectedYScorecardUrl = selYScorecardUrl.filter(elm=>elm.content === currentAttrScorecard[i].content)
            if((currentAttrScorecard[i].type === "number" || currentAttrScorecard[i].type === "date") && (currentSelectedYScorecardUrl.length === aggNumberAvailableList.length)){
              currentAttrScorecard.splice(i,1)
            }
            else if((currentAttrScorecard[i].type === "string") && (currentSelectedYScorecardUrl.length === aggStringAvailableList.length)){
              currentAttrScorecard.splice(i,1)
            }
          }
          setScorecardState({
            itemsA: currentAttrScorecard,
            selectedX: [],
            selectedY: selYScorecard
          })
          _setSelectedScorecardStateFromShare_({
            itemsA: currentAttrScorecard,
            selectedX: [],
            selectedY: selYScorecard
          })
          if(chart === "Scorecard"){
            setCurrentState({
              itemsA: currentAttrScorecard,
              selectedX: [],
              selectedY: selYScorecard,
              update: false
            })
          }
        }

        //for heatmap
        
        if(searchParams.has("heatmap")){
          const allItemHeatmap = getItems(_schema_.value);
          if(_selectedHeatmapState_.itemsA.length === 0 && _selectedHeatmapState_.selectedY.length === 0){
            allItemHeatmap.forEach((elm)=>{
              elm["newContent"] = null
              attrItemsHeatmap.push(elm)
            })
          }
          else{
            const selHeatmapYUrl = _selectedHeatmapState_.selectedY.map((item) => {
              const obj = {};
              if (item.includes(":")) {
                obj["content"] = item.includes("<") ? item.substring(0, item.indexOf(":")).substring(0, item.indexOf("<")) : item.substring(0, item.indexOf(":"));
                obj["agg"] = item.substring(item.indexOf(":") + 1, item.length);
                obj["newContent"] = item.includes("<") ? item.substring(0, item.indexOf(":")).substring(item.indexOf("<")).slice(1, -1) : null;
              } else {
                obj["content"] = item.includes("<") ? item.substring(0,item.indexOf("<")) : item.substring(0, item.length)
                obj["agg"] = "";
                obj["newContent"] = item.includes("<") ? item.substring(item.indexOf("<")).slice(1, -1) : null
              }
              return obj;
            });
            selHeatmapYUrl.forEach((it) => {
              allItemHeatmap.forEach((item) => {
                if (item["content"] === it["content"]) {
                  item["agg"] = it["agg"];
                  item["newContent"] = it["newContent"] ? it["newContent"] : null
                  selYHeatmap.push(setColorToItem(item));
                }
              });
            });
            _selectedHeatmapState_.itemsA.forEach((item,idx)=>{
              allItemHeatmap.forEach((elm)=>{
                let contentName = item.includes("<") ? item.substring(0,item.indexOf("<")) : item.substring(0, item.length)
                let newContentName = item.includes("<") ? item.substring(item.indexOf("<")).slice(1, -1) : null
                if(elm["content"] === contentName){
                  elm["newContent"] = newContentName
                  attrItemsHeatmap.push(elm)
                }
              })
            })

            
          }
          const allAttrHeatmap = attrItemsHeatmap.concat(selYHeatmap)
          setHeatmapState({
            itemsA: attrItemsHeatmap,
            selectedX: [],
            selectedY: selYHeatmap
          })
          _setSelectedHeatmapStateFromShare_({
            itemsA: attrItemsHeatmap,
            selectedX: [],
            selectedY: selYHeatmap
          })
          if(chart === "Heatmap"){
            setCurrentState({
              itemsA: attrItemsHeatmap,
              selectedX: [],
              selectedY: selYHeatmap,
              update: false
            })
          }

          //check has newContent to open mapfieldmodal or not
          if(allAttrHeatmap.some(({newContent}) => newContent !== null)){
            setNewMapSchema({update: true, value: allAttrHeatmap})
            setMapFieldStatus(true)
          }
          else{
            setNewMapSchema({update: false, value: allAttrHeatmap})
            setMapFieldStatus(false)
          }
        }
      } else {//for normal link
        setLbState({
          itemsA: getItems(_schema_.value),
          selectedX: [],
          selectedY: [],
        })
        setPieState({
          itemsA: getItems(_schema_.value),
          selectedX: [],
          selectedY: [],
        })
        setBarState({
          itemsA: getItems(_schema_.value),
          selectedX: [],
          selectedY: [],
        })
        setSunburstState({
          itemsA: getItems(_schema_.value),
          selectedX: [],
          selectedY: [],
        })
        setScatterTreemapState({
          itemsA: getItems(_schema_.value),
          selectedX: [],
          selectedY: [],
        })
        setScorecardState({
          itemsA: getItems(_schema_.value),
          selectedX: [],
          selectedY: [],
        })
        setHeatmapState({
          itemsA: getItems(_schema_.value),
          selectedX: [],
          selectedY: [],
        })
        _setSelectedLbState_({
          itemsA: getItems(_schema_.value),
          selectedX: [],
          selectedY: [],
        })
        _setSelectedPieState_({
          itemsA: getItems(_schema_.value),
          selectedX: [],
          selectedY: [],
        })
        _setSelectedBarState_({
          itemsA: getItems(_schema_.value),
          selectedX: [],
          selectedY: [],
        })
        _setSelectedSunburstState_({
          itemsA: getItems(_schema_.value),
          selectedX: [],
          selectedY: [],
        })
        _setSelectedScatterTreemapState_({
          itemsA: getItems(_schema_.value),
          selectedX: [],
          selectedY: [],
        })
        _setSelectedScorecardState_({
          itemsA: getItems(_schema_.value),
          selectedX: [],
          selectedY: [],
        })
        _setSelectedHeatmapState_({
          itemsA: getItems(_schema_.value),
          selectedX: [],
          selectedY: [],
        })
        setCurrentState({
          itemsA: getItems(_schema_.value),
          selectedX: [],
          selectedY: [],
          update: true//not to false cause on load no x and y and heatmap must load geojson
        })
        setNewMapSchema({update: false, value: getItems(_schema_.value)})
      }
      if (searchParams.has("label")){
        const scoreCardLabelUrl  = _scoreCardLabel_.map((item) => {
            const obj = {};
            if (item.includes(":")) {
                obj["attr"] = item.substring(0, item.indexOf(":"));
                obj["newLabel"] = item.substring(item.indexOf(":") + 1, item.length);
            }
            else {
                obj["attr"] = item
            }
            return obj
        })
        _setScorecardLabel_(scoreCardLabelUrl)
      }
      if(searchParams.has("level")){
        setMapLevel(_mapLevel_)
      }
    }
    // eslint-disable-next-line
  }, [_schema_]);

  // get data on drag end || up date filter
  useMemo(async () => {
    if (
      !_baseUrl_.isUpdated ||
      !_isMapping_.isUpdated ||
      !_utfMapping_.isUpdated
    ) {
      return;
    }
    const searchParams = new URLSearchParams(window.location.search);
    //remove cuz on shared link when remove filter should update data
    // if (searchParams.has("chartfilter") && filters === "") {
    //   return;
    // }

    if(chart === "Scorecard" && currentState.selectedY.length !== 0 && currentState.update){
      setShouldRender(false);
      setChartLoading(true)
      const ft = filters === "" ? "" : `(${filters})`;

      let isGroup = false;
      let _records_ = "{";
      const recordsCheckList = [];
      const selectedList = [...currentState.selectedY];
      selectedList.forEach((selected) => {
        if (!recordsCheckList.includes(selected.content)) {
          let ct = selected.content;
          if (_isMapping_.value) {
            ct = "";
            Buffer.from(selected.content, "utf-8").forEach((hex) => {
              ct += `_${hex.toString(16).toUpperCase()}`;
            });
          }
          _records_ += `${ct},`;
          recordsCheckList.push(ct);
        }
      });
      _records_ = _records_.substring(0, _records_.length - 1);
      _records_ += "}";

      let _agg_ = "";
      const selectedContainAggList = selectedList.filter(
        (selected) => selected.agg !== ""
      );
      if (selectedContainAggList.length > 0) {
        _agg_ += "aggregate(";
        ["SUM", "AVG", "MAX", "MIN", "COUNT", "COUNT_D", "MED"].forEach(
          (element) => {
            const lst = selectedContainAggList.filter((s) => s.agg === element);
            if (lst.length !== 0) {
              _agg_ += `${element}:[`;
              lst.forEach((i) => {
                let ct = i.content;
                if (_isMapping_.value) {
                  ct = "";
                  Buffer.from(i.content, "utf-8").forEach((hex) => {
                    ct += `_${hex.toString(16).toUpperCase()}`;
                  });
                }
                _agg_ += `"${ct}",`;
              });
              _agg_ = _agg_.substring(0, _agg_.length - 1);
              _agg_ += "],";
            }
          }
        );
        const selectedNotContainAggList = selectedList.filter(
          (selected) => selected.agg === ""
        );

        if (selectedNotContainAggList.length !== 0) {
          isGroup = true;
          _agg_ += "GROUP_BY:[";
          selectedNotContainAggList.forEach((i) => {
            let ct = i.content;
            if (_isMapping_.value) {
              ct = "";
              Buffer.from(i.content, "utf-8").forEach((hex) => {
                ct += `_${hex.toString(16).toUpperCase()}`;
              });
            }
            _agg_ += `"${ct}",`;
          });
          _agg_ = _agg_.substring(0, _agg_.length - 1);
          _agg_ += "]";
        }
        _agg_ += "){result}";
      }

      try {
        let _data = await getData(
          `${_baseUrl_.value}/graphql?id=${datasetId}&dsname=${dsname}&query={records${ft}${_records_},${_agg_}}`,
          selectedContainAggList.length > 0,
          isGroup
        );
        if(_data){

          if (_isMapping_.value) {
            _data = dataMapping(_data, _utfMapping_);
          }

          setDataScore(_data);
          _setScorecardData_({..._data})
          setHasFilterChangeScorecard(false)
          setShouldRender(true);
          setChartLoading(false)
        }
      } catch (error) {
        setChartLoading(false)
        console.log("error >> ", error);
        history.push(window.location.pathname + "/error");
      }
    }
    else if((chart !== "Scorecard" && chart !== "Heatmap") && currentState.selectedX.length !== 0 && currentState.selectedY.length !== 0 && currentState.update){
      setShouldRender(false);
      setChartLoading(true)
      const ft = filters === "" ? "" : `(${filters})`;

      let isGroup = false;
      let _records_ = "{";
      const recordsCheckList = [];
      const selectedList = currentState.selectedX.concat(currentState.selectedY);

      selectedList.forEach((selected) => {
        if (!recordsCheckList.includes(selected.content)) {
          let ct = selected.content;
          if (_isMapping_.value) {
            ct = "";
            Buffer.from(selected.content, "utf-8").forEach((hex) => {
              ct += `_${hex.toString(16).toUpperCase()}`;
            });
          }
          _records_ += `${ct},`;
          recordsCheckList.push(ct);
        }
      });
      _records_ = _records_.substring(0, _records_.length - 1);
      _records_ += "}";

      let _agg_ = "";
      const selectedContainAggList = selectedList.filter(
        (selected) => selected.agg !== ""
      );

      if (selectedContainAggList.length > 0) {
        _agg_ += "aggregate(";
        ["SUM", "AVG", "MAX", "MIN", "COUNT", "COUNT_D", "MED"].forEach(
          (element) => {
            const lst = selectedContainAggList.filter((s) => s.agg === element);
            if (lst.length !== 0) {
              _agg_ += `${element}:[`;
              lst.forEach((i) => {
                let ct = i.content;
                if (_isMapping_.value) {
                  ct = "";
                  Buffer.from(i.content, "utf-8").forEach((hex) => {
                    ct += `_${hex.toString(16).toUpperCase()}`;
                  });
                }
                _agg_ += `"${ct}",`;
              });
              _agg_ = _agg_.substring(0, _agg_.length - 1);
              _agg_ += "],";
            }
          }
        );
        const selectedNotContainAggList = selectedList.filter(
          (selected) => selected.agg === ""
        );

        if (selectedNotContainAggList.length !== 0) {
          isGroup = true;
          _agg_ += "GROUP_BY:[";
          selectedNotContainAggList.forEach((i) => {
            let ct = i.content;
            if (_isMapping_.value) {
              ct = "";
              Buffer.from(i.content, "utf-8").forEach((hex) => {
                ct += `_${hex.toString(16).toUpperCase()}`;
              });
            }
            _agg_ += `"${ct}",`;
          });
          _agg_ = _agg_.substring(0, _agg_.length - 1);
          _agg_ += "]";
        }
        _agg_ += "){result}";
      }

      try {
        let _data = await getData(
          `${_baseUrl_.value}/graphql?id=${datasetId}&dsname=${dsname}&query={records${ft}${_records_},${_agg_}}`,
          selectedContainAggList.length > 0,
          isGroup
        );
        if(_data){

          if (_isMapping_.value) {
            _data = dataMapping(_data, _utfMapping_);
          }
          if(chart === "Line chart" || chart === "Bar chart"){
            _setLbData_({..._data})
            setHasFilterChangeLb(false)
          }
          else if(chart === "Pie chart"){
            _setPieData_({..._data})
            setHasFilterChangePie(false)
          }
          else if(chart === "Bar chart2" || chart === "Line chart2"){
            _setBarData_({..._data})
            setHasFilterChangeBar(false)
          }
          else if(chart === "Sunburst chart"){
            _setSunburstData_({..._data})
            setHasFilterChangeSunburst(false)
          }
          else if(chart === "Scatter chart" || chart === "Treemap chart"){
            _setScatterTreemapData_({..._data})
            setHasFilterChangeScatterTreemap(false)
          }
          setData(_data);
          setShouldRender(true);
          setChartLoading(false)
          //to prevent sunburst height not render on load when current page is share map and charttype is sunburst, this will trigger when click graph menu and render sunburst chart on tabindex useEffect
          if((searchParams.has("t") && tabIndex === 2 && chart === "Sunburst chart")){
            setCurrentState({
              itemsA: [],
              selectedX: [],
              selectedY: [],
              update: false
            })
            setData({ value: [], numData: 0, isUpdated: false })
          }

        }
      } catch (error) {
        setChartLoading(false)
        console.log("error >> ", error);
        history.push(window.location.pathname + "/error");
      }
    }
    else if(chart === "Heatmap" && currentState.update){
      // if(!mapFieldStatus)
      //   setVisible(true)
      if(mapFieldStatus){
        setShapeRender(false)
        setChartLoading(true)
        try{
          let api_path="province", req_body = {}, areaFields = [], arrPattern = {region: 1, province: 2, district: 3, sub_district: 4 } ;
          if(mapLevel.level === "region"){
            api_path = "region"
            let regionObj = newMapSchema.value.find(({newContent}) => newContent?.toLowerCase() === "region")
            if(regionObj){
              //assign region name to obj
              // regionObj["region"] = mapLevel.properties?.NAME_TH
              areaFields.push(regionObj)
            }
            setSelectedXHeatmap([])
            // if(mapLevel.properties?.NAME_TH)
            //   req_body["REGION_NAME"] = mapLevel.properties.NAME_TH
          }
          else if(mapLevel.level === "region_province"){
            api_path = "province"
            req_body["REGION_NAME"] = mapLevel.properties.NAME_TH
            let fieldArr = newMapSchema.value.filter(({newContent}) => newContent?.toLowerCase() === "region" || newContent?.toLowerCase() === "province")
            //handle field for query filter
            fieldArr.forEach((elm)=>{
              if(elm.newContent === "region")
                elm["region"] = mapLevel.properties?.NAME_TH
              areaFields.push(elm)
            })
            let xField = newMapSchema.value.filter(({newContent}) => newContent?.toLowerCase() === "region")
            xField.sort((x, y)=>arrPattern[x.newContent] - arrPattern[y.newContent]);
            const newXField = xField.map((t)=>{
              return {
                id: t.id,
                agg: "",
                color: "#189FF0",
                newContent: t.newContent,
                icon: t.icon,
                type: t.type
              }
            })
            setSelectedXHeatmap(newXField)
          }
          else if(mapLevel.level === "province"){
            api_path = "amphoe"
            req_body["PROVINCE_CODE"] = mapLevel.properties.PROV_CODE

            let fieldArr = newMapSchema.value.filter(({newContent}) => newContent?.toLowerCase() === "province" || newContent?.toLowerCase() === "district")
            fieldArr.forEach((elm)=>{
              //when click province got province name with key PROV_NAM_T
              if(elm.newContent === "province")
                elm["province"] = mapLevel.properties?.PROV_NAM_T
              areaFields.push(elm)
            })
            let xField = newMapSchema.value.filter((item) => item.newContent?.toLowerCase() === "province")
            let regionObj = newMapSchema.value.find(({newContent}) => newContent?.toLowerCase() === "region")
            if(regionObj && mapLevel.mode === "region"){
              xField = [...xField,regionObj]
            }
            xField.sort((x, y)=>arrPattern[x.newContent] - arrPattern[y.newContent]);
            const newXField = xField.map((t)=>{
              return {
                id: t.id,
                agg: "",
                color: "#189FF0",
                newContent: t.newContent,
                icon: t.icon,
                type: t.type
              }
            })
            setSelectedXHeatmap(newXField)
          }
          else if(mapLevel.level === "amphoe"){
            api_path = "tambol"
            req_body["PROVINCE_CODE"] = mapLevel.properties.PV_CODE
            req_body["AMPHOE_CODE"] = mapLevel.properties.AP_CODE
            let fieldArr = newMapSchema.value.filter(({newContent}) => newContent?.toLowerCase() === "province" || newContent?.toLowerCase() === "district" || newContent?.toLowerCase() === "sub_district")
            fieldArr.forEach((elm)=>{
              if(elm.newContent === "province")
                elm["province"] = mapLevel.properties?.PV_TN
              else if(elm.newContent === "district")
                elm["district"] = mapLevel.properties?.AP_TN
              areaFields.push(elm)
            })
            let xField = newMapSchema.value.filter(({newContent}) => newContent?.toLowerCase() === "province" || newContent?.toLowerCase() === "district")
            let regionObj = newMapSchema.value.find(({newContent}) => newContent?.toLowerCase() === "region")
            if(regionObj && mapLevel.mode === "region"){
              xField = [...xField,regionObj]
            }
            xField.sort((x, y)=>arrPattern[x.newContent] - arrPattern[y.newContent]);
            const newXField = xField.map((t)=>{
              return {
                id: t.id,
                agg: "",
                color: "#189FF0",
                newContent: t.newContent,
                icon: t.icon,
                type: t.type
              }
            })
            setSelectedXHeatmap(newXField)
          }
          else if(mapLevel.level === "tambol"){
            api_path = "tambol"
            req_body["PROVINCE_CODE"] = mapLevel.properties.P_CODE
            req_body["AMPHOE_CODE"] = mapLevel.properties.AM_CODE
            req_body["TAMBOL_CODE"] = mapLevel.properties.TAM_CODE.toString()
            let fieldArr = newMapSchema.value.filter(({newContent}) => newContent?.toLowerCase() === "province" || newContent?.toLowerCase() === "district" || newContent?.toLowerCase() === "sub_district")
            fieldArr.forEach((elm)=>{
              if(elm.newContent === "province")
                elm["province"] = mapLevel.properties?.P_NAME_T
              else if(elm.newContent === "district")
                elm["district"] = mapLevel.properties?.A_NAME_T
              else if(elm.newContent === "sub_district")
                elm["sub_district"] = mapLevel.properties?.T_NAME_T
              areaFields.push(elm)
            })
            let regionObj = newMapSchema.value.find(({newContent}) => newContent?.toLowerCase() === "region")
            if(regionObj && mapLevel.mode === "region"){
              fieldArr = [...fieldArr,regionObj]
            }
            fieldArr.sort((x, y)=>arrPattern[x.newContent] - arrPattern[y.newContent]);
            const newXField = fieldArr.map((t)=>{
              return {
                id: t.id,
                agg: "",
                color: "#189FF0",
                newContent: t.newContent,
                icon: t.icon,
                type: t.type
              }
            })
            setSelectedXHeatmap(newXField)
          }
          else if(mapLevel.level === "country"){
            let provinceObj = newMapSchema.value.find(({newContent}) => newContent?.toLowerCase() === "province")
            if(provinceObj){
              areaFields.push(provinceObj)
            }
            setSelectedXHeatmap([])
          }
          
          const url = `${process.env.REACT_APP_API_MAP_HOST}/map/${api_path}`
          let geo_data = await getMapData(url, req_body)
          if(searchParams.has("level")){
            let currUrl = JSON.parse(decodeURIComponent(searchParams.get("level")))
            if((mapLevel.level === currUrl.level) && currUrl.level !== "region" && currUrl.level !== "country" && currUrl.level !== "region_province"){
              if(mapLevel.level === "province"){
                mapLevel.properties["PROV_NAM_T"] = geo_data[0]["properties"]["PV_TN"]
                //update province name
                areaFields.forEach((elm)=>{
                  if(elm.newContent === "province")
                    elm["province"] = mapLevel.properties?.PROV_NAM_T
                })
              }
              else if(mapLevel.level === "amphoe"){
                //set current value from geo_data to key prov, amp name for this level
                mapLevel.properties["PV_TN"] = geo_data[0]["properties"]["P_NAME_T"]
                mapLevel.properties["AP_TN"] = geo_data[0]["properties"]["A_NAME_T"]
                areaFields.forEach((elm)=>{
                  if(elm.newContent === "province")
                    elm["province"] = mapLevel.properties?.PV_TN
                  else if(elm.newContent === "district")
                    elm["district"] = mapLevel.properties?.AP_TN
                })
              }
              else if(mapLevel.level === "tambol"){
                mapLevel.properties["P_NAME_T"] = geo_data[0]["properties"]["P_NAME_T"]
                mapLevel.properties["A_NAME_T"] = geo_data[0]["properties"]["A_NAME_T"]
                mapLevel.properties["T_NAME_T"] = geo_data[0]["properties"]["T_NAME_T"]
                areaFields.forEach((elm)=>{
                  if(elm.newContent === "province")
                    elm["province"] = mapLevel.properties?.P_NAME_T
                  else if(elm.newContent === "district")
                    elm["district"] = mapLevel.properties?.A_NAME_T
                  else if(elm.newContent === "sub_district")
                    elm["sub_district"] = mapLevel.properties?.T_NAME_T
                })
              }
              if(mapLevel.mode === "region")
                mapLevel.properties["REGION_NAME"] = geo_data[0]["properties"]["REGION_NAME"]
              _setMapLevel_(mapLevel)
            }
          }
          if(geo_data && currentState.selectedY.length !== 0 && areaFields.length !== 0){
            setShouldRender(false);
            let ft = heatmapFilter === "" ? "" : `(${heatmapFilter})`;
            //when click map level to filter condition for query check have field in data source
            if((mapLevel.level === "province" && newMapSchema.value.some(({newContent}) => newContent?.toLowerCase() === "province")) || (mapLevel.level === "amphoe" && newMapSchema.value.some(({newContent}) => newContent?.toLowerCase() === "district")) || (mapLevel.level === "tambol" && newMapSchema.value.some(({newContent}) => newContent?.toLowerCase() === "sub_district")) || (mapLevel.level === "region_province" && newMapSchema.value.some(({newContent}) => newContent?.toLowerCase() === "region"))){
              let AND_Condition = "AND: ["
              let OR_Condition = "OR: ["
              ft = ""
              areaFields.forEach((item)=>{
                let ct = item.content;
                if (_isMapping_.value) {
                  ct = "";
                  Buffer.from(item.content, "utf-8").forEach((hex) => {
                    ct += `_${hex.toString(16).toUpperCase()}`;
                  });
                }
                if((item.newContent?.toLowerCase() === "province") && item.province){
                  AND_Condition += `{field: "${ct}",operator: "EQUALS(${item.province})"},`
                }
                else if((item.newContent?.toLowerCase() === "district") && item.district){
                  AND_Condition += `{field: "${ct}",operator: "EQUALS(${item.district})"},`
                }
                else if((item.newContent?.toLowerCase() === "sub_district") && item.sub_district){
                  AND_Condition += `{field: "${ct}",operator: "EQUALS(${item.sub_district})"},`
                }
                else if((item.newContent?.toLowerCase() === "region") && item.region){
                  AND_Condition += `{field: "${ct}",operator: "EQUALS(${item.region})"},`
                }
              })
              if(heatmapFilter){
                for (let i = 0; i < heatmapFiltersObj.length; i++) {
                  let col = heatmapFiltersObj[i]["col"];
                  if (_isMapping_.value) {
                    col = "";
                    Buffer.from(heatmapFiltersObj[i]["col"], "utf-8").forEach((hex) => {
                      col += `_${hex.toString(16).toUpperCase()}`;
                    });
                  }
                  let operator
                  if (heatmapFiltersObj[i]["operator"] === "Contains") {
                    operator = `CONTAINS(${heatmapFiltersObj[i]["condition"]})`;
                  } else if (heatmapFiltersObj[i]["operator"] === "Equals") {
                    operator = `EQUALS(${heatmapFiltersObj[i]["condition"]})`;
                  } else if (heatmapFiltersObj[i]["operator"] === "Not Equals") {
                    operator = `NOTEQUALS(${heatmapFiltersObj[i]["condition"]})`;
                  } else if (heatmapFiltersObj[i]["operator"] === "RegEx") {
                    operator = `REGEX(${heatmapFiltersObj[i]["condition"]})`;
                  } else if (heatmapFiltersObj[i]["operator"] === "Not Contains") {
                    operator = `NOTCONTAINS(${heatmapFiltersObj[i]["condition"]})`;
                  } else {
                    operator = `${heatmapFiltersObj[i]["operator"]}${heatmapFiltersObj[i]["condition"]}`;
                  }
                  if(heatmapFiltersObj.length === 1){
                    AND_Condition += `{field: "${col}",operator: "${operator}"}`
                    ft += `(${AND_Condition}])`
                  }else{
                    if(heatmapFiltersObj[i]['logic'] && heatmapFiltersObj[i]['logic'] === "OR"){
                      OR_Condition += `{field: "${col}",operator: "${operator}"},`
                    }
                    else if(heatmapFiltersObj[i]['logic'] && heatmapFiltersObj[i]['logic'] === "AND"){
                      AND_Condition += `{field: "${col}",operator: "${operator}"},`
                    }
                    else{
                      //check index 1 logic is or ? and
                      if(heatmapFiltersObj[1]['logic'] === "OR"){
                        OR_Condition += `{field: "${col}",operator: "${operator}"},`
                      }
                      else{
                        AND_Condition += `{field: "${col}",operator: "${operator}"},`
                      }
                    }
                  }
                }
                if(heatmapFiltersObj.some(({logic}) => logic === "AND")){
                  ft += `(${AND_Condition}]${heatmapFiltersObj.some(({logic}) => logic === "OR")?',':')'}`
                }
                if(heatmapFiltersObj.some(({logic}) => logic === "OR")){
                  ft += `${heatmapFiltersObj.some(({logic}) => logic === "AND") ? "" : "("}${OR_Condition}])`
                }
              }
              else{
                ft += `(${AND_Condition}])`
              }
            }

            let isGroup = false;
            let _records_ = "{";
            const recordsCheckList = [];
            const selectedList = areaFields.concat(currentState.selectedY);
            selectedList.forEach((selected) => {
              if (!recordsCheckList.includes(selected.content)) {
                let ct = selected.content;
                if (_isMapping_.value) {
                  ct = "";
                  Buffer.from(selected.content, "utf-8").forEach((hex) => {
                    ct += `_${hex.toString(16).toUpperCase()}`;
                  });
                }
                _records_ += `${ct},`;
                recordsCheckList.push(ct);
              }
            });
            _records_ = _records_.substring(0, _records_.length - 1);
            _records_ += "}";

            let _agg_ = "";
            const selectedContainAggList = selectedList.filter(
              (selected) => selected.agg !== ""
            );

            if (selectedContainAggList.length > 0) {
              _agg_ += "aggregate(";
              ["SUM", "AVG", "MAX", "MIN", "COUNT", "COUNT_D", "MED"].forEach(
                (element) => {
                  const lst = selectedContainAggList.filter((s) => s.agg === element);
                  if (lst.length !== 0) {
                    _agg_ += `${element}:[`;
                    lst.forEach((i) => {
                      let ct = i.content;
                      if (_isMapping_.value) {
                        ct = "";
                        Buffer.from(i.content, "utf-8").forEach((hex) => {
                          ct += `_${hex.toString(16).toUpperCase()}`;
                        });
                      }
                      _agg_ += `"${ct}",`;
                    });
                    _agg_ = _agg_.substring(0, _agg_.length - 1);
                    _agg_ += "],";
                  }
                }
              );
              const selectedNotContainAggList = selectedList.filter(
                (selected) => selected.agg === ""
              );

              if (selectedNotContainAggList.length !== 0) {
                isGroup = true;
                _agg_ += "GROUP_BY:[";
                selectedNotContainAggList.forEach((i) => {
                  let ct = i.content;
                  if (_isMapping_.value) {
                    ct = "";
                    Buffer.from(i.content, "utf-8").forEach((hex) => {
                      ct += `_${hex.toString(16).toUpperCase()}`;
                    });
                  }
                  _agg_ += `"${ct}",`;
                });

                _agg_ = _agg_.substring(0, _agg_.length - 1);
                _agg_ += "]";
              }
              _agg_ += "){result}";
            }

            try {
              let _data = await getData(
                `${_baseUrl_.value}/graphql?id=${datasetId}&dsname=${dsname}&query={records${ft}${_records_},${_agg_}}`,
                selectedContainAggList.length > 0,
                isGroup
              );
              if(_data){

                if (_isMapping_.value) {
                  _data = dataMapping(_data, _utfMapping_);
                }
                if(mapLevel.level === "country" || mapLevel.level === "region_province"){
                  //render country map
                  //check have field to get field name
                  let attrName = newMapSchema.value.find(({newContent}) => newContent?.toLowerCase() === "province")
                  if(attrName){
                    if(mapLevel.mode === "region" && !newMapSchema.value.find(({newContent}) => newContent?.toLowerCase() === "region")){
                      _data.value = _data.value.filter(item => {
                        return geo_data.some(({properties}) => properties.PROV_NAM_T === item[attrName.content])
                      })
                    }
                    for (let item_area of geo_data) {
                      for (let item_detail of _data.value) {
                        if (item_area.properties.PROV_NAM_T === item_detail[attrName.content]) {
                          item_area.properties.count_item = item_detail[currentState.selectedY[0].agg === "" ? currentState.selectedY[0].content : `${currentState.selectedY[0].agg}(${currentState.selectedY[0].content})`]
                          item_area.properties.attr_name = currentState.selectedY[0].content
                        }
                      }
                    }
                  }
                }
                else if(mapLevel.level === "province"){
                  //render province shape
                  //check have field to get field name
                  let attrName = newMapSchema.value.find(({newContent}) => newContent?.toLowerCase() === "district")
                  if(attrName){
                    for (let item_area of geo_data) {
                      for (let item_detail of _data.value) {
                        if (item_area.properties.AP_TN === item_detail[attrName.content]) {
                          item_area.properties.count_item = item_detail[currentState.selectedY[0].agg === "" ? currentState.selectedY[0].content : `${currentState.selectedY[0].agg}(${currentState.selectedY[0].content})`]
                          item_area.properties.attr_name = currentState.selectedY[0].content
                        }
                      }
                    }
                  }
                }
                else if(mapLevel.level === "amphoe" || mapLevel.level === "tambol"){
                  //render amphoe||tambol shape
                  //get attr name to access this field through the data response
                  let attrName = newMapSchema.value.find(({newContent}) => newContent?.toLowerCase() === "sub_district")
                  if(attrName){
                    for (let item_area of geo_data) {
                      for (let item_detail of _data.value) {
                        if (item_area.properties.T_NAME_T === item_detail[attrName.content]) {
                          item_area.properties.count_item = item_detail[currentState.selectedY[0].agg === "" ? currentState.selectedY[0].content : `${currentState.selectedY[0].agg}(${currentState.selectedY[0].content})`]
                          item_area.properties.attr_name = currentState.selectedY[0].content
                        }
                      }
                    }
                  }
                }
                else if(mapLevel.level === "region"){
                  //render region shape
                  //get attr name to access this field through the data response
                  let attrName = newMapSchema.value.find(({newContent}) => newContent?.toLowerCase() === "region")
                  if(attrName){
                    for (let item_area of geo_data) {
                      for (let item_detail of _data.value) {
                        if (item_area.properties.NAME_TH === item_detail[attrName.content]) {
                          item_area.properties.count_item = item_detail[currentState.selectedY[0].agg === "" ? currentState.selectedY[0].content : `${currentState.selectedY[0].agg}(${currentState.selectedY[0].content})`]
                          item_area.properties.attr_name = currentState.selectedY[0].content
                        }
                      }
                    }
                  }
                }
                setData(_data);
                _setHeatmapData_({..._data})
                setShouldRender(true);
              }
            } catch (error) {
              console.log("error >> ", error);
              history.push(window.location.pathname + "/error");
            }
          }
          setTimeout(()=>{
            setChartLoading(false)
            setGeoJson(geo_data)
            setShapeRender(true)
          },500)
        }
        catch(error){
          setChartLoading(false)
          console.log("error >> ", error);
          history.push(window.location.pathname + "/error");
        }
      }
    }
  // eslint-disable-next-line
  }, [_baseUrl_, _isMapping_, _utfMapping_, history, currentState]);

  // render
  return useMemo(() => {
    const getList = (id) => {
      if(chart === "Line chart" || chart === "Bar chart")
        return lbState[id2List[id]]
      if(chart === "Pie chart")
        return pieState[id2List[id]]
      if(chart === "Bar chart2" || chart === "Line chart2")
        return barState[id2List[id]]
      if(chart === "Sunburst chart")
        return sunburstState[id2List[id]]
      if(chart === "Scatter chart" || chart === "Treemap chart")
        return scatterTreemapState[id2List[id]]
      // if(chart === "Treemap chart")
      //   return treemapState[id2List[id]]
      if(chart === "Scorecard")
        return scorecardState[id2List[id]]
      if(chart === "Heatmap")
        return heatmapState[id2List[id]]
    };

    const checkDropableX = () => {
      /* if (
        lbState.selectedX.length === 1 &&
        state.selectedY.length === 2 &&
        (chart === "Line chart" || chart === "Bar chart")
      ) {
        return true;
      } */
      if (
        currentState.selectedX.length === 1 &&
        (chart === "Line chart" || chart === "Bar chart" || chart === "Pie chart")
      ) {
        return true;
      }
      if(chart === "Scorecard" || chart === "Heatmap"){
        return true
      }
    };
    const checkDropableY = () =>{
      if (
        (currentState.selectedY.length === 1 && chart === "Heatmap") || (chart === "Heatmap" && geoJson.length === 0)
      ) {
        return true;
      }
      if (currentState.selectedY.length === 1 && (chart === "Pie chart" || chart === "Bar chart2" || chart === "Line chart2" || chart === "Sunburst chart")) {
        return true;
      }
    }
    /*
    const checkDropableY = () => {
      if (
        lbState.selectedX.length === 2 &&
        state.selectedY.length === 1 &&
        (chart === "Line chart" || chart === "Bar chart")
      ) {
        return true;
      }
      if (
        state.selectedY.length === 2 &&
        (chart === "Line chart" || chart === "Bar chart")
      ) {
        return true;
      }
      return false;
    }; */

    const spanStyleAttributes = () => {
      if (_breakpoint_ === "xxl") {
        return 2;
      } else if (_breakpoint_ === "xl" || _breakpoint_ === "lg") {
        return 3;
      } else {
        return 4;
      }
    };
    const spanStyleChart = () => {
      if (_embed_) {
        return 24;
      } else {
        if (_breakpoint_ === "xxl") {
          return 22;
        } else if (_breakpoint_ === "xl" || _breakpoint_ === "lg") {
          return 21;
        } else {
          return 20;
        }
      }
    };

    const updateState = (item = null, is_remove = false) => {
      let currentAStatic = [...currentState.itemsA]
      const currentStateY = [...currentState.selectedY]
      const currentStateX = [...currentState.selectedX]
      //is_remove for remove button event clicked
      if((chart !== "Pie chart" && chart !== "Heatmap" && chart !== "Bar chart2" && chart !== "Line chart2" && chart !== "Sunburst chart") && is_remove){
        if(chart === "Scorecard"){
          //also remove from score label too
          let labelScoreIdx = _scoreCardLabel_.findIndex(({attr}) => attr === `${item.agg}(${item.content})`);
          _scoreCardLabel_.splice(labelScoreIdx, 1);
        }
        let newAggNumberAvailableList = [...aggNumberAvailableList]
        let newAggStringAvailableList = [...aggStringAvailableList]
        
        if(chart !== "Scorecard"){
          newAggNumberAvailableList = [...aggNumberAvailableList, "no function"]
          newAggStringAvailableList = [...aggStringAvailableList, "no function"]
        }
        let getItemAttr = getItems(_schema_.value).find(elm=>elm.content === item.content)
        //remove from axis, item each content can stored only one axis in the same time, when click remove once it's never duplicate content
        let isXAxis = currentStateX.some(({content})=>content === item.content)
        if(isXAxis){
          currentStateX.splice(currentStateX.indexOf(item), 1)
          currentAStatic = [...currentAStatic, getItemAttr]
        }else{
          currentStateY.splice(currentStateY.indexOf(item), 1)
        }
        
        let currentAttrDropped = currentStateY.filter(elm=>elm.content === item.content)
        if((item.type === "number" || item.type === "date") && (currentAttrDropped.length === newAggNumberAvailableList.length - 1)){
          currentAStatic = [...currentAStatic, getItemAttr]
        }
        else if((item.type === "string") && (currentAttrDropped.length === newAggStringAvailableList.length - 1)){
          currentAStatic = [...currentAStatic, getItemAttr]
        }
        currentAStatic = _.sortBy(currentAStatic, ["type", "content"])
      }
      else if((chart === "Pie chart" || chart === "Heatmap" || chart === "Bar chart2" || chart === "Line chart2" || chart === "Sunburst chart") && is_remove){
        if(chart === "Heatmap"){
            currentStateY.splice(currentStateY.indexOf(item), 1)
            let getItemAttr = newMapSchema.value.find(elm=>elm.content === item.content)
            if(getItemAttr.type === "string"){
              getItemAttr.color = "#189FF0"
            }
            currentState.itemsA.push(getItemAttr)
        }
        else{
          let isXAxis = currentStateX.some(({content})=>content === item.content)
          // let isYAxis = state.selectedY.some(({content})=>content === item.content)
          if(isXAxis){
            currentStateX.splice(currentStateX.indexOf(item), 1)
          }else{
            currentStateY.splice(currentStateY.indexOf(item), 1)
          }
          let getItemAttr = getItems(_schema_.value).find(elm=>elm.content === item.content)
          currentState.itemsA.push(getItemAttr)
          //also update currentAStatic for bar2 & pie to prevent wrong attribute list when handle share axis below
          currentAStatic = [...currentAStatic, getItemAttr]
          currentAStatic = _.sortBy(currentAStatic, ["type", "content"])
        }
      }
      if(chart !== "Scorecard" && chart !== "Heatmap"){
        const curr_selectedA = [...currentAStatic]
        const curr_selectedX = [...currentStateX]
        const curr_selectedY = [...currentStateY]
        const all_attributes = getItems(_schema_.value)
        let newItemsAPie = [...currentAStatic]
        let newItemsXPie = [...currentStateX]
        let newItemsYPie = [...currentStateY]
        let newItemsABar = [...currentAStatic]
        let newItemsXBar = [...currentStateX]
        let newItemsYBar = [...currentStateY]
        let newItemsALb = [...currentAStatic]
        let newItemsXLb = [...currentStateX]
        let newItemsYLb = [...currentStateY]
        let newItemsAScatter = [...currentAStatic]
        let newItemsXScatter = [...currentStateX]
        let newItemsYScatter = [...currentStateY]
        let newItemsASunburst = [...currentAStatic]
        let newItemsXSunburst = [...currentStateX]
        let newItemsYSunburst = [...currentStateY]
        if(chart === "Line chart" || chart === "Bar chart"){
          //pie&bar2 has 1 y
          newItemsYPie = curr_selectedY.toSpliced(1)
          newItemsYBar = curr_selectedY.toSpliced(1)
          //attribute of pie&bar2 not in x and y
          newItemsAPie = all_attributes.filter(({content})=> !newItemsYPie.some(item => item.content === content) && !newItemsXPie.some(item => item.content === content))
          newItemsABar = all_attributes.filter(({content})=> !newItemsYBar.some(item => item.content === content) && !newItemsXBar.some(item => item.content === content))
          //sunburst
          newItemsYSunburst = curr_selectedY.toSpliced(1)
          newItemsASunburst = all_attributes.filter(({content})=> !newItemsYSunburst.some(item => item.content === content) && !newItemsXSunburst.some(item => item.content === content))
          //axis change call api again, reorder in attribute section will not trigger api call
          //set this to pie,bar and scatter,treemap to trigger call api when chart change
          setHasFilterChangePie(true)
          setHasFilterChangeBar(true)
          setHasFilterChangeScatterTreemap(true)
          setHasFilterChangeSunburst(true)
        }
        else if(chart === "Bar chart2" || chart === "Line chart2"){
          //line&bar has 1 x
          newItemsXLb = curr_selectedX.toSpliced(1)
          newItemsXPie = curr_selectedX.toSpliced(1)
          newItemsAPie = all_attributes.filter(({content})=> !newItemsXPie.some(item => item.content === content) && !newItemsYPie.some(item=> item.content === content))
          //attribute of line&bar and scatter not in x (y at current chart always has only 1)
          newItemsALb = all_attributes.filter(({content})=> !newItemsXLb.some(item => item.content === content))
          newItemsAScatter = all_attributes.filter(({content})=> !newItemsXScatter.some(item => item.content === content))
          setHasFilterChangeLb(true)
          setHasFilterChangeScatterTreemap(true)
          setHasFilterChangePie(true)
          setHasFilterChangeSunburst(true)
        }
        else if(chart === "Pie chart"){
          //attribute of line&bar and scatter not in x (y at current chart always has only 1)
          newItemsALb = all_attributes.filter(({content})=> !newItemsXLb.some(item => item.content === content))
          newItemsAScatter = all_attributes.filter(({content})=> !newItemsXScatter.some(item => item.content === content))
          setHasFilterChangeLb(true)
          setHasFilterChangeScatterTreemap(true)
          setHasFilterChangeBar(true)
          setHasFilterChangeSunburst(true)
        }
        else if(chart === "Scatter chart" || chart === "Treemap chart"){
          //line&bar has 1 x
          newItemsXLb = curr_selectedX.toSpliced(1)
          //find the rest of x if x more than 1 to push x back to attribute of line&bar
          const tempX = curr_selectedX.filter(({content}) => !newItemsXLb.some(item=> item.content === content))
          newItemsALb = [...curr_selectedA, ...tempX]
          //if current x more than 2 get only first two and set to pie&bar2 x, pie&bar2 has only 1 y, attribute not in x & y as it was
          if(curr_selectedX.length > 2)
            newItemsXBar = curr_selectedX.toSpliced(2)
          newItemsYBar = curr_selectedY.toSpliced(1)
          newItemsYPie = curr_selectedY.toSpliced(1)
          newItemsXPie = curr_selectedX.toSpliced(1)
          newItemsAPie = all_attributes.filter(({content})=> !newItemsYPie.some(item => item.content === content) && !newItemsXPie.some(item => item.content === content))
          newItemsABar = all_attributes.filter(({content})=> !newItemsYBar.some(item => item.content === content) && !newItemsXBar.some(item => item.content === content))
          //sunburst
          newItemsYSunburst = curr_selectedY.toSpliced(1)
          newItemsASunburst = all_attributes.filter(({content})=> !newItemsYSunburst.some(item => item.content === content) && !newItemsXSunburst.some(item => item.content === content))
          setHasFilterChangeLb(true)
          setHasFilterChangePie(true)
          setHasFilterChangeBar(true)
          setHasFilterChangeSunburst(true)
        }
        else if(chart === "Sunburst chart"){
          //line&bar has 1 x
          newItemsXLb = curr_selectedX.toSpliced(1)
          newItemsXPie = curr_selectedX.toSpliced(1)
          newItemsAPie = all_attributes.filter(({content})=> !newItemsXPie.some(item => item.content === content) && !newItemsYPie.some(item=> item.content === content))
          //attribute of line&bar and scatter not in x (y at current chart always has only 1)
          newItemsALb = all_attributes.filter(({content})=> !newItemsXLb.some(item => item.content === content))
          newItemsAScatter = all_attributes.filter(({content})=> !newItemsXScatter.some(item => item.content === content))
         //bar chart2
          if(curr_selectedX.length > 2)
            newItemsXBar = curr_selectedX.toSpliced(2)
          newItemsABar = all_attributes.filter(({content})=> !newItemsYBar.some(item => item.content === content) && !newItemsXBar.some(item => item.content === content))
     
          setHasFilterChangeLb(true)
          setHasFilterChangeScatterTreemap(true)
          setHasFilterChangePie(true)
          setHasFilterChangeBar(true)
        }
        setLbState(
          {
            // itemsA: _.sortBy(currentState.itemsA, ["type", "content"]),
            itemsA: newItemsALb,
            selectedX: newItemsXLb,
            selectedY: newItemsYLb,
          }
        )
        _setSelectedLbState_(
          {
            // itemsA: _.sortBy(currentState.itemsA, ["type", "content"]),
            itemsA: newItemsALb,
            selectedX: newItemsXLb,
            selectedY: newItemsYLb,
          }
        )
        _setSelectedLbStateFromShare_(
          {
            // itemsA: _.sortBy(currentState.itemsA, ["type", "content"]),
            itemsA: newItemsALb,
            selectedX: newItemsXLb,
            selectedY: newItemsYLb,
          }
        )
        setPieState({
          itemsA: newItemsAPie,
          selectedX: newItemsXPie,
          selectedY: newItemsYPie
        })
        _setSelectedPieState_({
          itemsA: newItemsAPie,
          selectedX: newItemsXPie,
          selectedY: newItemsYPie
        })
        _setSelectedPieStateFromShare_({
          itemsA: newItemsAPie,
          selectedX: newItemsXPie,
          selectedY: newItemsYPie
        })
        setBarState({
          itemsA: newItemsABar,
          selectedX: newItemsXBar,
          selectedY: newItemsYBar
        })
        _setSelectedBarState_({
          itemsA: newItemsABar,
          selectedX: newItemsXBar,
          selectedY: newItemsYBar
        })
        _setSelectedBarStateFromShare_({
          itemsA: newItemsABar,
          selectedX: newItemsXBar,
          selectedY: newItemsYBar
        })
        setSunburstState({
          itemsA: newItemsASunburst,
          selectedX: newItemsXSunburst,
          selectedY: newItemsYSunburst
        })
        _setSelectedSunburstState_({
          itemsA: newItemsASunburst,
          selectedX: newItemsXSunburst,
          selectedY: newItemsYSunburst
        })
        _setSelectedSunburstStateFromShare_({
          itemsA: newItemsASunburst,
          selectedX: newItemsXSunburst,
          selectedY: newItemsYSunburst
        })
        setScatterTreemapState({
          itemsA: newItemsAScatter,
          selectedX: newItemsXScatter,
          selectedY: newItemsYScatter
        })
        _setSelectedScatterTreemapState_({
          itemsA: newItemsAScatter,
          selectedX: newItemsXScatter,
          selectedY: newItemsYScatter
        })
        _setSelectedScatterTreemapStateFromShare_({
          itemsA: newItemsAScatter,
          selectedX: newItemsXScatter,
          selectedY: newItemsYScatter
        })
      }
      else if(chart === "Scorecard"){
        setScorecardState(
          {
            itemsA: currentAStatic,
            selectedX: currentStateX,
            selectedY: currentStateY,
          }
        )
        _setSelectedScorecardState_(
          {
            itemsA: currentAStatic,
            selectedX: currentStateX,
            selectedY: currentStateY,
          }
        )
        _setSelectedScorecardStateFromShare_(
          {
            itemsA: currentAStatic,
            selectedX: currentStateX,
            selectedY: currentStateY,
          }
        )
      }
      else if(chart === "Heatmap"){
        setHeatmapState(
          {
            itemsA: _.sortBy(currentState.itemsA, ["type", "content"]),
            selectedX: currentStateX,
            selectedY: currentStateY,
          }
        )
        _setSelectedHeatmapState_(
          {
            itemsA: _.sortBy(currentState.itemsA, ["type", "content"]),
            selectedX: currentStateX,
            selectedY: currentStateY,
          }
        )
        _setSelectedHeatmapStateFromShare_(
          {
            itemsA: _.sortBy(currentState.itemsA, ["type", "content"]),
            selectedX: currentStateX,
            selectedY: currentStateY,
          }
        )
      }
      setCurrentState(
        {
          itemsA: (chart !== "Pie chart" && chart !== "Heatmap" && chart !== "Bar chart2" && chart !== "Line chart2" && chart !== "Sunburst chart") ? currentAStatic : _.sortBy(currentState.itemsA, ["type", "content"]),
          selectedX: currentStateX,
          selectedY: currentStateY,
          update: true
        }
      )
    };

    const onChangeChart = (ch) => {
      if (ch !== _chart_) {
        _setChart_(ch);
      }
      setChart(ch);

      setShouldRender(false)
      data.isUpdated = false
      if((ch === "Line chart" || ch === "Bar chart")){
        setShouldRender(true)
        //for first render this chart
        if(lbState.selectedY.length > 0 && lbState.selectedX.length > 0 && _lbData_.value.length === 0 && !_lbData_.isUpdated){
          setCurrentState({...lbState, update: false})//when chart change will effect chartFilter then updateFilter will set update to true
          setIsFilterUpdate(true)
        }
        //when filter has update must call api to update data
        else if(hasFilterChangeLb && _lbData_.isUpdated){
          setCurrentState({...lbState, update: true})
        }
        //nothing change then use stored data
        else{
          setCurrentState({...lbState, update: false})
          setData({..._lbData_, isUpdated: true})
          setIsFilterUpdate(false)
        }
      }
      else if(ch === "Pie chart"){
        setShouldRender(true)
        if(pieState.selectedY.length > 0 && pieState.selectedX.length > 0 && _pieData_.value.length === 0 && !_pieData_.isUpdated){
          setCurrentState({...pieState, update: false})
          setIsFilterUpdate(true)
        }
        else if(hasFilterChangePie && _pieData_.isUpdated){
          setCurrentState({...pieState, update: true})
        }
        else{
          setCurrentState({...pieState, update: false})
          setData({..._pieData_, isUpdated: true})
          setIsFilterUpdate(false)
        }
      }
      else if(ch === "Bar chart2" || ch === "Line chart2"){
        setShouldRender(true)
        if(barState.selectedY.length > 0 && barState.selectedX.length > 0 && _barData_.value.length === 0 && !_barData_.isUpdated){
          setCurrentState({...barState, update: false})
          setIsFilterUpdate(true)
        }
        else if(hasFilterChangeBar && _barData_.isUpdated){
          setCurrentState({...barState, update: true})
        }
        else{
          setCurrentState({...barState, update: false})
          setData({..._barData_, isUpdated: true})
          setIsFilterUpdate(false)
        }
      }
      else if(ch === "Sunburst chart"){
        setShouldRender(true)
        if(sunburstState.selectedY.length > 0 && sunburstState.selectedX.length > 0 && _sunburstData_.value.length === 0 && !_sunburstData_.isUpdated){
          setCurrentState({...sunburstState, update: false})
          setIsFilterUpdate(true)
        }
        else if(hasFilterChangeSunburst && _sunburstData_.isUpdated){
          setCurrentState({...sunburstState, update: true})
        }
        else{
          setCurrentState({...sunburstState, update: false})
          setData({..._sunburstData_, isUpdated: true})
          setIsFilterUpdate(false)
        }
      }
      else if(ch === "Scatter chart" || ch === "Treemap chart"){
        setShouldRender(true)
        if(scatterTreemapState.selectedY.length > 0 && scatterTreemapState.selectedX.length > 0 && _scatterTreemapData_.value.length === 0 && !_scatterTreemapData_.isUpdated){
          setCurrentState({...scatterTreemapState, update: false})
          setIsFilterUpdate(true)
        }
        else if(hasFilterChangeScatterTreemap && _scatterTreemapData_.isUpdated){
          setCurrentState({...scatterTreemapState, update: true})
        }
        else{
          setCurrentState({...scatterTreemapState, update: false})
          setData({..._scatterTreemapData_, isUpdated: true})
          setIsFilterUpdate(false)
        }
      }
      else if(ch === "Scorecard"){
        setShouldRender(true)
        if(scorecardState.selectedY.length > 0 && _scorecardData_.value.length === 0 && !_scorecardData_.isUpdated){
          setCurrentState({...scorecardState, update: false})
          setIsFilterUpdate(true)
        }
        else if(hasFilterChangeScorecard && _scorecardData_.isUpdated){
          setCurrentState({...scorecardState, update: true})
        }
        else{
          setCurrentState({...scorecardState, update: false})
          setDataScore({..._scorecardData_, isUpdated: true})
          setIsFilterUpdate(false)
        }
      }
      else if(ch === "Heatmap"){
        setShouldRender(true)
        if(_heatmapData_.value.length === 0 && !_heatmapData_.isUpdated && geoJson.length === 0){
          setCurrentState({...heatmapState, update: false})
          setIsFilterUpdate(true)
        }
        else{
          setCurrentState({...heatmapState, update: false})
          setData(_heatmapData_)
          setIsFilterUpdate(false)
        }
      }
    };
    //when start drag check to disabled X axis for multiple Y aggregate
    const onDragStart = (result) => {
      const { source, draggableId} = result;
      //for drag&drop as copy attr. check duplicate
      const checkExistedInY = currentState.selectedY.filter(({content}) => content === draggableId.split(":")[1])
      if(checkExistedInY.length > 1 && source.droppableId === "droppableY" )
        setDisableXAxis(true)
      else if(checkExistedInY.length > 0 && source.droppableId === "droppableA")
        setDisableXAxis(true)
      //xAxis max len(2) of bar chart2 couldn't drag from A and Y and drop on X, disabled x axis here cuz when X exceed max len it can't move to swap attr in x
      else if((chart === "Bar chart2" || chart === "Line chart2") && currentState.selectedX.length === 2 && (source.droppableId === "droppableA" || source.droppableId === "droppableY"))
        setDisableXAxis(true)
      else
        setDisableXAxis(false)
      //remove noFunction before drag to another axis
      if(source.droppableId === "droppableX"){
        const currXIdx = currentState.selectedX.findIndex(({content}) => content === draggableId.split(":")[1])
        if(currentState.selectedX[currXIdx].noFunction) delete currentState.selectedX[currXIdx].noFunction
      }
    }

    const onDragEnd = async (result) => {
      const { source, destination, draggableId } = result;
      if (!destination) {
        return;
      }
      if (source.droppableId === destination.droppableId) {
        const _items = reorder(
          getList(source.droppableId),
          source.index,
          destination.index
        );

        let _state = {};

        if (source.droppableId === "droppableA") {
          _state = {
            itemsA: _items,
            selectedX: currentState.selectedX,
            selectedY: currentState.selectedY,
          };
        }

        if (source.droppableId === "droppableX") {
          _state = {
            itemsA: currentState.itemsA,
            selectedX: _items,
            selectedY: currentState.selectedY,
          };
        }

        if (source.droppableId === "droppableY") {
          _state = {
            itemsA: currentState.itemsA,
            selectedX: currentState.selectedX,
            selectedY: _items,
          };
        }
        if(chart !== "Scorecard" && chart !== "Heatmap"){
          const curr_selectedA = [..._state.itemsA]
          const curr_selectedX = [..._state.selectedX]
          const curr_selectedY = [..._state.selectedY]
          const all_attributes = getItems(_schema_.value)
          let newItemsAPie = [..._state.itemsA]
          let newItemsXPie = [..._state.selectedX]
          let newItemsYPie =  [..._state.selectedY]
          let newItemsABar = [..._state.itemsA]
          let newItemsXBar = [..._state.selectedX]
          let newItemsYBar =  [..._state.selectedY]
          let newItemsALb = [..._state.itemsA]
          let newItemsXLb = [..._state.selectedX]
          let newItemsYLb = [..._state.selectedY]
          let newItemsAScatter = [..._state.itemsA]
          let newItemsXScatter = [..._state.selectedX]
          let newItemsYScatter = [..._state.selectedY]
          let newItemsASunburst = [..._state.itemsA]
          let newItemsXSunburst = [..._state.selectedX]
          let newItemsYSunburst = [..._state.selectedY]
          if((chart === "Line chart" || chart === "Bar chart")){//on current chart
            //pie&bar2 has 1 y
            newItemsYPie = curr_selectedY.toSpliced(1)
            newItemsYBar = curr_selectedY.toSpliced(1)
            //attribute of pie&bar2 not in x and y
            newItemsAPie = all_attributes.filter(({content})=> !newItemsYPie.some(item => item.content === content) && !newItemsXPie.some(item => item.content === content))
            newItemsABar = all_attributes.filter(({content})=> !newItemsYBar.some(item => item.content === content) && !newItemsXBar.some(item => item.content === content))
             //sunburst
            newItemsYSunburst = curr_selectedY.toSpliced(1)
            newItemsASunburst = all_attributes.filter(({content})=> !newItemsYSunburst.some(item => item.content === content) && !newItemsXSunburst.some(item => item.content === content))
            //axis change call api again, reorder in attribute section will not trigger api call
            if(source.droppableId !== "droppableA"){
              setHasFilterChangePie(true)
              setHasFilterChangeBar(true)
              setHasFilterChangeScatterTreemap(true)
              setHasFilterChangeSunburst(true)
            }
          }
          else if(chart === "Bar chart2" || chart === "Line chart2"){
            //line&bar has 1 x
            newItemsXLb = curr_selectedX.toSpliced(1)
            newItemsXPie = curr_selectedX.toSpliced(1)
            newItemsAPie = all_attributes.filter(({content})=> !newItemsXPie.some(item => item.content === content) && !newItemsYPie.some(item=> item.content === content))
            //attribute of line&bar and scatter not in x (y at current chart always has only 1)
            newItemsALb = all_attributes.filter(({content})=> !newItemsXLb.some(item => item.content === content))
            newItemsAScatter = all_attributes.filter(({content})=> !newItemsXScatter.some(item => item.content === content))
            //axis change call api again
            if(source.droppableId !== "droppableA"){
              setHasFilterChangeLb(true)
              setHasFilterChangeScatterTreemap(true)
              setHasFilterChangePie(true)
              setHasFilterChangeSunburst(true)
            }
          }
          else if(chart === "Pie chart"){
            //attribute of line&bar and scatter not in x (y at current chart always has only 1)
            newItemsALb = all_attributes.filter(({content})=> !newItemsXLb.some(item => item.content === content))
            newItemsAScatter = all_attributes.filter(({content})=> !newItemsXScatter.some(item => item.content === content))
            //axis change call api again
            if(source.droppableId !== "droppableA"){
              setHasFilterChangeLb(true)
              setHasFilterChangeScatterTreemap(true)
              setHasFilterChangeBar(true)
              setHasFilterChangeSunburst(true)
            }
          }
          else if(chart === "Scatter chart" || chart === "Treemap chart"){
            //line&bar has 1 x
            newItemsXLb = curr_selectedX.toSpliced(1)
            //find the rest of x if x more than 1 to push x back to attribute of line&bar
            const tempX = curr_selectedX.filter(({content}) => !newItemsXLb.some(item=> item.content === content))
            newItemsALb = [...curr_selectedA, ...tempX]
            //if current x more than 2 get only first two and set to pie&bar2 x, pie&bar2 has only 1 y, attribute not in x & y as it was
            if(curr_selectedX.length > 2)
              newItemsXBar = curr_selectedX.toSpliced(2)
            newItemsYBar = curr_selectedY.toSpliced(1)
            newItemsYPie = curr_selectedY.toSpliced(1)
            newItemsXPie = curr_selectedX.toSpliced(1)
            newItemsAPie = all_attributes.filter(({content})=> !newItemsYPie.some(item => item.content === content) && !newItemsXPie.some(item => item.content === content))
            newItemsABar = all_attributes.filter(({content})=> !newItemsYBar.some(item => item.content === content) && !newItemsXBar.some(item => item.content === content))
             //sunburst
            newItemsYSunburst = curr_selectedY.toSpliced(1)
            newItemsASunburst = all_attributes.filter(({content})=> !newItemsYSunburst.some(item => item.content === content) && !newItemsXSunburst.some(item => item.content === content))
            //axis change call api again
            if(source.droppableId !== "droppableA"){
              setHasFilterChangeBar(true)
              setHasFilterChangePie(true)
              setHasFilterChangeLb(true)
              setHasFilterChangeSunburst(true)
            }
          }
          else if(chart === "Sunburst chart"){
            //line&bar has 1 x
            newItemsXLb = curr_selectedX.toSpliced(1)
            newItemsXPie = curr_selectedX.toSpliced(1)
            newItemsAPie = all_attributes.filter(({content})=> !newItemsXPie.some(item => item.content === content) && !newItemsYPie.some(item=> item.content === content))
            //attribute of line&bar and scatter not in x (y at current chart always has only 1)
            newItemsALb = all_attributes.filter(({content})=> !newItemsXLb.some(item => item.content === content))
            newItemsAScatter = all_attributes.filter(({content})=> !newItemsXScatter.some(item => item.content === content))
           //bar chart2
           if(curr_selectedX.length > 2)
             newItemsXBar = curr_selectedX.toSpliced(2)
           newItemsABar = all_attributes.filter(({content})=> !newItemsYBar.some(item => item.content === content) && !newItemsXBar.some(item => item.content === content))
       
            setHasFilterChangeLb(true)
            setHasFilterChangeScatterTreemap(true)
            setHasFilterChangePie(true)
            setHasFilterChangeBar(true)
          }
          setLbState({
            itemsA: newItemsALb,
            selectedX: newItemsXLb,
            selectedY: newItemsYLb
          })
          _setSelectedLbState_({
            itemsA: newItemsALb,
            selectedX: newItemsXLb,
            selectedY: newItemsYLb
          })
          _setSelectedLbStateFromShare_({
            itemsA: newItemsALb,
            selectedX: newItemsXLb,
            selectedY: newItemsYLb
          })
          setPieState({
            itemsA: newItemsAPie,
            selectedX: newItemsXPie,
            selectedY: newItemsYPie
          })
          _setSelectedPieState_({
            itemsA: newItemsAPie,
            selectedX: newItemsXPie,
            selectedY: newItemsYPie
          })
          _setSelectedPieStateFromShare_({
            itemsA: newItemsAPie,
            selectedX: newItemsXPie,
            selectedY: newItemsYPie
          })
          setBarState({
            itemsA: newItemsABar,
            selectedX: newItemsXBar,
            selectedY: newItemsYBar
          })
          _setSelectedBarState_({
            itemsA: newItemsABar,
            selectedX: newItemsXBar,
            selectedY: newItemsYBar
          })
          _setSelectedBarStateFromShare_({
            itemsA: newItemsABar,
            selectedX: newItemsXBar,
            selectedY: newItemsYBar
          })
          setSunburstState({
            itemsA: newItemsASunburst,
            selectedX: newItemsXSunburst,
            selectedY: newItemsYSunburst
          })
          _setSelectedSunburstState_({
            itemsA: newItemsASunburst,
            selectedX: newItemsXSunburst,
            selectedY: newItemsYSunburst
          })
          _setSelectedSunburstStateFromShare_({
            itemsA: newItemsASunburst,
            selectedX: newItemsXSunburst,
            selectedY: newItemsYSunburst
          })
          setScatterTreemapState({
            itemsA: newItemsAScatter,
            selectedX: newItemsXScatter,
            selectedY: newItemsYScatter
          })
          _setSelectedScatterTreemapState_({
            itemsA: newItemsAScatter,
            selectedX: newItemsXScatter,
            selectedY: newItemsYScatter
          })
          _setSelectedScatterTreemapStateFromShare_({
            itemsA: newItemsAScatter,
            selectedX: newItemsXScatter,
            selectedY: newItemsYScatter
          })
        }
        else if(chart === "Scorecard"){
          setScorecardState(_state)
          _setSelectedScorecardState_(_state)
          _setSelectedScorecardStateFromShare_(_state)
        }
        else if(chart === "Heatmap"){
          setHeatmapState(_state)
          _setSelectedHeatmapState_(_state)
          _setSelectedHeatmapStateFromShare_(_state)
        }
        if(source.droppableId === "droppableA" && destination.droppableId === "droppableA")
          setCurrentState({..._state, update: false})
        else
          setCurrentState({..._state, update: true})
      } else {
        const _state = {};
        //drag attr to Y Axis as copy
        if((chart !== "Pie chart" && chart !== "Heatmap" && chart !== "Bar chart2" && chart !== "Line chart2"  && chart !== "Sunburst chart") && destination.droppableId === "droppableY"){
          let newAggNumberAvailableList = [...aggNumberAvailableList]
          let newAggStringAvailableList = [...aggStringAvailableList]
          
          if(chart !== "Scorecard"){
            newAggNumberAvailableList = [...aggNumberAvailableList, "no function"]
            newAggStringAvailableList = [...aggStringAvailableList, "no function"]
          }
          const copy_result = copy(
            getList(source.droppableId),
            getList(destination.droppableId),
            source,
            destination
          )
          const droppableResultList = Object.keys(copy_result);
          const droppableList = id2List
          for (const [key, value] of Object.entries(droppableList)) {
            if (droppableResultList.includes(key)) {
              let l = copy_result[key];
              if (key === "droppableA") {
                l.forEach((item, i)=>{
                  let currentAttrDropped = copy_result['droppableY'].filter(elm=>elm.content === item.content)
                  if((item.type === "number" || item.type === "date") && (currentAttrDropped.length === newAggNumberAvailableList.length)){
                    l.splice(i,1)
                  }
                  else if((item.type === "string") && (currentAttrDropped.length === newAggStringAvailableList.length)){
                    l.splice(i,1)
                  }
                })
                l = l.map((item) => {
                  item.agg = "";
                  if (item.type === "string" || item.type === "boolean") {
                    item.color = "#189FF0";
                  } else if (item.type === "number") {
                    item.color = "#002766";
                  }
                  return item;
                });
              }
              if (source.droppableId !== "droppableX" && key === "droppableY") {
                l = l.map((item) => {
                  //list of same attr
                  let filterCurrent = l.filter(elm=>elm.content === item.content)
                  let getAggList = filterCurrent.map((elm) => {
                      if(elm.noFunction)
                        return "no function"
                      return elm.agg
                  })
                  if (item.type === "number" || item.type === "date") {
                    let defaultAgg = newAggNumberAvailableList.filter(it=>!getAggList.includes(it));
                    //prevent the previous attr that no function selected will change
                    if(!item.noFunction && !item.agg){
                      item.agg = defaultAgg[0] === "no function" ? "" : defaultAgg[0];
                    }
                  } else if(item.type === "string") {
                    let defaultAgg = newAggStringAvailableList.filter(it=>!getAggList.includes(it))
                    if(!item.noFunction && !item.agg){
                      item.agg = defaultAgg[0] === "no function" ? "" : defaultAgg[0];
                    }
                  }
                  if(item.agg === "")
                    item.noFunction = true
                  item = setColorToItem(item);
                  return item;
                });
              }
              //drag from X axis to Y
              if (source.droppableId === "droppableX" && key === "droppableY") {
                l = l.map((item) => {
                  let filterCurrent = l.filter(elm=>elm.content === item.content)
                  let getAggList = filterCurrent.map((elm) => {
                    if(elm.noFunction)
                      return "no function"
                    return elm.agg
                  })
                  if (item.type === "number" || item.type === "date") {
                    let defaultAgg = newAggNumberAvailableList.filter(it=>!getAggList.includes(it))
                    if(!item.noFunction && !item.agg){
                      item.agg = defaultAgg[0] === "no function" ? "" : defaultAgg[0];
                    }
                  } else if(item.type === "string") {
                    let defaultAgg = newAggStringAvailableList.filter(it=>!getAggList.includes(it))
                    if(!item.noFunction && !item.agg){
                      item.agg = defaultAgg[0] === "no function" ? "" : defaultAgg[0];
                    }
                  }
                  if(item.agg === "")
                    item.noFunction = true
                  item = setColorToItem(item);
                  return item;
                });
                //remove this item from X and push in to A
                const xId = _state.selectedX.findIndex(({content}) => content === draggableId.split(":")[1])
                if(xId !== -1)
                  _state.selectedX.splice(xId,1)
                const currentAttr = [...getItems(_schema_.value)]
                const currItem = currentAttr.find(({content}) =>  content === draggableId.split(":")[1])
                _state.itemsA.push(currItem)
              }
              _state[value] = l;
            }
            else {
              _state[value] = currentState[value];
            }
          }

        }
        else{//move event for any axis without duplicate attr. content on Y axis
          const result = move(
            getList(source.droppableId),
            getList(destination.droppableId),
            source,
            destination
          );
          const droppableResultList = Object.keys(result);
          const droppableList = id2List;
          for (const [key, value] of Object.entries(droppableList)) {
            if (droppableResultList.includes(key)) {
              let l = result[key];
              //handle Attr. items in case move from Y axis to Attr. for multiple agg. of y attr.
              if(chart !== "Pie chart" && chart !== "Heatmap" && chart !== "Bar chart2" && chart !== "Line chart2" && chart !== "Sunburst chart" && source.droppableId === "droppableY" && (key === "droppableA" || key === "droppableY" || key === "droppableX")){
                if (key === "droppableA") {
                  let newAggNumberAvailableList = [...aggNumberAvailableList]
                  let newAggStringAvailableList = [...aggStringAvailableList]
                  
                  if(chart !== "Scorecard"){
                    newAggNumberAvailableList = [...aggNumberAvailableList, "no function"]
                    newAggStringAvailableList = [...aggStringAvailableList, "no function"]
                  }
                  const checkAttrA = l.findIndex(({content}) => content === draggableId.split(":")[1])
                  let currentAttrYDropped = result['droppableY'].filter(elm=>elm.content === draggableId.split(":")[1])
                  //list attr on y and list agg when move back it not equal then check in A if found then splice
                  const aCurrIdx = result['droppableA'].findIndex(elm=> elm.content === l[checkAttrA].content && elm.id !== l[checkAttrA].id)
                  if((l[checkAttrA].type === "number" || l[checkAttrA].type === "date") && (currentAttrYDropped.length !== newAggNumberAvailableList.length)){
                    if(aCurrIdx !== -1){
                      l.splice(aCurrIdx, 1)
                    }
                  }
                  else if((l[checkAttrA].type === "string") && (currentAttrYDropped.length !== newAggStringAvailableList.length)){
                    if(aCurrIdx !== -1){
                      l.splice(aCurrIdx, 1)
                    }
                  }

                  l = l.map((item) => {
                    item.agg = "";
                    if(item.noFunction) delete item.noFunction
                    if (item.type === "string" || item.type === "boolean") {
                      item.color = "#189FF0";
                    } else if (item.type === "number") {
                      item.color = "#002766";
                    }
                    return item;
                  });
                }
                else if(key === "droppableX" && destination.droppableId === "droppableX"){
                  //drag from Y to X, remove from Attr.
                  const attrIdx = _state.itemsA.findIndex(({content}) => content === draggableId.split(":")[1])
                  if(attrIdx !== -1)
                    _state.itemsA.splice(attrIdx, 1)
                }
              }
              else if (key === "droppableA") {
                l = l.map((item) => {
                  item.agg = "";
                  if (item.type === "string" || item.type === "boolean") {
                    item.color = "#189FF0";
                  } else if (item.type === "number") {
                    item.color = "#002766";
                  }
                  return item;
                });
              }
              else if (key === "droppableY") {
                l = l.map((item) => {
                  //get selected attr by chart to get all selected and match with current item to prevent the previous attr. agg reset
                    let selectedAttr
                    if(chart === "Line chart" || chart === "Bar chart")
                      selectedAttr = lbState.selectedY.find(x=> x.content === item.content)
                    // else if(chart === "Bar chart2")//only 1 y on axis
                    // else if(chart === "Pie chart")
                    // else if(chart === "Heatmap")
                    else if(chart === "Scatter chart" || chart === "Treemap chart")
                      selectedAttr = scatterTreemapState.selectedY.find(x=> x.content === item.content)
                    // else if(chart === "Treemap chart")
                    //   selectedAttr = treemapState.selectedY.find(x=> x.content === item.content)

                  if (item.type === "number") {
                    if(selectedAttr)
                      item.agg = selectedAttr.agg
                    else
                      item.agg = "AVG";
                  } else {
                    if(selectedAttr)
                      item.agg = selectedAttr.agg
                    else
                      item.agg = "COUNT";
                    // item.agg = "COUNT_D";
                  }
                  item = setColorToItem(item);
                  return item;
                });
              }
              _state[value] = l;
            } else {
              _state[value] = currentState[value];
            }
          }
        }
        if(chart !== "Scorecard" && chart !== "Heatmap"){
          const curr_selectedA = [..._state.itemsA]
          const curr_selectedX = [..._state.selectedX]
          const curr_selectedY = [..._state.selectedY]
          const all_attributes = getItems(_schema_.value)
          let newItemsAPie = [..._state.itemsA]
          let newItemsXPie = [..._state.selectedX]
          let newItemsYPie =  [..._state.selectedY]
          let newItemsABar = [..._state.itemsA]
          let newItemsXBar = [..._state.selectedX]
          let newItemsYBar =  [..._state.selectedY]
          let newItemsALb = [..._state.itemsA]
          let newItemsXLb = [..._state.selectedX]
          let newItemsYLb = [..._state.selectedY]
          let newItemsAScatter = [..._state.itemsA]
          let newItemsXScatter = [..._state.selectedX]
          let newItemsYScatter = [..._state.selectedY]
          let newItemsASunburst = [..._state.itemsA]
          let newItemsXSunburst = [..._state.selectedX]
          let newItemsYSunburst = [..._state.selectedY]
          if((chart === "Line chart" || chart === "Bar chart")){//on current chart
            //pie&bar2 has 1 y
            newItemsYPie = curr_selectedY.toSpliced(1)
            newItemsYBar = curr_selectedY.toSpliced(1)
            //attribute of pie&bar2 not in x and y
            newItemsAPie = all_attributes.filter(({content})=> !newItemsYPie.some(item => item.content === content) && !newItemsXPie.some(item => item.content === content))
            newItemsABar = all_attributes.filter(({content})=> !newItemsYBar.some(item => item.content === content) && !newItemsXBar.some(item => item.content === content))
            //sunburst
            newItemsYSunburst = curr_selectedY.toSpliced(1)
            newItemsASunburst = all_attributes.filter(({content})=> !newItemsYSunburst.some(item => item.content === content) && !newItemsXSunburst.some(item => item.content === content))

            //axis change call api again
            setHasFilterChangePie(true)
            setHasFilterChangeBar(true)
            setHasFilterChangeScatterTreemap(true)
            setHasFilterChangeSunburst(true)
          }
          else if(chart === "Bar chart2" || chart === "Line chart2"){
            //line&bar has 1 x
            newItemsXLb = curr_selectedX.toSpliced(1)
            newItemsXPie = curr_selectedX.toSpliced(1)
            newItemsAPie = all_attributes.filter(({content})=> !newItemsXPie.some(item => item.content === content) && !newItemsYPie.some(item=> item.content === content))
            //attribute of line&bar and scatter not in x (y at current chart always has only 1)
            newItemsALb = all_attributes.filter(({content})=> !newItemsXLb.some(item => item.content === content))
            newItemsAScatter = all_attributes.filter(({content})=> !newItemsXScatter.some(item => item.content === content))
            setHasFilterChangeLb(true)
            setHasFilterChangeScatterTreemap(true)
            setHasFilterChangePie(true)
            setHasFilterChangeSunburst(true)
          }
          else if(chart === "Pie chart"){
            //attribute of line&bar and scatter not in x (y at current chart always has only 1)
            newItemsALb = all_attributes.filter(({content})=> !newItemsXLb.some(item => item.content === content))
            newItemsAScatter = all_attributes.filter(({content})=> !newItemsXScatter.some(item => item.content === content))
            setHasFilterChangeLb(true)
            setHasFilterChangeScatterTreemap(true)
            setHasFilterChangeBar(true)
            setHasFilterChangeSunburst(true)
          }
          else if(chart === "Scatter chart" || chart === "Treemap chart"){
            //line&bar has 1 x
            newItemsXLb = curr_selectedX.toSpliced(1)
            //find the rest of x if x more than 1 to push x back to attribute of line&bar
            const tempX = curr_selectedX.filter(({content}) => !newItemsXLb.some(item=> item.content === content))
            newItemsALb = [...curr_selectedA, ...tempX]
            //if current x more than 2 get only first two and set to pie&bar2 x, pie&bar2 has only 1 y, attribute not in x & y as it was
            if(curr_selectedX.length > 2)
              newItemsXBar = curr_selectedX.toSpliced(2)
            newItemsYPie = curr_selectedY.toSpliced(1)
            newItemsYBar = curr_selectedY.toSpliced(1)
            newItemsXPie = curr_selectedX.toSpliced(1)
            newItemsAPie = all_attributes.filter(({content})=> !newItemsYPie.some(item => item.content === content) && !newItemsXPie.some(item => item.content === content))
            newItemsABar = all_attributes.filter(({content})=> !newItemsYBar.some(item => item.content === content) && !newItemsXBar.some(item => item.content === content))
            //sunburst
            newItemsYSunburst = curr_selectedY.toSpliced(1)
            newItemsASunburst = all_attributes.filter(({content})=> !newItemsYSunburst.some(item => item.content === content) && !newItemsXSunburst.some(item => item.content === content))
            setHasFilterChangeLb(true)
            setHasFilterChangePie(true)
            setHasFilterChangeBar(true)
            setHasFilterChangeSunburst(true)
          }
          else if(chart === "Sunburst chart"){
             //line&bar has 1 x
             newItemsXLb = curr_selectedX.toSpliced(1)
             newItemsXPie = curr_selectedX.toSpliced(1)
             newItemsAPie = all_attributes.filter(({content})=> !newItemsXPie.some(item => item.content === content) && !newItemsYPie.some(item=> item.content === content))
             //attribute of line&bar and scatter not in x (y at current chart always has only 1)
             newItemsALb = all_attributes.filter(({content})=> !newItemsXLb.some(item => item.content === content))
             newItemsAScatter = all_attributes.filter(({content})=> !newItemsXScatter.some(item => item.content === content))
            //bar chart2
            if(curr_selectedX.length > 2)
              newItemsXBar = curr_selectedX.toSpliced(2)
            newItemsABar = all_attributes.filter(({content})=> !newItemsYBar.some(item => item.content === content) && !newItemsXBar.some(item => item.content === content))

             setHasFilterChangeLb(true)
             setHasFilterChangeScatterTreemap(true)
             setHasFilterChangePie(true)
             setHasFilterChangeBar(true)
          }
          setLbState({
            itemsA: newItemsALb,
            selectedX: newItemsXLb,
            selectedY: newItemsYLb
          })
          _setSelectedLbState_({
            itemsA: newItemsALb,
            selectedX: newItemsXLb,
            selectedY: newItemsYLb
          })
          _setSelectedLbStateFromShare_({
            itemsA: newItemsALb,
            selectedX: newItemsXLb,
            selectedY: newItemsYLb
          })
          setPieState({
            itemsA: newItemsAPie,
            selectedX: newItemsXPie,
            selectedY: newItemsYPie
          })
          _setSelectedPieState_({
            itemsA: newItemsAPie,
            selectedX: newItemsXPie,
            selectedY: newItemsYPie
          })
          _setSelectedPieStateFromShare_({
            itemsA: newItemsAPie,
            selectedX: newItemsXPie,
            selectedY: newItemsYPie
          })
          setBarState({
            itemsA: newItemsABar,
            selectedX: newItemsXBar,
            selectedY: newItemsYBar
          })
          _setSelectedBarState_({
            itemsA: newItemsABar,
            selectedX: newItemsXBar,
            selectedY: newItemsYBar
          })
          _setSelectedBarStateFromShare_({
            itemsA: newItemsABar,
            selectedX: newItemsXBar,
            selectedY: newItemsYBar
          })
          setSunburstState({
            itemsA: newItemsASunburst,
            selectedX: newItemsXSunburst,
            selectedY: newItemsYSunburst
          })
          _setSelectedSunburstState_({
            itemsA: newItemsASunburst,
            selectedX: newItemsXSunburst,
            selectedY: newItemsYSunburst
          })
          _setSelectedSunburstStateFromShare_({
            itemsA: newItemsASunburst,
            selectedX: newItemsXSunburst,
            selectedY: newItemsYSunburst
          })
          setScatterTreemapState({
            itemsA: newItemsAScatter,
            selectedX: newItemsXScatter,
            selectedY: newItemsYScatter
          })
          _setSelectedScatterTreemapState_({
            itemsA: newItemsAScatter,
            selectedX: newItemsXScatter,
            selectedY: newItemsYScatter
          })
          _setSelectedScatterTreemapStateFromShare_({
            itemsA: newItemsAScatter,
            selectedX: newItemsXScatter,
            selectedY: newItemsYScatter
          })
        }
        else if(chart === "Heatmap"){
          setHeatmapState(_state)
          _setSelectedHeatmapState_(_state)
          _setSelectedHeatmapStateFromShare_(_state)
        }
        else if(chart === "Scorecard"){
          setScorecardState(_state)
          _setSelectedScorecardState_(_state)
          _setSelectedScorecardStateFromShare_(_state)
        }
        setCurrentState({..._state, update: true})
      }
    };

    const handleInterval = (val) => {
      setInterval({ interval: val, isUpdated: true });
    };
    const updateFormSubmit = (formValue) => {
      setMapFieldStatus(true)
      if(formValue){
      //set key newContent
        currentState.itemsA.forEach((item)=>{
          item.newContent = null
        })
        newMapSchema.value.forEach(elm =>elm.newContent = null)
        Object.entries(formValue).forEach((item)=>{
          if(item[1] !== undefined){
            let idxItemA = currentState.itemsA.findIndex(({content}) => content === item[1])
            if(idxItemA !== -1){
              currentState.itemsA[idxItemA]["newContent"] = item[0]
            }
            let idxSchema = newMapSchema.value.findIndex(({content}) => content === item[1])
            if(idxSchema !== -1){
              newMapSchema.value[idxSchema]["newContent"] = item[0]
            }
          }
        })
        //set from from to newMapSchema with item match and not match set newContent to undef
        setNewMapSchema({update: true, value: newMapSchema.value})
      }

      setCurrentState({
        itemsA: currentState.itemsA,
        selectedX: currentState.selectedX,
        selectedY: currentState.selectedY,
        update: true
      })
      setHeatmapState({
        itemsA: currentState.itemsA,
        selectedX: currentState.selectedX,
        selectedY: currentState.selectedY,
      })
      _setSelectedHeatmapState_({
        itemsA: currentState.itemsA,
        selectedX: currentState.selectedX,
        selectedY: currentState.selectedY,
      })
      _setSelectedHeatmapStateFromShare_({
        itemsA: currentState.itemsA,
        selectedX: currentState.selectedX,
        selectedY: currentState.selectedY,
      })
    }
    const updateHeatmapGeo = (propertiesObj, level, mode) =>{
      //remove name from map field schema to prevent wrong filter
      if(level === "region" || level === "country"){
        newMapSchema.value.forEach((item)=>{
          if(item.newContent?.toLowerCase() === "province"){
            item["province"] = null
          }
          else if(item.newContent?.toLowerCase() === "district"){
            item["district"] = null
          }
          else if(item.newContent?.toLowerCase() === "sub_district"){
            item["sub_district"] = null
          }
          else if(item.newContent?.toLowerCase() === "region"){
            item["region"] = null
          }
        })
      }
      else if(level === "region_province"){//left only region name
        newMapSchema.value.forEach((item)=>{
          if(item.newContent?.toLowerCase() === "province"){
            item["province"] = null
          }
          else if(item.newContent?.toLowerCase() === "district"){
            item["district"] = null
          }
          else if(item.newContent?.toLowerCase() === "sub_district"){
            item["sub_district"] = null
          }
        })
      }
      else if(level === "province"){//left only province name
        newMapSchema.value.forEach((item)=>{
          if(item.newContent?.toLowerCase() === "district"){
            item["district"] = null
          }
          else if(item.newContent?.toLowerCase() === "sub_district"){
            item["sub_district"] = null
          }
        })
      }
      else if(level === "amphoe"){//left only amphoe name
        newMapSchema.value.forEach((item)=>{
          if(item.newContent?.toLowerCase() === "sub_district"){
            item["sub_district"] = null
          }
        })
      }
      setMapLevel({
        mode: mode,
        level: level,
        properties: propertiesObj
      })
      _setMapLevel_({
        mode: mode,
        level: level,
        properties: propertiesObj
      })
      setCurrentState({
        itemsA: currentState.itemsA,
        selectedX: currentState.selectedX,
        selectedY: currentState.selectedY,
        update: true
      })
    }
    const updateFilter = (fts,mode) => {
      setFilters(fts);
      setCurrentState({
        itemsA: currentState.itemsA,
        selectedX: currentState.selectedX,
        selectedY: currentState.selectedY,
        update: true
      })
      setIsFilterUpdate(false)
      //when filter change then all chart which shared filter should update data by call api again
      if((chart === "Line chart" || chart === "Bar chart") && mode === "onchange"){
        setHasFilterChangePie(true)
        setHasFilterChangeBar(true)
        setHasFilterChangeScatterTreemap(true)
        setHasFilterChangeScorecard(true)
        setHasFilterChangeSunburst(true)
      }
      else if((chart === "Pie chart") && mode === "onchange"){
        setHasFilterChangeLb(true)
        setHasFilterChangeScatterTreemap(true)
        setHasFilterChangeScorecard(true)
        setHasFilterChangeBar(true)
        setHasFilterChangeSunburst(true)
      }
      else if((chart === "Bar chart2" || chart === "Line chart2") && mode === "onchange"){
        setHasFilterChangeLb(true)
        setHasFilterChangeScatterTreemap(true)
        setHasFilterChangeScorecard(true)
        setHasFilterChangePie(true)
        setHasFilterChangeSunburst(true)
      }
      else if((chart === "Scatter chart" || chart === "Treemap chart") && mode === "onchange"){
        setHasFilterChangeLb(true)
        setHasFilterChangePie(true)
        setHasFilterChangeBar(true)
        setHasFilterChangeScorecard(true)
        setHasFilterChangeSunburst(true)
      }
      else if((chart === "Sunburst chart") && mode === "onchange"){
        console.log("sunn");
        setHasFilterChangeLb(true)
        setHasFilterChangePie(true)
        setHasFilterChangeBar(true)
        setHasFilterChangeScorecard(true)
        setHasFilterChangeScatterTreemap(true)
      }
      // else if(chart === "Treemap chart" && mode === "onchange"){
      //   setHasFilterChangeLb(true)
      //   setHasFilterChangePieBar(true)
      //   setHasFilterChangeScatter(true)
      //   setHasFilterChangeScorecard(true)
      // }
      else if(chart === "Scorecard" && mode === "onchange"){
        setHasFilterChangeLb(true)
        setHasFilterChangePie(true)
        setHasFilterChangeBar(true)
        setHasFilterChangeScatterTreemap(true)
        setHasFilterChangeSunburst(true)
      }
    };
    const updateHeatmapFilter = (fts,ftLst) => {
      setHeatmapFiltersObj(ftLst)
      setHeatmapFilters(fts);
      setCurrentState({
        itemsA: currentState.itemsA,
        selectedX: currentState.selectedX,
        selectedY: currentState.selectedY,
        update: true
      })
      setIsFilterUpdate(false)
    };
    return (
      <>
        <div className={((_sharedTitle_ === "" && !_showEdit_ && !_showBack_) || isFullscreen) ? "fullScreenLayout" : "ChartLayout"}>
          {currentState.itemsA.length !== 0 ? (
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
              <Row
                align="middle"
                className="chart-layout-rowcol-droppable"
                style={_embed_ && !_chartInfoEmbed_ && { display: "none" }}
              >
                <Col span={2}>
                  {chart === "Scorecard" ? <label className="disabled-text">แกน X</label>:<label>แกน X</label>}
                </Col>
                <Col span={22}>
                  {
                    <Droppable droppableId="droppableX" direction="horizontal" isDropDisabled={checkDropableX() || disableXAxis}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyleH(snapshot.isDraggingOver)}
                      >
                        {
                          currentState.selectedX.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                              isDragDisabled={_embed_}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyleH(
                                    provided.draggableProps.style,
                                    item.color
                                  )}
                                >
                                  <ChartChip
                                    embed={_embed_}
                                    item={item}
                                    updateState={updateState}
                                    handleInterval={handleInterval}
                                    type="horizontal"
                                    axisOption="xAxis"
                                    hasCloseBtn={true}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))
                        }
                        {
                          chart === "Heatmap" &&
                          <>
                            <MapFieldModal updateFormSubmit={updateFormSubmit} mapFieldStatus={mapFieldStatus}/>
                            {selectedXHeatmap.map((item, index)=>(
                              <div
                                key={item.id}
                                style={{
                                  width: 134.58,
                                  borderRadius: 7,
                                  height: 30,
                                  background: item.color,
                                  color: "#fff",
                                  userSelect: "none",
                                  padding: 3,
                                  margin: `0 3px 0 3px`
                                }}
                              >
                                <ChartChip
                                  embed={_embed_}
                                  item={item}
                                  type="horizontal"
                                  axisOption="xAxis"
                                  chart={chart}
                                  readonly
                                />
                              </div>
                            ))}
                          </>
                        }
                        {provided.placeholder}
                      </div>
                    )}
                    </Droppable>
                  }
                </Col>
              </Row>
              <Row
                align="middle"
                className="chart-layout-rowcol-droppable"
                style={_embed_ && !_chartInfoEmbed_ && { display: "none" }}
              >
                <Col span={2}>
                  <label>แกน Y</label>
                </Col>
                <Col span={22}>
                  <Droppable droppableId="droppableY" direction="horizontal" isDropDisabled={checkDropableY()}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyleH(snapshot.isDraggingOver)}
                      >
                        {
                            currentState.selectedY.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                                isDragDisabled={_embed_}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyleH(
                                      provided.draggableProps.style,
                                      item.color
                                    )}
                                  >
                                      <ChartChip
                                        embed={_embed_}
                                        item={item}
                                        updateState={updateState}
                                        handleInterval={handleInterval}
                                        type="horizontal"
                                        axisOption="yAxis"
                                        hasCloseBtn={true}
                                        handleAggOption={chart !== "Pie chart" && chart !== "Heatmap" && chart !== "Bar chart2" && chart !== "Line chart2" && chart !== "Sunburst chart" ? true : false}
                                        allItemList={currentState.selectedY}
                                        chart={chart}
                                        hasNoFunctionAgg={chart !== "Scorecard" ? true : false}
                                      />
                                  </div>
                                )}
                              </Draggable>
                            ))
                        }
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Col>
              </Row>
              {/* <Row
                align="middle"
                className="chart-layout-rowcol-droppable"
                style={_embed_ && !_chartInfoEmbed_ && { display: "none" }}
              >
                <Col span={2}>
                  <label>Lat</label>
                </Col>
                <Col span={22}>
                  <Droppable
                    droppableId="droppableY"
                    direction="horizontal"
                    isDropDisabled={checkDropableY()}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyleH(snapshot.isDraggingOver)}
                      >
                        {state.selectedY.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                            isDragDisabled={_embed_}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyleH(
                                  provided.draggableProps.style,
                                  item.color
                                )}
                              >
                                <ChartChip
                                  embed={_embed_}
                                  item={item}
                                  updateState={updateState}
                                  aggFunction={aggFunction}
                                  handleAggChange={handleAggChange}
                                  handleInterval={handleInterval}
                                  type="horizontal"
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Col>
              </Row> */}
              <Row align="top" className={_embed_ && !_chartInfoEmbed_ ? (_sharedTitle_ === "" && !_showEdit_ && !_showBack_) ? 'full-screen-layout' : 'chart-layout' : 'chart-layout-items-droppable'}>
                <Col
                  span={spanStyleAttributes()}
                  style={
                    _embed_
                      ? {
                          display: "none",
                          border: "1px solid rgb(238, 238, 238)",
                        }
                      : { border: "1px solid rgb(238, 238, 238)" }
                  }
                >
                  <Droppable droppableId="droppableA">
                    {(provided, snapshot) => (
                      <>
                        <p className="chart-layout-list-header">Attributes</p>
                        <div
                          ref={provided.innerRef}
                          style={getListStyleV}
                          className="chart-layout-list-container"
                        >
                          {
                            currentState.itemsA.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyleV(
                                      provided.draggableProps.style,
                                      item.color
                                    )}
                                  >
                                    <ChartChip
                                      embed={_embed_}
                                      item={item}
                                      updateState={updateState}
                                      type="vertical"
                                      chart={chart}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))
                          }
                          {provided.placeholder}
                        </div>
                      </>
                    )}
                  </Droppable>
                </Col>
                <Col span={spanStyleChart()} style={{height: '100%'}}>
                  <Row
                    style={_embed_ && !_chartInfoEmbed_ && { display: "none" }}
                  >
                    <Col
                      span={12}
                      style={{
                        marginTop: 10,
                        paddingLeft: 10,
                        textAlign: "start",
                      }}
                    >
                      <ChartFilter updateFilter={chart !== "Heatmap" ? updateFilter : updateHeatmapFilter} chart={chart} updateMapField={chart === "Heatmap" ? newMapSchema : null} isFilterUpdate={isFilterUpdate}/>
                    </Col>
                    <Col
                      span={12}
                      style={{
                        marginTop: 10,
                        paddingLeft: 10,
                        textAlign: "start",
                      }}
                    >
                      <ChartButtonMenu onChangeChart={onChangeChart} isDisabled={chartLoading}/>
                    </Col>
                  </Row>
                  <Row style={{height: '100%'}}>
                    <Col
                      span={24}
                      style={{
                        paddingTop: 10,
                        paddingLeft: 10,
                        paddingBottom: 10,
                        position: 'relative'
                      }}
                      className={`chart-container-layout ${_embed_ && !_chartInfoEmbed_ ? 'chart-border-top' : 'chart-border'}`}
                    >
                      {
                        chartLoading ?
                        <div className="loading-wrapper">
                          <div className="bg-loading"></div>
                          <Spin size="large" className="loading-spin" />
                        </div>
                      :
                        <ChartComponent
                          data={shouldRender ? chart !== "Scorecard" ? data : dataScore : { value: ["prev"] }}
                          stateX={currentState.selectedX}
                          stateY={currentState.selectedY}
                          chart={chart}
                          geoJson={shapeRender ? geoJson : []}
                          updateHeatmapGeo={chart === "Heatmap" ? updateHeatmapGeo : null}
                        />
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </DragDropContext>
          ) : null}
        </div>
      </>
    );
  }, [
    data,
    shouldRender,
    _chart_,
    _setChart_,
    _chartInfoEmbed_,
    _embed_,
    _breakpoint_,
    chart,
    dataScore,
    _schema_,
    aggNumberAvailableList,
    aggStringAvailableList,
    _scoreCardLabel_,
    geoJson,
    shapeRender,
    newMapSchema,
    _setMapLevel_,
    mapFieldStatus,
    selectedXHeatmap,
    currentState,
    lbState,
    heatmapState,
    pieState,
    barState,
    sunburstState,
    scatterTreemapState,
    scorecardState,
    _setSelectedLbState_,
    _setSelectedPieState_,
    _setSelectedBarState_,
    _setSelectedSunburstState_,
    _setSelectedScatterTreemapState_,
    _setSelectedScorecardState_,
    _setSelectedHeatmapState_,
    _setSelectedLbStateFromShare_,
    _setSelectedPieStateFromShare_,
    _setSelectedBarStateFromShare_,
    _setSelectedSunburstStateFromShare_,
    _setSelectedScatterTreemapStateFromShare_,
    _setSelectedScorecardStateFromShare_,
    _setSelectedHeatmapStateFromShare_,
    chartLoading,
    _heatmapData_,
    _lbData_,
    _pieData_,
    _barData_,
    _sunburstData_,
    _scatterTreemapData_,
    _scorecardData_,
    isFilterUpdate,
    hasFilterChangeLb,
    hasFilterChangePie,
    hasFilterChangeBar,
    hasFilterChangeSunburst,
    hasFilterChangeScatterTreemap,
    hasFilterChangeScorecard,
    _sharedTitle_,
    _showBack_,
    _showEdit_,
    disableXAxis,
    isFullscreen
  ]);
};

export default ChartLayout;

const id2List = {
  droppableA: "itemsA",
  droppableX: "selectedX",
  droppableY: "selectedY",
};

const getData = async (url, isAgg, isGroup) => {
  return await axios
    .get(url)
    .then(async (res) => {
      let data = await res.data.data.records;
      if (isAgg && isGroup) {
        data = await res.data.data.aggregate.result.group;
      }
      if (isAgg && !isGroup) {
        data = await res.data.data.aggregate.result;
      }
      //   const numData = await res.data.data.numData.result;
      return { value: data, numData: 0, isUpdated: true };
    })
    .catch((error) => {
      if(error.response.status === 504 && error.response.data.error === "operation exceeded time limit"){
        Swal.fire({
          html: '<h3>เกิดข้อผิดพลาด!</h3><div>การประมวลผลข้อมูลนี้ใช้เวลามากกว่าที่กำหนด</div>',
          timer: 5000,
          icon: 'error',
          confirmButtonColor: '#1890ff',
          confirmButtonText: 'ปิด'
        })
      }
      console.log("error >> ", error)
    });
};

const dataMapping = (data, utfMapping) => {
  const newData = [];
  data.value.forEach((item) => {
    let mapped = Object.keys(item).reduce((acc, key) => {
      let newKey = utfMapping.value[key];
      ["SUM", "AVG", "MAX", "MIN", "COUNT", "COUNT_D", "MED"].forEach(
        (aggf) => {
          if (key.includes(aggf)) {
            let utfKey = key.substring(aggf.length + 1, key.length - 1);
            let nk = utfMapping.value[utfKey];
            newKey = `${aggf}(${nk})`;
          }
        }
      );
      acc[newKey] = item[key];
      return acc;
    }, {});
    newData.push(mapped);
  });
  data.value = newData;
  return data;
};
const getMapData = async (url,param) => {
  const config = {
      method: 'post',
      url: url,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : param
    }
  return await axios(config)
    .then(async (res) => {
      const mapData = await res.data;
      return mapData;
    })
    .catch((error) => console.log("error >> ", error));
};
const setColorToItem = (item) => {
  switch (item.agg) {
    case "MIN":
      item.color = "#520339";
      break;
    case "MAX":
      item.color = "#722ed1";
      break;
    case "SUM":
      item.color = "#2f54eb";
      break;
    case "AVG":
      item.color = "#08979c";
      break;
    case "MED":
      item.color = "#0ac960";
      break;
    case "COUNT":
      item.color = "#389e0d";
      break;
    case "COUNT_D":
      item.color = "#cf1322";
      break;
    case "":
      switch (item.type) {
        case "string":
          item.color = "#189FF0";
          break;
        case "number":
          item.color = "#002766";
          break;
        case "date":
          item.color = "#391085";
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }

  return item;
};

const getItems = (schema) => {
  let lst = [];
  for (const [key, value] of Object.entries(schema)) {
    if (key === "uuid") {
      continue;
    }
    if (value === "string") {
      lst.push({
        id: `item:${key}`,
        content: key,
        type: value,
        color: "#189FF0",
        agg: "",
        icon: (
          <FieldStringOutlined
            style={{
              fontSize: 18,
              marginTop: 3,
              paddingRight: 3,
              paddingLeft: 3,
              backgroundColor: "#fff",
              color: "#189FF0",
              borderRadius: 4,
            }}
          />
        ),
      });
    } else if (value === "number") {
      lst.push({
        id: `item:${key}`,
        content: key,
        type: value,
        color: "#002766",
        agg: "",
        icon: (
          <FieldNumberOutlined
            style={{
              fontSize: 18,
              marginTop: 3,
              paddingRight: 3,
              paddingLeft: 3,
              backgroundColor: "#fff",
              color: "#002766",
              borderRadius: 4,
            }}
          />
        ),
      });
    } else if (value === "date") {
      lst.push({
        id: `item:${key}`,
        content: key,
        type: value,
        color: "#391085",
        agg: "",
        icon: (
          <FieldTimeOutlined
            style={{
              fontSize: 18,
              marginTop: 3,
              paddingRight: 3,
              paddingLeft: 3,
              backgroundColor: "#fff",
              color: "#391085",
              borderRadius: 4,
            }}
          />
        ),
      });
    }
  }
  lst = _.sortBy(lst, ["type", "content"]);
  return lst;
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const copy = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const item = sourceClone[droppableSource.index];
  //insert at index
  destClone.splice(droppableDestination.index, 0, { ...item, id: `${(Date.now()).toString()}:${item.content}`});
  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;
  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, {...removed, id: `${(Date.now()).toString()}:${removed.content}`});
  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;
  return result;
};

const grid = 3;

const getItemStyleH = (draggableStyle, color) => ({
  userSelect: "none",
  padding: grid,
  margin: `0 ${grid}px 0 ${grid}px`,
  borderRadius: 7,
  width: 134.58,
  height: 30,
  background: color,
  color: "#fff",
  position: "relative",
  ...draggableStyle,
});

const getItemStyleV = (draggableStyle, color) => ({
  userSelect: "none",
  padding: grid,
  margin: `0 ${grid}px ${grid}px ${grid}px`,
  borderRadius: 7,
  width: "98%",
  height: 30,
  background: color,
  color: "#fff",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  ...draggableStyle,
});

const getListStyleH = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "#f2f2f2",
  display: "flex",
  padding: grid,
  overflow: "auto",
  height: 38,
  borderBottom: "1px solid #e6e6e6",
});

const getListStyleV = {
  padding: grid,
  width: "100%",
  height: "calc(100vh - 190px)",
  overflowY: "scroll",
  overflowX: "hidden",
};
