import React, { useContext, useState, useEffect } from "react";
import "./TableSelectDataModal.css";
import { Modal, Card, Form, Button } from "antd";

import axios from "axios";
import { useHistory, useParams } from "react-router-dom";

import { DataContext } from "../../contexts/data";
import { WebParameterContext } from "../../contexts/webParameter";

import TableSelectData from "../TableSelectData";
import DynamicConditionFormItem from "../DynamicConditionFormItem";

const TableSelectDataModal = ({ form, visible, modalVisibility, index }) => {
  const { _schema_, _isMapping_, _utfMapping_ } = useContext(DataContext);
  const { _baseUrl_ } = useContext(WebParameterContext);

  const [data, setData] = useState({ value: [], numData: 0, isUpdated: false });
  const [initData, setInitData] = useState({
    value: [],
    numData: 0,
    isUpdated: false,
  });
  const [ft, setFt] = useState("");

  const history = useHistory();
  const [filterForm] = Form.useForm();
  const { dsname, datasetId } = useParams();

  useEffect(() => {
    const callGetData = async () => {
      try {
        let _data = await getData(
          _isMapping_.value
            ? `${
                _baseUrl_.value
              }/graphql?id=${datasetId}&dsname=${dsname}&query={records(${ft}LIMIT:"${10}", OFFSET:"${0}")${
                _schema_.schemaStrMapped
              }numData{result}}`
            : `${
                _baseUrl_.value
              }/graphql?id=${datasetId}&dsname=${dsname}&query={records(${ft}LIMIT:"${10}", OFFSET:"${0}")${
                _schema_.schemaStr
              }numData{result}}`
        );
        if (_isMapping_.value) {
          _data = dataMapping(_data, _utfMapping_);
        }
        setData(_data);
        setInitData(_data);
      } catch (error) {
        console.log("error >> ", error);
        history.push(window.location.pathname + "/error");
      }
    };
    if (
      _schema_.isUpdated &&
      _baseUrl_.isUpdated &&
      _isMapping_.isUpdated &&
      _utfMapping_.isUpdated
    ) {
      callGetData();
    }
  }, [_schema_, ft, _baseUrl_, _isMapping_, _utfMapping_, history, dsname, datasetId]);

  const onSubmitFilterForm = ({ ConditionForm }) => {
    const conditionList = ConditionForm;

    let uniqueColList = [];
    if (conditionList !== undefined && conditionList.length !== 0) {
      uniqueColList = [
        ...new Set(conditionList.map((condition) => condition.col)),
      ];
    }

    let uniqueColListUtf = [];
    if (_isMapping_.value) {
      uniqueColList.forEach((item) => {
        let str = "";
        Buffer.from(item, "utf-8").forEach((hex) => {
          str += `_${hex.toString(16).toUpperCase()}`;
        });
        uniqueColListUtf.push(str);
      });
      uniqueColList = uniqueColListUtf;
    }

    if (uniqueColList.length !== 0) {
      let filters = "";

      uniqueColList.forEach((uCol) => {
        let filter = `${uCol}:"`;

        for (let i = 0; conditionList.length > i; i++) {
          let c = conditionList[i]["col"];

          if (_isMapping_.value) {
            c = "";
            Buffer.from(conditionList[i]["col"], "utf-8").forEach((hex) => {
              c += `_${hex.toString(16).toUpperCase()}`;
            });
          }

          if (c === uCol) {
            if (conditionList[i]["operator"] === "Contains") {
              filter += `CONTAINS(${conditionList[i]["condition"]}),`;
            } else if (conditionList[i]["operator"] === "Equals") {
              filter += `EQUALS(${conditionList[i]["condition"]}),`;
            } else {
              filter += `${conditionList[i]["operator"]}${conditionList[i]["condition"]},`;
            }
          }
        }
        filter = filter.replace(/,$/, "");
        filter += '"';
        filters += filter;
      });

      setFt(filters);
    } else {
      setFt("");
    }
  };

  const onChangePageOrPageSize = async (p, ps) => {
    try {
      let _data = await getData(
        _isMapping_.value
          ? `${
              _baseUrl_.value
            }/graphql?id=${datasetId}&dsname=${dsname}&query={records(${ft}LIMIT:"${ps}", OFFSET:"${
              (p - 1) * ps
            }")${_schema_.schemaStrMapped}numData{result}}`
          : `${
              _baseUrl_.value
            }/graphql?id=${datasetId}&dsname=${dsname}&query={records(${ft}LIMIT:"${ps}", OFFSET:"${
              (p - 1) * ps
            }")${_schema_.schemaStr}numData{result}}`
      );
      if (_isMapping_.value) {
        _data = dataMapping(_data, _utfMapping_);
      }
      setData(_data);
      setInitData(_data);
    } catch (error) {
      console.log("error >> ", error);
      history.push(window.location.pathname + "/error");
    }
  };

  const modalVisibilityLocal = async () => {
    setData(initData);
    modalVisibility();
  };
  return (
    <Modal
      title="เลือกข้อมูล"
      open={visible}
      onOk={() => modalVisibilityLocal()}
      onCancel={() => modalVisibilityLocal()}
      okText="ตกลง"
      cancelText="ยกเลิก"
      width={1300}
      centered
    >
      {data.isUpdated && visible ? (
        <div>
          <Card
            className="table-select-data-modal-filter-card"
            style={{ marginBottom: 10 }}
          >
            <Form
              onFinish={onSubmitFilterForm}
              size="small"
              layout="inline"
              name="filterForm"
              form={filterForm}
            >
              <Form.Item style={{ width: 600 }} label="ฟิลเตอร์">
                <div
                  id="table-select-data-modal-scrollbar-style"
                  className="table-select-data-modal-condition-container"
                >
                  <DynamicConditionFormItem />
                </div>
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  ค้นหา
                </Button>
              </Form.Item>
            </Form>
          </Card>
          <TableSelectData
            schema={_schema_}
            data={data}
            onChangePageOrPageSize={onChangePageOrPageSize}
            form={form}
            index={index}
          />
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </Modal>
  );
};

export default TableSelectDataModal;

const getData = async (url) => {
  return await axios
    .get(url)
    .then(async (res) => {
      const data = await res.data.data.records;
      const numData = await res.data.data.numData.result;
      return { value: data, numData: numData, isUpdated: true };
    })
    .catch((error) => console.log("error >> ", error));
};

const dataMapping = (data, utfMapping) => {
  const newData = [];
  data.value.forEach((item) => {
    let mapped = Object.keys(item).reduce((acc, key) => {
      acc[utfMapping.value[key]] = item[key];
      return acc;
    }, {});
    newData.push(mapped);
  });
  data.value = newData;
  return data;
};
