import React, { useMemo, useState, useEffect } from "react";
import { BlockPicker } from "react-color";

const ColorPicker = ({ defaultColor, width, index, onChangeColorComplete }) => {
  const [color, setColor] = useState(defaultColor);

  useEffect(() => {
    onChangeColorComplete(index, defaultColor);
  }, [index, defaultColor, onChangeColorComplete]);

  return useMemo(() => {
    const onChangeCompleteLocal = (color) => {
      setColor(color.hex);
      onChangeColorComplete(index, color.hex);
    };
    return (
      <BlockPicker
        triangle="hide"
        color={color}
        onChangeComplete={onChangeCompleteLocal}
        width={width}
        colors={[
          "#ffa39e",
          "#ffbb96",
          "#ffd591",
          "#ffe58f",
          "#fffb8f",
          "#eaff8f",
          "#b7eb8f",
          "#87e8de",
          "#91d5ff",
          "#adc6ff",
          "#d3adf7",
          "#ffadd2",
          "#8c8c8c",
        ]}
      />
    );
  }, [color, width, index, onChangeColorComplete]);
};

export default ColorPicker;
