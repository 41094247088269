import React, { useMemo } from "react";
import { Modal } from "antd";
import MapGroupingForm from "../MapGroupingForm";

const MapGroupingModal = ({ visible, onCancel, onFinishModal }) => {
  return useMemo(() => {
    const onFinishForm = (values) => {
      onFinishModal(values);
    };
    return (
      <Modal
        title="กลุ่ม"
        open={visible}
        onCancel={onCancel}
        okText="ตกลง"
        cancelText="ยกเลิก"
        footer={null}
        destroyOnClose={true}
        width={530}
      >
        <MapGroupingForm onFinishForm={onFinishForm} />
      </Modal>
    );
  }, [visible, onCancel, onFinishModal]);
};

export default MapGroupingModal;
