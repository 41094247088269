export const nameInputFormItem = {
  display: "inline-block",
  width: "100%",
  marginRight: 6,
};
export const colorPickerFormItem = {
  width: "100%",
};
export const dataFormItem = {
  width: "100%",
};
export const addBtn = {
  width: "100%",
};
export const addBtnFormItem = {
  marginTop: 10,
};
