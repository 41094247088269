import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import "./MainPage.css";
import { Layout, Spin } from "antd";

import axios from "axios";
import { useHistory } from "react-router-dom";

import { WebParameterContext } from "../../contexts/webParameter";
import { DataContext } from "../../contexts/data";

import AppHeader from "../../components/AppHeader";
import AppFooter from "../../components/AppFooter";
import AppSider from "../../components/AppSider";
import AppContent from "../../components/AppContent";
import AppNavbar from "../../components/AppNavbar";

const MainPage = (props) => {
  const _webParameterContext = useRef(useContext(WebParameterContext));
  const {
    _setPage_,
    _setPageSize_,
    _setEmbed_,
    _setChartFilter_,
    _setSelectedState_,
    _setChartInfoEmbed_,
    _setChartType_,
    _setMainFilter_,
    _setCurrBaseMap_,
    _setLineSort_,
    _setBarSort_,
    _setLocation_,
    _setScorecardLabel_,
    _setTableSorter_,
    _setMapFilter_,
    _setTooltipField_,
    _setLimitOffsetMap_,
    _setMapLevel_,
    _setChartHeatmapFilter_,
    _setBarChartType_,
    _setUtmLocation_,
    _setCardColor_,
    _setSelectedLbState_,
    _setSelectedPieState_,
    _setSelectedBarState_,
    _setSelectedScatterTreemapState_,
    _setSelectedScorecardState_,
    _setSelectedHeatmapState_,
    _setSelectedSunburstState_,
    _setZoomLevel_,
    _setCoordinate_,
    _setLatitude_,
    _setLongitude_,
    _setShowBack_,
    _setShowEdit_,
    _setShowZoom_,
    _setShowType_,
    _setShowInfo_,
    _setTitleFont_,
    _setSharedTitle_,
    _setShowFilter_,
    _setShareSetting_,
    _setCurrentMapLayer_,
    _setShowAxisLabel_
  } = useContext(WebParameterContext);
  const {
    _setSchema_,
    _setIsMapping_,
    _setUtfMapping_,
    _breakpoint_,
    _setBreakpoint_,
    _setFieldList_
  } = useContext(DataContext);

  const propsRef = useRef(props);
  const mapRef = useRef();
  const history = useHistory();

  const [dataInfo, setDataInfo] = useState({ value: {}, isUpdated: false });
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const [tabIndex, setTabIndex] = useState(1);
  const [showComponent, setShowComponent] = useState(true);

  useEffect(() => {
    const getBreakpoint = () => {
      const screenWidth = window.innerWidth;
      let bp = "";
      if (screenWidth < 576) {
        bp = "xs";
      } else if (screenWidth >= 576 && screenWidth < 768) {
        bp = "sm";
      } else if (screenWidth >= 768 && screenWidth < 992) {
        bp = "md";
      } else if (screenWidth >= 992 && screenWidth < 1200) {
        bp = "lg";
      } else if (screenWidth >= 1200 && screenWidth < 1600) {
        bp = "xl";
      } else if (screenWidth >= 1600) {
        bp = "xxl";
      }
      _setBreakpoint_(bp);
    };

    getBreakpoint();
    window.addEventListener("resize", getBreakpoint);
  }, [_setBreakpoint_]);

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search);
    const _showComponent = !(
      searchParams.has("map") || searchParams.has("chart") || searchParams.has("table")
    );
    setShowComponent(_showComponent);

    //set tab
    if (searchParams.has("t")) {
      const _t = parseInt(searchParams.get("t"));
      setTabIndex(_t);
    }

    //map embed
    searchParams.has("limit") &&
      _setPageSize_(parseInt(searchParams.get("limit")));

    searchParams.has("offset") &&
      _setPage_(
        parseInt(searchParams.get("offset")) /
          parseInt(searchParams.get("limit")) +
          1
      );
    if (searchParams.has("map")) {
      _setEmbed_(true);
      setTabIndex(2);
    }
    searchParams.has("base") && _setCurrBaseMap_(searchParams.get("base"));
    searchParams.has("mainfilter") &&
      _setMainFilter_(
        decodeURIComponent(searchParams.get("mainfilter"))
          .split("|")
          .map((item) => JSON.parse(item))
      );

    searchParams.has("lat") &&
      searchParams.has("lat") &&
      _setLocation_({
        lat: decodeURIComponent(searchParams.get("lat")),
        lng: decodeURIComponent(searchParams.get("lng")),
      });

    //chart embed
    if (searchParams.has("chart")) {
      _setEmbed_(true);
      setTabIndex(3);
    }
    //table embed
    if (searchParams.has("table")) {
      _setEmbed_(true);
      setTabIndex(1);
    }
    searchParams.has("chartinfo") && _setChartInfoEmbed_(true);

    searchParams.has("charttype") &&
      _setChartType_(searchParams.get("charttype"));

    searchParams.has("ls") && _setLineSort_(searchParams.get("ls"));

    searchParams.has("bs") && _setBarSort_(searchParams.get("bs"));
    searchParams.has("bt") && _setBarChartType_(searchParams.get("bt"));

    searchParams.has("chartfilter") &&
      _setChartFilter_(
        decodeURIComponent(searchParams.get("chartfilter"))
        .split("|")
        .map((item) => JSON.parse(item))
      );
    searchParams.has("linebar") &&
      _setSelectedLbState_(JSON.parse(decodeURIComponent(searchParams.get("linebar"))));
    // (searchParams.has("pie") || searchParams.has("bar")) &&
    //   _setSelectedPieBarState_(JSON.parse(decodeURIComponent(searchParams.get("pie") ?? searchParams.get("bar"))));
    (searchParams.has("pie")) &&
      _setSelectedPieState_(JSON.parse(decodeURIComponent(searchParams.get("pie"))));
    (searchParams.has("bar")) &&
      _setSelectedBarState_(JSON.parse(decodeURIComponent(searchParams.get("bar"))));
    if(searchParams.has("scatter")||searchParams.has("treemap")){
      if(searchParams.get("charttype") === "Treemap chart"){
        _setSelectedScatterTreemapState_(JSON.parse(decodeURIComponent(searchParams.get("treemap"))))
      }
      else{
        _setSelectedScatterTreemapState_(JSON.parse(decodeURIComponent(searchParams.get("scatter"))))
      }
    }
    searchParams.has("scorecard") &&
      _setSelectedScorecardState_(JSON.parse(decodeURIComponent(searchParams.get("scorecard"))));
    searchParams.has("heatmap") &&
      _setSelectedHeatmapState_(JSON.parse(decodeURIComponent(searchParams.get("heatmap"))));
    searchParams.has("sunburst") &&
      _setSelectedSunburstState_(JSON.parse(decodeURIComponent(searchParams.get("sunburst"))));

    searchParams.has("label") &&
    _setScorecardLabel_(JSON.parse(decodeURIComponent(searchParams.get("label"))));
    if(searchParams.has("color")){
      const color = JSON.parse(searchParams.get("color"));
      _setCardColor_({
        background: color[0],
        title: color[1],
        number: color[2]
      })
    }

    if(searchParams.has("sorter")){
      const sorterObj = JSON.parse(decodeURIComponent(searchParams.get("sorter")))
      const tableSortUrl  = sorterObj.map((item) => {
        const obj = {};
        if (item.includes(":")) {
            obj["columnKey"] = item.substring(0, item.indexOf(":"));
            obj["order"] = item.substring(item.indexOf(":") + 1, item.length);
        }
        return obj
      })
      _setTableSorter_(tableSortUrl)
    }

    searchParams.has("mapfilter") &&
      _setMapFilter_(
        decodeURIComponent(searchParams.get("mapfilter"))
          .split("|")
          .map((item) => JSON.parse(item))
      );
    searchParams.has("field") &&
    _setTooltipField_(JSON.parse(decodeURIComponent(searchParams.get("field"))));
    searchParams.has("mlimit") && searchParams.has("moffset") &&
    _setLimitOffsetMap_({offset: parseInt(searchParams.get("moffset")), limit: parseInt(searchParams.get("mlimit"))});
    searchParams.has("heatmapft") &&
      _setChartHeatmapFilter_(
        decodeURIComponent(searchParams.get("heatmapft"))
          .split("|")
          .map((item) => JSON.parse(item))
      );

    
    searchParams.has("level") && _setMapLevel_(JSON.parse(decodeURIComponent(searchParams.get("level"))))
    searchParams.has("utm") && _setUtmLocation_(JSON.parse(decodeURIComponent(searchParams.get("utm"))))
    searchParams.has("zoom_level") && _setZoomLevel_(searchParams.get("zoom_level"))
    searchParams.has("set_coordinate") && _setCoordinate_(JSON.parse(searchParams.get("set_coordinate")))
    searchParams.has("x") && _setLatitude_(JSON.parse(searchParams.get("x")))
    searchParams.has("y") && _setLongitude_(JSON.parse(searchParams.get("y")))
    searchParams.has("show_back") && _setShowBack_(JSON.parse(searchParams.get("show_back")))
    searchParams.has("show_edit") && _setShowEdit_(JSON.parse(searchParams.get("show_edit")))
    searchParams.has("show_map_zoom") && _setShowZoom_(JSON.parse(searchParams.get("show_map_zoom")))
    searchParams.has("show_map_type") && _setShowType_(JSON.parse(searchParams.get("show_map_type")))
    searchParams.has("show_info") && _setShowInfo_(JSON.parse(searchParams.get("show_info")))
    searchParams.has("show_filter") && _setShowFilter_(JSON.parse(searchParams.get("show_filter")))
    searchParams.has("is_small_frame") && _setTitleFont_(JSON.parse(searchParams.get("is_small_frame")))
    searchParams.has("share_title") && _setSharedTitle_(JSON.parse(searchParams.get("share_title")))
    searchParams.has("share_setting") && _setShareSetting_(JSON.parse(searchParams.get("share_setting")))
    searchParams.has("layer") && _setCurrentMapLayer_(decodeURIComponent(searchParams.get("layer")))
    searchParams.has("show_axis_label") && _setShowAxisLabel_(JSON.parse(searchParams.get("show_axis_label")))
  }, [
    _setEmbed_,
    _setPageSize_,
    _setPage_,
    _setChartFilter_,
    _setChartInfoEmbed_,
    _setChartType_,
    _setMainFilter_,
    _setSelectedState_,
    _setLocation_,
    _setScorecardLabel_,
    _setMapFilter_,
    _setTooltipField_,
    _setLimitOffsetMap_,
    _setChartHeatmapFilter_,
    _setMapLevel_,
    _setBarChartType_,
    _setBarSort_,
    _setCurrBaseMap_,
    _setLineSort_,
    _setTableSorter_,
    _setUtmLocation_,
    _setCardColor_,
    _setSelectedLbState_,
    _setSelectedPieState_,
    _setSelectedBarState_,
    _setSelectedScatterTreemapState_,
    _setSelectedScorecardState_,
    _setSelectedHeatmapState_,
    _setSelectedSunburstState_,
    _setZoomLevel_,
    _setCoordinate_,
    _setLatitude_,
    _setLongitude_,
    _setShowBack_,
    _setShowEdit_,
    _setShowZoom_,
    _setShowType_,
    _setShowInfo_,
    _setTitleFont_,
    _setSharedTitle_,
    _setShowFilter_,
    _setShareSetting_,
    _setCurrentMapLayer_,
    _setShowAxisLabel_
  ]);

  useEffect(() => {
    const { dsname, dataSource, datasetId } = propsRef.current.match.params;
    _webParameterContext.current._setBaseUrl_({
      // value: `${process.env.REACT_APP_API_HOST}/${dsname}/${dataSource}/resource/${datasetId}`,
      value: `${process.env.REACT_APP_API_HOST}/${dataSource}/resource`,
      isUpdated: true,
    });

    const initDataInfo = async () => {
      try {
        // const d = await getData(
        //   `${process.env.REACT_APP_API_HOST}/${dsname}/${dataSource}/resource/${datasetId}/graphql?query=query{info{result}schema{result}isMapping{result}utfMapping{result}}`
        // );
        const d = await getData(
          `${process.env.REACT_APP_API_HOST}/${dataSource}/resource/graphql?query={info{result}schema{result}isMapping{result}utfMapping{result}}&id=${datasetId}&dsname=${dsname}`
        );
        document.title = `Data Catalog | Opend Playground ${d.dataInfo.value.package_title} (${d.dataInfo.value.resource_title})`
        setDataInfo(d.dataInfo);
        _setIsMapping_(d.isMapping);
        _setSchema_(d.schema);
        _setUtfMapping_(d.utfMapping);
      } catch (error) {
        console.log("error >> ", error);
        history.push(window.location.pathname + "/error");
      }
    };
    const getExceptFields = async () => {
      try{
        const res = await axios.get(`${process.env.REACT_APP_API_MAP_HOST}/fields`)
        if(res.status === 200){
          let fieldList = []
          if(Array.isArray(res.data)){
            for(let item of res.data){
              if(Array.isArray(item.FIELDS)){
                fieldList = [...fieldList, ...item.FIELDS.map(x=>x.FIELD_NAME)]
              }
            }
          }
          _setFieldList_(fieldList)
        }
      }
      catch(error){
        console.log("error >> ", error);
      }
    }

    getExceptFields()
    initDataInfo();
  }, [_setSchema_, _setIsMapping_, _setUtfMapping_, history, _setFieldList_]);
  
  return useMemo(() => {
    const onCollapse = () => {
      setSiderCollapsed(!siderCollapsed);
    };


    const onChangeTab = (nextTab) => {
      if (nextTab === 1) {
          setTabIndex(nextTab);
      } else if (nextTab === 2 || nextTab === 3 || nextTab === 4) {
        setTabIndex(nextTab);
        if (nextTab === 2) {
          mapRef.current.reRenderMap();
        }
      }
    };

    return (
      <div className="MainPage">
        {dataInfo.isUpdated ? (
          <>
            <Layout className="main-page-layout">
              {_breakpoint_ === "xs" ? (
                <AppNavbar
                  onChangeTab={onChangeTab}
                  showComponent={showComponent}
                  defaultOpenKey={tabIndex.toString()}
                />
              ) : (
                <AppSider
                  collapsed={siderCollapsed}
                  onCollapse={onCollapse}
                  onChangeTab={onChangeTab}
                  showComponent={showComponent}
                  defaultOpenKey={tabIndex.toString()}
                />
              )}

              <Layout className="main-page-content-layout">
                <AppHeader
                  dataInfo={dataInfo}
                  onChangeTab={onChangeTab}
                  breakpoint={_breakpoint_}
                  currentTab={tabIndex}
                  defaultOpenKey={
                    (tabIndex === 1) && tabIndex
                  }
                />
                <AppContent
                  tabIndex={tabIndex}
                  ref={mapRef}
                  dataInfo={dataInfo}
                />
                {
                tabIndex === 1 &&
                <AppFooter
                  breakpoint={_breakpoint_}
                  tabIndex={tabIndex}
                />
                }
              </Layout>
            </Layout>
          </>
        ) : (
          <>
            <Spin size="large" style={{ marginTop: 60 }} />
          </>
        )}
      </div>
    );
  }, [
    siderCollapsed,
    tabIndex,
    dataInfo,
    showComponent,
    _breakpoint_,
  ]);
};

export default MainPage;

const getData = async (url) => {
  return await axios
    .get(url)
    .then(async (res) => {
      let dataInfo = await res.data.data.info.result;
      let schema = await res.data.data.schema.result;
      const isMapping = await res.data.data.isMapping.result;

      if (dataInfo === null) {
        dataInfo = {
          package_title: "-",
          resource_title: "-",
          ref_url: "https://gdcatalog.go.th/",
        };
      }

      let utfMapping;
      if (isMapping) {
        utfMapping = await res.data.data.utfMapping.result;
        const newSchema = {};
        Object.keys(schema).forEach((key) => {
          newSchema[utfMapping[key]] = schema[key];
        });
        schema = newSchema;
      }

      let schemaStr = "{";
      for (let [key] of Object.entries(schema)) {
        schemaStr += `${key},`;
      }
      schemaStr += "}";

      let schemaStrMapped = "";
      if (isMapping) {
        schemaStrMapped = "{";
        for (let [key] of Object.entries(utfMapping)) {
          schemaStrMapped += `${key},`;
        }
        schemaStrMapped += "}";
      }

      return {
        dataInfo: { value: dataInfo, isUpdated: true },
        schema: {
          value: schema,
          schemaStr: schemaStr,
          schemaStrMapped: schemaStrMapped,
          isUpdated: true,
        },
        isMapping: { value: isMapping, isUpdated: true },
        utfMapping: { value: utfMapping, isUpdated: true },
      };
    })
    .catch((error) => console.log("error >> ", error));
};
