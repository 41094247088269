import React, { useState, useContext, useEffect } from "react";
import "./MapFieldModal.css";
import { Button, Modal, Form, Select } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { DataContext } from "../../contexts/data";
import * as wordList from "../../config/heatmapData";
import { WebParameterContext } from "../../contexts/webParameter";
const { Option } = Select;

const MapFieldModal = ({ updateFormSubmit, mapFieldStatus }) => {
  const { _schema_ } = useContext(DataContext);
  const { _embed_, _chart_ } = useContext(WebParameterContext)
  const [options, setOptions] = useState({ options: {}, isUpdated: false });

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);


  useEffect(() => {
    if (_schema_.isUpdated) {
      let _options = Object.keys(_schema_.value);
      _options = _options.filter(item => item !== "unique" && item !== "uuid")
      //check each word list include _options item to set default value
      let hasRegion = _options.find((item) => wordList.regionWordList.includes(item.toLowerCase()))
      let hasProvince = _options.find((item) => wordList.provinceWordList.includes(item.toLowerCase()))
      let hasDistrict = _options.find((item) => wordList.districtWordList.includes(item.toLowerCase()))
      let hasSubDistrict = _options.find((item) => wordList.subDistrictWordList.includes(item.toLowerCase()))

      if(hasRegion){
        form.setFieldsValue({
          region: hasRegion,
        });
        _options = _options.filter(item => item.toLowerCase() !== hasRegion.toLowerCase())
      }
      if(hasProvince){
        form.setFieldsValue({
          province: hasProvince,
        });
        _options = _options.filter(item => item.toLowerCase() !== hasProvince.toLowerCase())
      }
      if(hasDistrict){
        form.setFieldsValue({
          district: hasDistrict,
        });
        _options = _options.filter(item => item.toLowerCase() !== hasDistrict.toLowerCase())
      }
      if(hasSubDistrict){
        form.setFieldsValue({
          sub_district: hasSubDistrict,
        });
        _options = _options.filter(item => item.toLowerCase() !== hasSubDistrict.toLowerCase())
      }

      setOptions({ options: _options, isUpdated: true });
      if(_chart_ === "Heatmap" && !mapFieldStatus){
        setVisible(true)
      }
    }

  }, [_schema_, form, mapFieldStatus, _chart_]);


  const onSubmitForm = (values) => {
    setVisible(false)
    updateFormSubmit(values)
  };
  const onClose = () =>{
    setVisible(false)
    updateFormSubmit()
  }
  const onOpen = () =>{
    setVisible(true)
  }

  const handleChange = (value) => {
    let selectedList = Object.values(form.getFieldsValue())
    let defaultOpt = Object.keys(_schema_.value).filter(item => item !== "unique" && item !== "uuid")
    let filterOp = defaultOpt.filter((item) => !selectedList.includes(item))
    setOptions({ options: filterOp, isUpdated: true });
  }
  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 20,
    },
    labelAlign: "left"
  };


  return (
    <>
      <Button onClick={onOpen} style={{ height: 30, color: '#40a9ff',borderColor: '#40a9ff', borderRadius: 7 }} disabled={_embed_}>
        กำหนดฟิลด์พื้นที่
      </Button>
      <Modal
        className="chart-filter-filter-modal"
        closeIcon={<CloseCircleOutlined />}
        open={visible}
        onCancel={onClose}
        footer={false}
        bodyStyle={{ paddingTop: 40, paddingLeft: 20 }}
        forceRender
        maskClosable={false}
      >
        {options.isUpdated ?
          <Form
            form={form}
            onFinish={onSubmitForm}
            size="small"
            {...layout}
          >
            <Form.Item
              name="region"
              label="ภาค"
            >
              <Select
                showSearch
                placeholder="กรุณาเลือก"
                allowClear
                onChange={(value)=>handleChange(value)}
              >
                {
                  options.options.map((item)=>(
                    <Option key={item} value={item}>
                    {item}
                  </Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Form.Item
              name="province"
              label="จังหวัด"
            >
              <Select
                showSearch
                placeholder="กรุณาเลือก"
                allowClear
                onChange={(value)=>handleChange(value)}
              >
                {
                  options.options.map((item)=>(
                    <Option key={item} value={item}>
                    {item}
                  </Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Form.Item
              name="district"
              label="อำเภอ"
            >
              <Select
                showSearch
                placeholder="กรุณาเลือก"
                allowClear
                onChange={(value)=>handleChange(value)}
              >
                {
                  options.options.map((item)=>(
                    <Option key={item} value={item}>
                    {item}
                  </Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Form.Item
              name="sub_district"
              label="ตำบล"
            >
              <Select
                showSearch
                placeholder="กรุณาเลือก"
                allowClear
                onChange={(value)=>handleChange(value)}
              >
                {
                  options.options.map((item)=>(
                    <Option key={item} value={item}>
                    {item}
                  </Option>
                  ))
                }
              </Select>
            </Form.Item>

            <Form.Item
              style={{ padding: 5, textAlign: "right" }}
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit">
                ยืนยัน
              </Button>
            </Form.Item>
          </Form>
          :
          null
        }
      </Modal>
      </>
  );
};

export default MapFieldModal;
