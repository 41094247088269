import React, { useState } from "react";

export const DataContext = React.createContext({});
const DataProvider = (props) => {
  const [_data_, _setData_] = useState({
    value: [],
    numData: 0,
    isUpdated: false,
  });
  const [_schema_, _setSchema_] = useState({
    value: {},
    schemaStr: "",
    schemaStrMapped: "",
    isUpdated: false,
  });
  const [_columns_, _setColumns_] = useState({ value: [], isUpdated: false });

  const [_isMapping_, _setIsMapping_] = useState({
    value: false,
    isUpdated: false,
  });
  const [_utfMapping_, _setUtfMapping_] = useState({
    value: {},
    isUpdated: false,
  });
  const [_breakpoint_, _setBreakpoint_] = useState("xxl");
  const [_lbData_, _setLbData_] = useState({ value: [], numData: 0, isUpdated: false });
  const [_pieData_, _setPieData_] = useState({ value: [], numData: 0, isUpdated: false });
  const [_barData_, _setBarData_] = useState({ value: [], numData: 0, isUpdated: false });
  const [_scatterTreemapData_, _setScatterTreemapData_] = useState({ value: [], numData: 0, isUpdated: false });
  const [_scorecardData_, _setScorecardData_] = useState({ value: [], numData: 0, isUpdated: false });
  const [_heatmapData_, _setHeatmapData_] = useState({ value: [], numData: 0, isUpdated: false });
  const [_sunburstData_, _setSunburstData_] = useState({ value: [], numData: 0, isUpdated: false });
  const [_fieldList_, _setFieldList_] = useState([])

  return (
    <DataContext.Provider
      value={{
        _data_,
        _setData_,
        _schema_,
        _setSchema_,
        _columns_,
        _setColumns_,
        _isMapping_,
        _setIsMapping_,
        _utfMapping_,
        _setUtfMapping_,
        _breakpoint_,
        _setBreakpoint_,
        _lbData_,
        _setLbData_,
        _pieData_,
        _setPieData_,
        _barData_,
        _setBarData_,
        _scatterTreemapData_,
        _setScatterTreemapData_,
        _scorecardData_,
        _setScorecardData_,
        _heatmapData_,
        _setHeatmapData_,
        _sunburstData_,
        _setSunburstData_,
        _fieldList_,
        _setFieldList_
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

export default DataProvider;
