import React, { useState, useEffect } from "react";
import LineChart from "../../components/Charts/LineChart";
import BarChart from "../../components/Charts/BarChart";
import PieChart from "../../components/Charts/PieChart";
import ScatterChart from "../../components/Charts/ScatterChart";
import TreemapChart from "../../components/Charts/TreemapChart";
import Scorecard from "../../components/Charts/Scorecard";
import Heatmap from "../../components/Charts/Heatmap";
import BarChart2 from "../../components/Charts/BarChart2";
import SunburstChart from "../../components/Charts/SunburstChart";
import LineChart2 from "../../components/Charts/LineChart2";

const ChartComponent = ({ data, stateX, stateY, chart, geoJson, updateHeatmapGeo }) => {
  const [d, setD] = useState({ value: [], isUpdated: false });
  const [currGeoJson, setCurrGeoJson] = useState([])

  useEffect(() => {
    if (data.value.length > 0) {
      if (data.value[0] !== "prev") {
        setD(data);
      } else {
        setD({ value: [], isUpdated: true });
      }
    }
  }, [data]);
  useEffect(() => {
    if (geoJson?.length > 0) {
      setCurrGeoJson(geoJson)
    }
    else{
      setCurrGeoJson([])
    }
  }, [geoJson]);

  const shouldRenderChart = (ch) => {
    if (d.value === undefined) {
      return false;
    }
    // if(d.isUpdated && d.value.length > 0 && ch==="Heatmap"){
    //   return true
    // }
    if(currGeoJson?.length > 0 && ch === "Heatmap"){
      return true
    }
    if(ch === "Scorecard" && stateY.length > 0 && stateX.length === 0 && d.isUpdated &&
    d.value.length > 0){
      return true
    }
    if (
      d.isUpdated &&
      d.value.length > 0 &&
      stateX.length > 0 &&
      stateY.length > 0 &&
      ch !== "Scorecard" &&
      ch !== "Heatmap"
    ) {
      const xAxis = [];
      stateX.forEach((item) => {
        if (item.agg !== "" && !item.content.includes(item.agg)) {
          xAxis.push(`${item.agg}(${item.content})`);
        }
        if (item.agg === "") {
          xAxis.push(item.content);
        }
      });
      const yAxis = [];
      stateY.forEach((item) => {
        if (item.agg !== "" && !item.content.includes(item.agg)) {
          yAxis.push(`${item.agg}(${item.content})`);
        }
        if (item.agg === "") {
          yAxis.push(item.content);
        }
      });

      const xResult =
        xAxis.length === 0
          ? false
          : xAxis.every((val) => Object.keys(d.value[0]).includes(val));
      const yResult =
        yAxis.length === 0
          ? false
          : yAxis.every((val) => Object.keys(d.value[0]).includes(val));

      if (xResult && yResult) {
        return true;
      }
    }
    return false;
  };

  const sortDate = () => {
    const dataLst = d.value;
    for (const state of stateX) {
      if (state.type === "date") {
        const target = state.agg ? `${state.agg}(${state.content})` : state.content;
        if(typeof dataLst[0][target] === "string"){
          dataLst.sort((a, b) => {
            return a[target].localeCompare(b[target]);
          });
        }
      }
    }
    for (const state of stateY) {
      if (state.type === "date") {
        const target = state.agg ? `${state.agg}(${state.content})` : state.content;
        if(typeof dataLst[0][target] === "string"){
          dataLst.sort((a, b) => {
            return a[target].localeCompare(b[target]);
          });
        }
      }
    }
    return dataLst;
  };

  const getChart = (ch) => {
    const dSorted = sortDate();
    const xAxis = [];
    const yAxis = [];
    const minMaxValue = {
      min: null,
      max: null
    }
    stateX.forEach((item) => {
      if (item.agg !== "" && !item.content.includes(item.agg)) {
        xAxis.push(`${item.agg}(${item.content})`);
      }
      if (item.agg === "") {
        xAxis.push(item.content);
      }
    });
    // const yAxis = [];
    stateY.forEach((item) => {
      if (item.agg !== "" && !item.content.includes(item.agg)) {
        yAxis.push(`${item.agg}(${item.content})`);
      }
      if (item.agg === "") {
        yAxis.push(item.content);
      }
    });
    if(chart === "Heatmap"){
      if(dSorted.length > 0 && stateY.length > 0){
        minMaxValue.min = Math.min(
          ...dSorted.map(item => {
            if(!isNaN(item[stateY[0].agg === "" ? stateY[0].content : `${stateY[0].agg}(${stateY[0].content})`]))
              return item[stateY[0].agg === "" ? stateY[0].content : `${stateY[0].agg}(${stateY[0].content})`]
            else
              return 0

          })
        );
        minMaxValue.max = Math.max(
          ...dSorted.map(item => {
            if(!isNaN(item[stateY[0].agg === "" ? stateY[0].content : `${stateY[0].agg}(${stateY[0].content})`]))
              return item[stateY[0].agg === "" ? stateY[0].content : `${stateY[0].agg}(${stateY[0].content})`]
            else
              return 0
          }
        ));
      }
    }

    if (ch === "Line chart") {
      return <LineChart data={dSorted} stateX={stateX} stateY={stateY} />;
    } else if (ch === "Line chart2") {
      return <LineChart2 data={dSorted} stateX={stateX} stateY={stateY} />;
    } else if (ch === "Bar chart") {
      return <BarChart data={dSorted} stateX={stateX} stateY={stateY} />;
    } else if (ch === "Bar chart2") {
      return <BarChart2 data={dSorted} stateX={stateX} stateY={stateY} />;
    } else if (ch === "Pie chart") {
      return <PieChart data={dSorted} stateX={stateX} stateY={stateY} />;
    } else if (ch === "Sunburst chart") {
      return <SunburstChart data={dSorted} stateX={stateX} stateY={stateY} />;
    } else if (ch === "Scatter chart") {
      return <ScatterChart data={dSorted} stateX={stateX} stateY={stateY} />;
    } else if (ch === "Treemap chart") {
      return <TreemapChart data={dSorted} stateX={stateX} stateY={stateY} />;
    } else if (ch === "Scorecard") {
      return <Scorecard data={dSorted} yAxis={stateY} />;
    } else if (ch === "Heatmap") {
      return <Heatmap yAxis={yAxis} dataGeo={currGeoJson} updateHeatmapGeo={updateHeatmapGeo} minMaxValue={minMaxValue} />;
    }
  };
  return <>{shouldRenderChart(chart) ? getChart(chart) : null}</>;
};

export default React.memo(ChartComponent);
