import React, { useContext } from "react";
import "./AppHeader.css";
import {
  Tooltip,
  Button,
  Layout,
  Typography,
  message,
} from "antd";
import {
  RollbackOutlined,
  ShareAltOutlined
} from "@ant-design/icons";

// import MainFilter from "../MainFilter";
import SettingModal from "../SettingModal";

import copy from "copy-to-clipboard";
import { getFilterStr, getSelectedAnotherChartAxis, getSelectedHeatmapChartAxis, getSortTableStr, getEditLabel, getHeatmapMapLevel } from "../../helpers";
import { WebParameterContext } from "../../contexts/webParameter";

const { Header } = Layout;

const AppHeader = ({
  dataInfo,
  onChangeTab,
  breakpoint,
  currentTab,
  defaultOpenKey,
}) => {
  const {
    _page_,
    _pageSize_,
    _mainFilter_,
    _chartFilter_,
    _lineSort_,
    _barSort_,
    _chart_,
    _location_,
    _tableSorter_,
    _scoreCardLabel_,
    _mapFilter_,
    _tooltipField_,
    _limitOffsetMap_,
    _mapLevel_,
    _chartHeatmapFilter_,
    _barChartType_,
    _utmLocation_,
    _cardColor_,
    _selectedLbState_,
    _selectedPieState_,
    _selectedBarState_,
    _selectedScatterTreemapState_,
    _selectedScorecardState_,
    _selectedHeatmapState_,
    _selectedSunburstState_,
    _selectedLbStateFromShare_,
    _selectedPieStateFromShare_,
    _selectedBarStateFromShare_,
    _selectedScatterTreemapStateFromShare_,
    _selectedScorecardStateFromShare_,
    _selectedHeatmapStateFromShare_,
    _selectedSunburstStateFromShare_,
    _currBaseMap_,
    _currentMapLayer_
  } = useContext(WebParameterContext);

  // const [mode, setMode] = useState(defaultOpenKey === 1 ? "data" : "map");
  // const [iconMode, setIconMode] = useState(
  //   <TableOutlined style={{ fontSize: 24, marginTop: 4 }} />
  // );

  // const handleFuncChange = (e) => {
  //   if (e.target.value === "data") {
  //     setMode("data");
  //     onChangeTab(1);
  //   } else if (e.target.value === "map") {
  //     setMode("map");
  //     onChangeTab(2);
  //   }
  // };

  // function handleMenuClick(e) {
  //   if (e.key === "1") {
  //     setMode("data");
  //     onChangeTab(1);
  //     setIconMode(<TableOutlined style={{ fontSize: 24, marginTop: 4 }} />);
  //   } else if (e.key === "2") {
  //     setMode("map");
  //     onChangeTab(2);
  //     setIconMode(
  //       <EnvironmentOutlined style={{ fontSize: 24, marginTop: 4 }} />
  //     );
  //   }
  // }

  // const menu = (
  //   <Menu
  //     onClick={handleMenuClick}
  //     items={[
  //       { key: 1, label: "ตาราง", icon: <TableOutlined /> },
  //       { key: 2, label: "แผนที่", icon: <EnvironmentOutlined /> },
  //     ]}
  //   >
  //     {/* <Menu.Item key="1" icon={<TableOutlined />}>
  //       ตาราง
  //     </Menu.Item>
  //     <Menu.Item key="2" icon={<EnvironmentOutlined />}>
  //       แผนที่
  //     </Menu.Item> */}
  //   </Menu>
  // );

  // eslint-disable-next-line no-unused-vars
  const filterSpan = () => {
    if (breakpoint === "xs" || breakpoint === "sm") {
      return 18;
    } else {
      return 21;
    }
  };

  const copySharableLink = () => {
    let sharedUrl = window.location.origin + window.location.pathname;
    const filterStr = getFilterStr(_mainFilter_, "main");
    const chartFilterStr = getFilterStr(_chartFilter_, "chart");
    const mapFilterStr = getFilterStr(_mapFilter_, "map");
    const heatmapFilter = getFilterStr(_chartHeatmapFilter_, "heatmap");

    const lineSort = `&ls=${_lineSort_}`;
    const barSort = `&bs=${_barSort_}`;
    const barType = `&bt=${_barChartType_}`;

    const lat = _location_.lat !== "" ? `&lat=${encodeURIComponent(_location_.lat)}` : "";
    const lng = _location_.lng !== "" ? `&lng=${encodeURIComponent(_location_.lng)}` : "";
    const utm = _utmLocation_.x !== "" && _utmLocation_.y !== "" && _utmLocation_.zone !== "" ? `&utm=${encodeURIComponent(JSON.stringify(_utmLocation_))}` : "";
    const mapBase = `&base=${_currBaseMap_}`;

    let selectedLbStr = getSelectedAnotherChartAxis(_selectedLbState_, "linebar");
    // let selectedPieBarStr = getSelectedAnotherChartAxis(_selectedPieBarState_, "pie");//share with bar chart2
    let selectedPieStr = getSelectedAnotherChartAxis(_selectedPieState_, "pie");
    let selectedBarStr = getSelectedAnotherChartAxis(_selectedBarState_, "bar");
    let selectedScatterStr = getSelectedAnotherChartAxis(_selectedScatterTreemapState_, "scatter");
    let selectedTreemapStr = getSelectedAnotherChartAxis(_selectedScatterTreemapState_, "treemap");
    let selectedScorecardStr = getSelectedAnotherChartAxis(_selectedScorecardState_, "scorecard");
    let selectedSunburstStr = getSelectedAnotherChartAxis(_selectedSunburstState_, "sunburst");
    let selectedHeatmapStr = getSelectedHeatmapChartAxis(_selectedHeatmapState_)
    const searchParams = new URLSearchParams(window.location.search);
    //also handle when share from shared link
    if(searchParams.has("linebar")) {
      selectedLbStr = getSelectedAnotherChartAxis(_selectedLbStateFromShare_, "linebar");
    }
    if(searchParams.has("pie")) {
      selectedPieStr = getSelectedAnotherChartAxis(_selectedPieStateFromShare_, "pie");
    }
    if(searchParams.has("bar")) {
      selectedBarStr = getSelectedAnotherChartAxis(_selectedBarStateFromShare_, "bar");
    }
    if(searchParams.has("scatter")) {
      selectedScatterStr = getSelectedAnotherChartAxis(_selectedScatterTreemapStateFromShare_, "scatter");
    }
    if(searchParams.has("treemap")) {
      selectedTreemapStr = getSelectedAnotherChartAxis(_selectedScatterTreemapStateFromShare_, "treemap");
    }
    if(searchParams.has("scorecard")) {
      selectedScorecardStr = getSelectedAnotherChartAxis(_selectedScorecardStateFromShare_, "scorecard");
    }
    if(searchParams.has("sunburst")) {
      selectedSunburstStr = getSelectedAnotherChartAxis(_selectedSunburstStateFromShare_, "sunburst");
    }
    if(searchParams.has("heatmap")) {
      selectedHeatmapStr = getSelectedHeatmapChartAxis(_selectedHeatmapStateFromShare_);
    }
    const sortTableStr = getSortTableStr(_tableSorter_)
    const mapLevelStr = getHeatmapMapLevel(_mapLevel_)
    let scoreCardLabel = getEditLabel(_scoreCardLabel_)
    let color = `&color=${JSON.stringify([_cardColor_.background, _cardColor_.title, _cardColor_.number])}`
    sharedUrl += `?t=${currentTab}&limit=${_pageSize_}&offset=${
      (_page_ - 1) * _pageSize_
    }${filterStr}${mapFilterStr}&charttype=${_chart_}${lineSort}${barSort}${barType}${chartFilterStr}${heatmapFilter}${selectedLbStr}${selectedPieStr}${selectedBarStr}${selectedScatterStr}${selectedTreemapStr}${selectedScorecardStr}${selectedHeatmapStr}${selectedSunburstStr}${mapBase}${lat}${lng}${utm}${scoreCardLabel?scoreCardLabel:""}${color}${sortTableStr?sortTableStr:""}${_tooltipField_.length > 0 ? `&field=${encodeURIComponent(JSON.stringify(_tooltipField_))}` : ""}&mlimit=${_limitOffsetMap_.limit}&moffset=${_limitOffsetMap_.offset}${mapLevelStr}&layer=${encodeURIComponent(_currentMapLayer_)}`;
    sharedUrl = encodeURI(sharedUrl);
    copy(sharedUrl);
    message.success("คัดลอกลิงก์สำหรับแชร์แล้ว", 1);
  };

  return (
    <Header
      className="custom-header"
      style={{ zIndex: 10 }}
    >
      <>
        {currentTab === 1 && (
          <>
            <div className="wrap-header">
              <div className="header-button">
                <Tooltip placement="bottomLeft" title={dataInfo.value.ref_url}>
                  <Button
                    icon={<RollbackOutlined />}
                    type="primary"
                    ghost
                    size="small"
                    href={dataInfo.value.ref_url}
                    style={{ marginTop: -12, marginRight: 5, marginLeft: 5 }}
                  />
                </Tooltip>
                <Tooltip placement="bottomLeft" title="แชร์หน้านี้">
                  <Button
                    icon={<ShareAltOutlined />}
                    type="primary"
                    ghost
                    size="small"
                    onClick={copySharableLink}
                    style={{ marginTop: -12, marginRight: 5 }}
                  />
                </Tooltip>
                <SettingModal dataInfo={dataInfo} appType=""/>
              </div>
              {/* <Tooltip placement="bottomLeft" title="ตั้งค่า">
                <Button
                  icon={<SettingFilled style={{ marginTop: 3 }} />}
                  type="primary"
                  ghost
                  size="small"
                  onClick={showModal}
                  style={{ marginTop: -12, marginRight: 5 }}
                />
              </Tooltip> */}
              <Tooltip
                placement="bottom"
                title={`${dataInfo.value.package_title} (${dataInfo.value.resource_title})`}
              >
                <Typography.Title
                  ellipsis
                  level={3}
                  style={{ width: "100%" }}
                >
                  {`${dataInfo.value.package_title} (${dataInfo.value.resource_title})`}
                </Typography.Title>
              </Tooltip>
            </div>

          </>
        )}
      </>
    </Header>
  );
};

export default AppHeader;
