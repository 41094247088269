import React, { useEffect, useState, useContext } from "react";
// import { Row, Col, Menu } from "antd";
// import {
//   MailOutlined,
//   AppstoreOutlined,
//   SettingOutlined,
// } from "@ant-design/icons";
import {
  Chart,
  Coordinate,
  Interval,
  Axis,
  Tooltip,
  Interaction,
  Legend,
} from "bizcharts";
// import { WebParameterContext } from "../../../contexts/webParameter";
import { getFormatNumber } from "../../../utils/formatNumber";
import { DataContext } from "../../../contexts/data";
import { DataView } from '@antv/data-set';

const PieChart = ({ data, stateX, stateY }) => {
  // const { _embed_, _chartInfoEmbed_ } = useContext(WebParameterContext);
  const { _breakpoint_, _fieldList_ } = useContext(DataContext);
  // const [onlyMain, setOnlyMain] = useState(true);
  // const [colorItemSub, setColorItemSub] = useState("");

  const [valueItem, setValueItem] = useState("");
  const [sumValue, setSumValue] = useState(0);

  const [dataMain, setDataMain] = useState([]);
  const [colorItemMain, setColorItemMain] = useState("");
  // const [chartData, setChartData] = useState([]);
  const hasExcludeFieldX = stateX.some(({content}) => _fieldList_.includes(content))
  const isMobilePhone = () => {
    if (_breakpoint_ === "xs" || _breakpoint_ === "sm") {
      return true;
    }
    return false;
  };
  // const yHasAgg = stateY.some(({agg})=>agg!=="")
  const [xTypeNumber, setXTypeNumber] = useState(false)
  const [yTypeNumber, setYTypeNumber] = useState(false)
  useEffect(() => {
    try {
      const xAxisTemp = [];
      const yAxisTemp = [];
      stateX.forEach((item) => {
        if (item.agg !== "" && !item.content.includes(item.agg)) {
          xAxisTemp.push(`${item.agg}(${item.content})`);
        }
        if (item.agg === "") {
          xAxisTemp.push(item.content);
        }
      });
      stateY.forEach((item) => {
        if (item.agg !== "" && !item.content.includes(item.agg)) {
          yAxisTemp.push(`${item.agg}(${item.content})`);
        }
        if (item.agg === "") {
          yAxisTemp.push(item.content);
        }
      });
      setXTypeNumber(data.some(item=>typeof item[xAxisTemp[0]] === "number"))
      let newData = data.map(item=>{
        let newItem = {...item}
        xAxisTemp.forEach(elm => newItem[elm] = String(newItem[elm]))
        newItem[yAxisTemp[0]] = newItem[yAxisTemp[0]] && typeof newItem[yAxisTemp[0]] === "number" ? Number(newItem[yAxisTemp[0]]) : 0
        return newItem
      })
      const dv = new DataView();
      dv.source(newData).transform({
        type: 'percent',
        field: yAxisTemp[0],
        dimension: xAxisTemp[0]
      });
      setYTypeNumber(data.some((item) => typeof item[yAxisTemp[0]] === "number"))
      setDataMain(dv.rows)
      setColorItemMain(xAxisTemp[0]);
      setValueItem(yAxisTemp[0]);
      /* if(xAxisTemp.length > 1){
        const dv1 = new DataView();
        dv1.source(data).transform({
          type: 'percent',
          field: yAxisTemp[0],
          dimension: xAxisTemp[1]
        });
        setChartData(dv1.rows)
        setColorItemSub(xAxisTemp[1]);
        setOnlyMain(false);
      }
 */
      //sum value of y axis
      //sum for y value type number only
      let sum = 0;
      // const sampleData = data[0];
      if(data.some((item) => typeof item[yAxisTemp[0]] === "number")){
        data.forEach((element) => {
          if(!isNaN(element[yAxisTemp[0]]) && element[yAxisTemp[0]])
            sum += Number(element[yAxisTemp[0]]);
        });
      }
      setSumValue(sum);
    } catch (error) {
      console.log("error >> ", error);
    }
  }, [data, stateX, stateY]);

  const cols = {
    [colorItemMain]:{
      formatter: (val) => {return (!isNaN(val) || val === "" || val === "null") && xTypeNumber && !hasExcludeFieldX ? getFormatNumber(val === "" || val === "null" ? 0 : +val) : val}
    },
    // [colorItemSub]:{
    //   formatter: (val) => {return !isNaN(val) && stateX[1].agg && !hasExcludeFieldX ? getFormatNumber(+val) : val}
    // },
    [valueItem]: {
      formatter: (val) => {
        if(yTypeNumber){
          val = `${new Intl.NumberFormat().format(val)}/${new Intl.NumberFormat().format(sumValue)} (${parseFloat((val / sumValue) * 100).toFixed(2)}%)`;
        }
        return val;
      },
    },
  };
  // const colorList = ["#B3A0CF", "#E0E0E0", "#FDC959", "#C2EA66", "#79BDBA", "#9CD091", "#D2E9F0", "#DEC696", "#C7A869", "#BBDEFB", "#FFF8E1", "#FFEBEE", "#E8F5E9", "#FCE4EC", "#F3E5F5", "#EDE7F6", "#E8EAF6", "#E3F2FD", "#E1F5FE", "#E0F7FA", "#E0F2F1", "#F1F8E9", "#F9FBE7", "#FFFDE7", "#FFF8E1", "#FFF3E0", "#FBE9E7", "#EFEBE9", "#A7FFEB", "#84FFFF", "#84FFFF", "#FF8A8", "#F4FF81", "#FFFF8D", "#64FFDA", "#EA80FC"]
  return (
    <Chart
      height="calc(100% - 35px)"
      data={dataMain}
      scale={cols}
      autoFit
      appendPadding={[15, 5, 10, 0]}
    >
      <Coordinate type="theta" radius={0.85} />
      <Legend />
      <Tooltip showTitle={false}>
        {(title,items) => {
          return <div>
                  <ul className="g2-tooltip-list" style={{textAlign: 'left'}}>
                    <li className="g2-tooltip-list-item">
                      <span className="g2-tooltip-marker" style={{background: items[0].color, width: '8px', height: '8px', borderRadius: '50%', display: 'inline-block', marginRight: '8px'}}></span>
                      <span className="g2-tooltip-name">{items[0].name}</span> :
                      <span className="g2-tooltip-value">{items[0].value}</span>
                    </li>
                  </ul>
                </div>
        }}
      </Tooltip>
      <Axis visible={false} />
      <Interval
        position={valueItem}
        adjust="stack"
        color={colorItemMain}
        element-highlight
        style={{
          lineWidth: 1,
          stroke: "#fff",
        }}
        label={!isMobilePhone() ? colorItemMain : ""}
        tooltip={[`${colorItemMain}*${valueItem}`, (name, value) => {
          return {
            value: `${!isNaN(value) && value ? `${new Intl.NumberFormat().format(value)}/${new Intl.NumberFormat().format(sumValue)} (${parseFloat((value / sumValue) * 100).toFixed(2)}%)` : value}`,
            name: (!isNaN(name) || name === "" || name === "null") && xTypeNumber && !hasExcludeFieldX ? new Intl.NumberFormat().format(name === "" || name === "null" ? 0 : +name) : name
          }
        }]}
      />
      <Interaction type="element-single-selected" />
      {/* {!onlyMain ? (
        <View data={chartData}>
          <Coordinate type="theta" radius={0.85} innerRadius={0.5 / 0.85} />
          <Interval
            position={valueItem}
            adjust="stack"
            element-highlight
            color={[colorItemSub, colorList]}
            style={{
              lineWidth: 1,
              stroke: "#fff",
            }}
            label={colorItemSub}
            tooltip={[`${colorItemSub}*${valueItem}`, (name, value) => {
              return {
                value: `${!isNaN(value) ? `${new Intl.NumberFormat().format(value)}/${new Intl.NumberFormat().format(sumValue)} (${parseFloat((value / sumValue) * 100).toFixed(2)}%)` : value}`,
                name: !isNaN(name) && stateX[1].agg && !hasExcludeFieldX ? new Intl.NumberFormat().format(+name) : name
              }
            }]}
          />
        </View>
      )
      : (
        <Interaction type="element-single-selected" />
      )
    } */}
    </Chart>

    // <Row>
    //   <Col span={23}>
    //     <Chart height={"calc(100vh - 220px)"} data={data} scale={cols} autoFit>
    //       <Coordinate type="theta" radius={0.85} />
    //       <Tooltip showTitle={false} />
    //       <Axis visible={false} />
    //       <Interval
    //         position={valueItem}
    //         adjust="stack"
    //         color={item}
    //         style={{
    //           lineWidth: 1,
    //           stroke: "#fff",
    //         }}
    //         label={[
    //           valueItem,
    //           {
    //             content: (d) => {
    //               return `${d[item]}: ${parseFloat(d[valueItem]/sumValue * 100).toFixed(2)}%`;
    //             },
    //           },
    //         ]}
    //       />
    //       <Interaction type="element-single-selected" />
    //     </Chart>
    //   </Col>
    //   <Col span={1} style={{ height: 620 }}>
    //   </Col>
    // </Row>
  );
};

export default PieChart;
