import React, {useContext, useState} from "react";
import {Tooltip, Button, Modal, Row, Col} from "antd"
import { BgColorsOutlined } from "@ant-design/icons";
import { WebParameterContext } from "../../contexts/webParameter";
import ColorSketchPicker from "../ColorSketchPicker";
import { CloseCircleOutlined } from "@ant-design/icons";


const ModalColorPicker = () =>{
    const { _embed_, _cardColor_, _setCardColor_ } = useContext(WebParameterContext);
    const [visible, setVisible] = useState(false);
    const [cardColor, setCardColor] = useState(_cardColor_)

    const onShow = () => {
        setVisible(true);
    };
    
    const onClose = () => {
        setVisible(false);
        setCardColor(_cardColor_)
    };
    const updateColor = (attr, color) =>{
        let tempColor = {...cardColor}
        tempColor[attr] = color
        setCardColor(tempColor)
    }
    const updateCardColor = () =>{
        _setCardColor_(cardColor)
        setVisible(false);
    }
    return (
        <>
            <Tooltip
                placement="top"
                title="กำหนดสี"
            >
                <Button onClick={onShow} style={{ height: 30, marginRight: 5 }} disabled={_embed_}>
                    <BgColorsOutlined />
                </Button>
            </Tooltip>
            <Modal
                className="chart-filter-filter-modal"
                closeIcon={<CloseCircleOutlined />}
                open={visible}
                onCancel={onClose}
                footer={false}
                bodyStyle={{ paddingTop: 40, paddingLeft: 20 }}
                forceRender
                width="250px"
            >
                <Row>
                    <Col
                    span="14"
                    >
                        <p style={{ marginTop: 5 }}>กำหนดสีพื้นหลัง</p>
                    </Col>
                    <Col
                    span="10"
                    >
                        <ColorSketchPicker style={{ marginTop: 5 }} defaultColor={cardColor.background} updateColor={(e)=>updateColor("background", e)} displayPicker={visible}/>
                    </Col>
                </Row>
                <Row>
                    <Col
                    span="14"
                    >
                        <p style={{ marginTop: 5 }}>กำหนดสีหัวข้อ</p>
                    </Col>
                    <Col
                    span="10"
                    >
                        <ColorSketchPicker style={{ marginTop: 5 }} defaultColor={cardColor.title} updateColor={(e)=>updateColor("title", e)} displayPicker={visible}/>
                    </Col>
                </Row>
                <Row>
                    <Col
                    span="14"
                    >
                        <p style={{ marginTop: 5 }}>กำหนดสีตัวเลข</p>
                    </Col>
                    <Col
                    span="10"
                    >
                        <ColorSketchPicker style={{ marginTop: 5 }} defaultColor={cardColor.number} updateColor={(e)=>updateColor("number", e)} displayPicker={visible}/>
                    </Col>
                </Row>
                <Row style={{justifyContent: 'flex-end'}}>
                    <Button type="primary" onClick={updateCardColor} size="small">
                        ตกลง
                    </Button>
                </Row>
            </Modal>
        </>
    )
}

export default ModalColorPicker