import React, { useState } from "react";
import { Button, Popconfirm, Select } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";

const { Option } = Select;

const PopconfirmAggregate = ({
  item,
  updateState,
  axisOption,
  handleAggOption,
  allItemList,
  hasNoFunctionAgg
}) => {
  const [aggFunction, setAggFunction] = useState()
  const setAgg = (item) => {
    item.agg = aggFunction ?? item.agg;
    if (aggFunction === "MIN") {
      item.color = "#520339";
    } else if (aggFunction === "MAX") {
      item.color = "#722ed1";
    } else if (aggFunction === "SUM") {
      item.color = "#2f54eb";
    } else if (aggFunction === "AVG") {
      item.color = "#08979c";
    } else if (aggFunction === "MED") {
      item.color = "#0ac960";
    } else if (aggFunction === "COUNT") {
      item.color = "#389e0d";
    } else if (aggFunction === "COUNT_D") {
      item.color = "#cf1322";
    } else if (aggFunction === "") {
      item.noFunction = true
      if (item.type === "string") {
        item.color = "#189FF0";
      } else if (item.type === "number") {
        item.color = "#002766";
      } else if (item.type === "date") {
        item.color = "#391085";
      }
    }
    updateState();
  };

  const aggSelectedChange = (value) =>{
    setAggFunction(value)
  }

  const aggOptions = (value, options) => {
    var newOption = [];
    if (value.type === "number" || value.type === "date") {
      newOption = [
        {
          value: "",
          text: "No Function",
        },
        {
          value: "MIN",
          text: "MIN",
        },
        {
          value: "MAX",
          text: "MAX",
        },
        {
          value: "SUM",
          text: "SUM",
        },
        {
          value: "AVG",
          text: "AVG",
        },
        {
          value: "MED",
          text: "MED",
        },
        {
          value: "COUNT",
          text: "COUNT",
        },
        {
          value: "COUNT_D",
          text: "COUNT DISTINCT",
        },
      ];
    } else if (value.type === "string") {
      newOption = [
        {
          value: "",
          text: "No Function",
        },
        {
          value: "COUNT",
          text: "COUNT",
        },
        {
          value: "COUNT_D",
          text: "COUNT DISTINCT",
        },
      ];
    }
    if(handleAggOption){
      let filterCurrent = allItemList.filter(item=>item.content === value.content)
      let getAggList = filterCurrent.map(({agg}) => agg)
      newOption = newOption.filter((item)=>hasNoFunctionAgg ? !getAggList.includes(item.value) : item.value !== "" && !getAggList.includes(item.value))
    }
    return (
      <Select
        defaultValue={value.agg === "" ? null : value.agg}
        placeholder="AGGREGATE"
        style={{ width: 165 }}
        dropdownStyle={{ zIndex: 9999 }}
        onChange={aggSelectedChange}
      >
        {newOption.map((item, index) => (
          <Option value={item.value} key={index}>
            {item.text}
          </Option>
        ))}
        {/* <Option value="">{options === "xAxis" ? "" : "No Function"}</Option>
        <Option value="MIN">MIN</Option>
        <Option value="MAX">MAX</Option>
        <Option value="SUM">SUM</Option>
        <Option value="AVG">AVG</Option>
        <Option value="MED">MED</Option>
        <Option value="COUNT">COUNT</Option>
        <Option value="COUNT_D">COUNT DISTINCT</Option> */}
      </Select>
    );
  };

  return (
    <Popconfirm
      icon={null}
      placement="bottom"
      title={<span>{aggOptions(item, axisOption)}</span>}
      okText="ตกลง"
      cancelText="ยกเลิก"
      okButtonProps={{ style: { width: 50 } }}
      cancelButtonProps={{
        style: { width: 50, margin: 0, padding: 0 },
      }}
      onConfirm={() => {
        setAgg(item);
      }}
    >
      <Button
        size="small"
        type="link"
        style={{width: '20px', height: '20px', marginRight: '10px'}}
        icon={
          <CaretDownOutlined
            style={
              item.type === "string" ? { color: "#000" } : { color: "#fff" }
            }
          />
        }
      ></Button>
    </Popconfirm>
  );
};

export default PopconfirmAggregate;
