import React, { useState, useContext, useEffect } from "react";
import "./ChartFilter.css";
import { Card, Button, Modal, Form, Row, Col, Tag } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

import { DataContext } from "../../contexts/data";
import { WebParameterContext } from "../../contexts/webParameter";

import DynamicConditionFormItem from "../../components/DynamicConditionFormItem";
import ModalColorPicker from "../ModalColorPicker";

const ChartFilter = ({ updateFilter, chart, updateMapField, isFilterUpdate}) => {
  const { _isMapping_ } = useContext(DataContext);
  const { _embed_, _chartFilter_, _setChartFilter_, _chartHeatmapFilter_, _setChartHeatmapFilter_ } =
    useContext(WebParameterContext);

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [tags, setTags] = useState([]);
  const [formField, setFormField] = useState([]);

  useEffect(()=>{
    if(chart === "Heatmap"){
      localUpdateFilter(_chartHeatmapFilter_, isFilterUpdate);
      form.setFieldsValue({
        ConditionForm: _chartHeatmapFilter_,
      });
    }else{
      localUpdateFilter(_chartFilter_, isFilterUpdate);
      form.setFieldsValue({
        ConditionForm: _chartFilter_,
      });
    }
  // eslint-disable-next-line
  },[chart])

  const onShow = () => {
    const { ConditionForm } = form.getFieldsValue()
    setFormField(ConditionForm)
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const localUpdateFilter = (conditionList, update, mode = null) => {
    if(conditionList?.length > 0){
      let AND_Condition = "AND: ["
      let OR_Condition = "OR: ["
      let filter = ""
      //indx 0 must no logic
      if(conditionList[0].logic)
        delete conditionList[0].logic
      for (let i = 0; i < conditionList.length; i++) {
        let col = conditionList[i]["col"];
        if (_isMapping_.value) {
          col = "";
          Buffer.from(conditionList[i]["col"], "utf-8").forEach((hex) => {
            col += `_${hex.toString(16).toUpperCase()}`;
          });
        }
        let operator
        if (conditionList[i]["operator"] === "Contains") {
          operator = `CONTAINS(${conditionList[i]["condition"]})`;
        } else if (conditionList[i]["operator"] === "Equals") {
          operator = `EQUALS(${conditionList[i]["condition"]})`;
        } else if (conditionList[i]["operator"] === "Not Equals") {
          operator = `NOTEQUALS(${conditionList[i]["condition"]})`;
        } else if (conditionList[i]["operator"] === "RegEx") {
          operator = `REGEX(${conditionList[i]["condition"]})`;
        } else if (conditionList[i]["operator"] === "Not Contains") {
          operator = `NOTCONTAINS(${conditionList[i]["condition"]})`;
        } else if (conditionList[i]["operator"] === "Not Null") {
          operator = `NOTNULL`;
        } else {
          operator = `${conditionList[i]["operator"]}${conditionList[i]["condition"]}`;
        }
        if(conditionList.length === 1){
          AND_Condition += `{field: "${col}",operator: "${operator}"}`
          filter += `${AND_Condition}]`
        }else{
          if(conditionList[i]['logic'] && conditionList[i]['logic'] === "OR"){
            OR_Condition += `{field: "${col}",operator: "${operator}"},`
          }
          else if(conditionList[i]['logic'] && conditionList[i]['logic'] === "AND"){
            AND_Condition += `{field: "${col}",operator: "${operator}"},`
          }
          else{
            //check index 1 logic is or ? and
            if(conditionList[1]['logic'] === "OR"){
              OR_Condition += `{field: "${col}",operator: "${operator}"},`
            }
            else{
              AND_Condition += `{field: "${col}",operator: "${operator}"},`
            }
          }
        }
      }
      if(conditionList.some(({logic}) => logic === "AND")){
        filter += `${AND_Condition}]${conditionList.some(({logic}) => logic === "OR")?',':''}`
      }
      if(conditionList.some(({logic}) => logic === "OR")){
        filter += `${OR_Condition}]`
      }
      if(update){
        if(chart === "Heatmap"){
          updateFilter(filter,conditionList);
          _setChartHeatmapFilter_(conditionList);
        }
        else{
          updateFilter(filter, mode);
          _setChartFilter_(conditionList);
        }
      }
      setTags(conditionList);
    }
    else{
      if(update){
        if(chart === "Heatmap"){
          _setChartHeatmapFilter_([])
        }
        else{
          _setChartFilter_([]);
        }
        updateFilter("", mode);
      }
      setTags([]);
    }
    /* let uniqueColList = [];
    if (conditionList !== undefined && conditionList.length !== 0) {
      uniqueColList = [
        ...new Set(conditionList.map((condition) => condition.col)),
      ];
    }

    let uniqueColListUtf = [];
    if (_isMapping_.value) {
      uniqueColList.forEach((item) => {
        let str = "";
        Buffer.from(item, "utf-8").forEach((hex) => {
          str += `_${hex.toString(16).toUpperCase()}`;
        });
        uniqueColListUtf.push(str);
      });
      uniqueColList = uniqueColListUtf;
    } */

    /* if (uniqueColList.length !== 0) {
      let filters = "";

      uniqueColList.forEach((uCol) => {
        let filter = `${uCol}:"`;

        for (let i = 0; conditionList.length > i; i++) {
          let c = conditionList[i]["col"];
          if (_isMapping_.value) {
            c = "";
            Buffer.from(conditionList[i]["col"], "utf-8").forEach((hex) => {
              c += `_${hex.toString(16).toUpperCase()}`;
            });
          }

          if (c === uCol) {
            if (conditionList[i]["operator"] === "Contains") {
              filter += `CONTAINS(${conditionList[i]["condition"]}),`;
            } else if (conditionList[i]["operator"] === "Equals") {
              filter += `EQUALS(${conditionList[i]["condition"]}),`;
            } else if (conditionList[i]["operator"] === "Not Equals") {
              filter += `NOTEQUALS(${conditionList[i]["condition"]}),`;
            } else if (conditionList[i]["operator"] === "RegEx") {
              filter += `REGEX(${conditionList[i]["condition"]}),`;
            } else if (conditionList[i]["operator"] === "Not Contains") {
              filter += `NOTCONTAINS(${conditionList[i]["condition"]}),`;
            } else {
              filter += `${conditionList[i]["operator"]}${conditionList[i]["condition"]},`;
            }
          }
        }
        filter = filter.replace(/,$/, "");
        filter += '"';
        filters += filter;
      });

      updateFilter(filters);
      setTags(conditionList);
      _setChartFilter_(conditionList);
    } else {
      updateFilter("");
      setTags([]);
      _setChartFilter_([]);
    } */
  };

  const onSubmitForm = (form) => {
    const conditionList = form["ConditionForm"];
    localUpdateFilter(conditionList, true, "onchange");
    setVisible(false);
  };
  //when remove tag
  const handleClose = () => {
    // const _tags = tags.filter((tag) => tag !== removedTag);
    form.setFieldsValue({
      ConditionForm: [],
    });
    localUpdateFilter([], true, "onchange");
  };
  const updateForm = (idx) =>{
    const { ConditionForm } = form.getFieldsValue()
    Object.assign(ConditionForm[idx], { operator: null, condition: null })
    form.setFieldsValue({ ConditionForm })
  }
  const updateFormInput = (idx) => {
    const { ConditionForm } = form.getFieldsValue()
    if(ConditionForm[idx].operator === "Not Null"){
      Object.assign(ConditionForm[idx], { condition: null })
      form.setFieldsValue({ ConditionForm })
      //also remove validate
      form.validateFields([['ConditionForm', idx,'condition']])
    }
  }

  const forMap = (tag) => {
    /* const tagElem = (
      <Tag
        closable={!_embed_}
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
        style={{ padding: 4, marginBottom: 3 }}
      >
        {tag.col} <b>{tag.operator}</b> {tag.condition}
      </Tag>
    ); */
    return (
      <span key={`${JSON.stringify(tag)}-${tags.indexOf(tag)}`}>
        {tag.logic && <span style={{color: '#ba0126',paddingRight: '5px',paddingLeft: '5px'}}>{tag.logic}</span>}
        <span style={{color: "#0f1ae8"}}>{tag.col}</span> <b style={{color: '#743f32'}}>{tag.operator}</b> {tag.condition}
      </span>
    );
  };

  const tagChild = tags.map(forMap);

  return (
    <Card bodyStyle={{ padding: "12px 0 0 5px", overflow: "hidden" }}>
      <Row wrap={false} align="top">
        <Col flex="none">
          <div style={{ padding: '0 7px', paddingRight: 5 }}>
            { chart === "Scorecard" &&
              <ModalColorPicker/>
            }
            <Button onClick={onShow} style={{ height: 30 }} disabled={_embed_}>
            ฟิลเตอร์
          </Button></div>
        </Col>
        <Col flex="auto">
          <div
            id="chart-filter-horizontal-scrollbar-style"
            className="chart-filter-tags"
          >
            {
              tags.length > 0 &&
              <Tag
                closable={!_embed_}
                onClose={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
                style={{ padding: 4, marginBottom: 3 }}
              >
                {tagChild}
              </Tag>
            }
          </div>
        </Col>
      </Row>
      {/* <Row align="top" style={{ flexFlow: "nowrap" }}>
        <Col>
          <Button onClick={onShow} style={{ height: 30 }} disabled={_embed_}>
            Filter
          </Button>
        </Col>
        <Col style={{ marginLeft: 4 }}>
          <div
            id="chart-filter-horizontal-scrollbar-style"
            className="chart-filter-tags"
          >
            {tagChild}
          </div>
        </Col>
      </Row> */}
      <Modal
        className="chart-filter-filter-modal"
        closeIcon={<CloseCircleOutlined />}
        open={visible}
        onCancel={onClose}
        footer={false}
        bodyStyle={{ paddingTop: 40, paddingLeft: 20 }}
        forceRender
        width={620}
      >
        <Row>
          <Col
            xs={{
              span: 24,
            }}
            md={{
              span: 3,
            }}
          >
            <p style={{ marginTop: 5 }}>ฟิลเตอร์</p>
          </Col>
          <Col
            xs={{
              span: 24,
            }}
            md={{
              span: 21,
            }}
          >
            <Form
              form={form}
              onFinish={onSubmitForm}
              size="small"
            >
              <Form.Item style={{ width: "100%", marginBottom: 0 }}>
                <div
                  id="chart-filter-scrollbar-style"
                  className="chart-filter-wrap-dynamic-item"
                >
                  <DynamicConditionFormItem formValue={formField} updateForm={updateForm} updateFormInput={updateFormInput} chart={chart} updateMapField={updateMapField}/>
                </div>
              </Form.Item>

              <Form.Item style={{ padding: '5px 0px', textAlign: "right", marginTop: 10, marginBottom: 0 }}>
                <Button type="primary" htmlType="submit">
                  ค้นหา
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </Card>
  );
};

export default ChartFilter;
