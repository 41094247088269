export const colSelect = {
  display: "inline-block",
  width: "33%",
  marginRight: 6,
  marginBottom: 10
};
export const operatorSelect = {
  display: "inline-block",
  width: "27%",
  marginRight: 6,
  marginBottom: 10
};
export const conditionInput = {
  display: "inline-block",
  width: "30%",
  marginRight: 12,
  marginBottom: 10
};
export const loadingIcon = { fontSize: 32 };
export const addButtonFormItem = { marginBottom: 0 };
export const addButton = { width: "100%" };
export const dropdownStyle = { zIndex: 1020 };
