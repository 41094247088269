import React from "react";
import "./App.css";
import "antd/dist/antd.css";
import { Switch, Route } from "react-router-dom";
import MainPage from "./pages/MainPage";
import ErrorWarning from "./pages/ErrorWarning";
import DataProvider from "./contexts/data";
import WebParameterProvider from "./contexts/webParameter";
import ExternalPage from "./pages/ExternalPage";

function App() {
  return (
    <div className="App">
      <WebParameterProvider>
        <DataProvider>
          <Switch>
            <Route exact path="/:dsname/:dataSource/:datasetId" component={MainPage} />
            <Route path="/:dsname/:dataSource/:datasetId/error" component={ErrorWarning} />
            <Route path="/externalfile" component={ExternalPage}/>
          </Switch>
        </DataProvider>
      </WebParameterProvider>
    </div>
  );
}

export default App;
