import * as L from "leaflet";

const accessToken = "pk.eyJ1IjoicGF0Y2hhcmFwb25rb25nY2hvcmIiLCJhIjoiY2s5NnU3YmN6MDRzNTNmcHFyanVweXl5MyJ9.BiG-R70J6Rhg2n4iSifHNA"

const attribution =
  "Map data &copy; <a href='https://www.openstreetmap.org/'>OpenStreetMap</a> contributors, <a href='https://creativecommons.org/licenses/by-sa/2.0/'>CC-BY-SA</a>, Imagery © <a href='https://www.mapbox.com/'>Mapbox</a>";
const apiUrl =
  "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}";
// const attribution = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
export const street = L.tileLayer( "https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
  attribution: attribution,
  maxZoom: 18,
  minZoom: 3,
  id: "mapbox/streets-v11",
  tileSize: 512,
  zoomOffset: -1,
  accessToken: accessToken,
});
export const light = L.tileLayer(apiUrl, {
  attribution: attribution,
  maxZoom: 18,
  minZoom: 3,
  id: "mapbox/light-v10",
  tileSize: 512,
  zoomOffset: -1,
  accessToken: accessToken,
});
export const dark = L.tileLayer(apiUrl, {
  attribution: attribution,
  maxZoom: 18,
  minZoom: 3,
  id: "mapbox/dark-v10",
  tileSize: 512,
  zoomOffset: -1,
  accessToken: accessToken,
});
export const outdoor = L.tileLayer(apiUrl, {
  attribution: attribution,
  maxZoom: 18,
  minZoom: 3,
  id: "mapbox/outdoors-v11",
  tileSize: 512,
  zoomOffset: -1,
  accessToken: accessToken,
});
export const satellite = L.tileLayer(apiUrl, {
  attribution: attribution,
  maxZoom: 18,
  minZoom: 3,
  id: "mapbox/satellite-v9",
  tileSize: 512,
  zoomOffset: -1,
  accessToken: accessToken,
});
