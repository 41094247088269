import React from "react";
import "./ChartChip.css";
import { Row, Col, Tooltip, Button } from "antd";
import PopconfirmAggregate from "../PopconfirmAggregate";
import { CloseOutlined } from "@ant-design/icons";

const ChartChip = ({
  embed,
  item,
  updateState,
  handleInterval,
  type,
  axisOption,
  hasCloseBtn,
  handleAggOption,
  allItemList,
  chart,
  readonly,
  hasNoFunctionAgg
}) => {
  const removeAttr = (item) =>{
    updateState(item, true);
  }
  const getName = (key) =>{
    if(key === "region"){
      return "ภาค"
    }
    else if(key === "province"){
      return "จังหวัด"
    }
    else if(key === "district"){
      return "อำเภอ"
    }
    else if(key === "sub_district"){
      return "ตำบล"
    }
    else{
      return key
    }

  }
  return (
    <Row align="middle" justify="start" style={{ width: "100%", flexWrap: 'nowrap' }}>
      <Col>{item.icon}</Col>
      {hasCloseBtn &&
      <Button className="remove-attr-btn" onClick={() => removeAttr(item)}><CloseOutlined /></Button>
      }
      {type === "horizontal" && !embed ? (
        <>
          <Col style={{overflow: 'hidden',width: '100%'}}>
            <Tooltip
              placement={type === "horizontal" ? "top" : "right"}
              title={
                item.agg === "" ? chart === "Heatmap" && item.newContent ? getName(item.newContent) : item.content : `${item.agg}(${chart === "Heatmap" && item.newContent ? getName(item.newContent) : item.content})`
              }
            >
              <div className="chart-layout-chip">
                {
                item.agg === ""
                  ? chart === "Heatmap" && item.newContent ? getName(item.newContent): item.content
                  : `${item.agg}(${chart === "Heatmap" && item.newContent ? getName(item.newContent) : item.content})`
                  }
              </div>
            </Tooltip>
          </Col>
          {
            !readonly &&
          <Col>
              <PopconfirmAggregate
                item={item}
                updateState={updateState}
                axisOption={axisOption}
                handleAggOption={handleAggOption}
                allItemList={allItemList}
                hasNoFunctionAgg={hasNoFunctionAgg}
              />
          </Col>
          }
        </>
      ) : (
        <Col style={{overflow: 'hidden', width: '100%'}}>
          <Tooltip
            placement={type === "horizontal" ? "top" : "right"}
            title={
              chart === "Heatmap" ?
                item.newContent ? getName(item.newContent) : item.content
              :
                item.agg === "" ? item.content : `${item.agg}(${item.content})`
            }
          >
            <div className="chart-layout-chip">
              {chart === "Heatmap" ?
                item.newContent ? getName(item.newContent) : item.content
              :
                item.agg === "" ? item.content : `${item.agg}(${item.content})`
              }
            </div>
          </Tooltip>
        </Col>
      )}
    </Row>
  );
};

export default React.memo(ChartChip);
