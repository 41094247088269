import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
  useMemo,
  useContext,
} from "react";
import "./MapView.css";
import { Card, Button, Radio } from "antd";

import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import "leaflet-extra-markers/dist/js/leaflet.extra-markers.min.js";
import "leaflet.featuregroup.subgroup";
import "leaflet-groupedlayercontrol/dist/leaflet.groupedlayercontrol.min";
import "leaflet-groupedlayercontrol/dist/leaflet.groupedlayercontrol.min.css";
import "leaflet.utm"
import "@fortawesome/fontawesome-free/css/all.css";
import * as MapCanvas from "../../config/mapLayers";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";

import { WebParameterContext } from "../../contexts/webParameter";
import { DataContext } from "../../contexts/data";

import MapGroupingModal from "../MapGroupingModal";
import LatLngSelect from "../LatLngSelect";
import "leaflet.heat/dist/leaflet-heat.js"


const MapView = forwardRef(({isFullScreen}, ref) => {
  const { _baseUrl_, _mapFilterStr_, _embed_, _currBaseMap_, _setCurrBaseMap_, _limitOffsetMap_, _setLimitOffsetMap_, _utmLocation_, _zoomLevel_, _showZoom_, _latitude_, _longitude_, _coordinate_, _showInfo_ , _showType_, _sharedTitle_, _showBack_, _showEdit_, _setCurrentMapLayer_, _currentMapLayer_} =
    useContext(WebParameterContext);
  const { _schema_, _isMapping_, _utfMapping_, _breakpoint_ } =
    useContext(DataContext);
  const [modeView, setModeView] = useState('LatLng');

  const history = useHistory();

  const [mapData, setMapData] = useState({ value: [], isUpdated: false });
  const [gModalVisible, setGModalVisible] = useState(false);
  const [columnLatLng, setColumnLatLng] = useState({
    lat: "",
    lng: "",
    isUpdated: false,
  });
  const [columnUtm, setColumnUTM] = useState({
    x: "",
    y: "",
    zone: "",
    isUpdated: false,
  });
  const [currData, setCurrData] = useState({ value: [], isUpdated: false })
  const [disabledPrev, setDisabledPrev] = useState(false)
  const [disabledNext, setDisabledNext] = useState(false)
  const [currLimitOffset, setCurrLimitOffset] = useState({
    offset: 0,
    limit: 1000
  })
  const [isMapPage, setIsMapPage] = useState(false)
  const [isMapText, setIsMapText] = useState(false)
  const [tooltip, setTooltip] = useState("")
  const { dsname, datasetId } = useParams();

  const isLat = (lat) => {
    return isFinite(lat) && Math.abs(lat) <= 90;
  };

  const isLng = (lng) => {
    return isFinite(lng) && Math.abs(lng) <= 180;
  };

  useEffect(()=>{
    if(_limitOffsetMap_.offset & _limitOffsetMap_.limit){
      setCurrLimitOffset({
        offset: _limitOffsetMap_.offset,
        limit: _limitOffsetMap_.limit,
      })
    }
    if(_utmLocation_.x && _utmLocation_.y && _utmLocation_.zone){
      setModeView("UTM")
    }
    // eslint-disable-next-line
  },[])
  // initial map data
  useEffect(() => {
    const callGetMapData = async () => {
      const filter = _mapFilterStr_ === "" ? "" : `(${_mapFilterStr_})`;

      try {
        let url=""
        if(columnLatLng.isUpdated && columnLatLng.lat !== "" && columnLatLng.lng !== "" && modeView === "LatLng"){
          let latStr = columnLatLng.lat;//check utm to use field utm query
          let lngStr = columnLatLng.lng;
          if (_isMapping_.value) {
            latStr = "";
            lngStr = "";
            Buffer.from(columnLatLng.lat, "utf-8").forEach((hex) => {
              latStr += `_${hex.toString(16).toUpperCase()}`;
            });
            Buffer.from(columnLatLng.lng, "utf-8").forEach((hex) => {
              lngStr += `_${hex.toString(16).toUpperCase()}`;
            });
          }
          url = `${_baseUrl_.value}/graphql?id=${datasetId}&dsname=${dsname}&query={records${filter}{${latStr}, ${lngStr}}}`
        }
        else if(columnUtm.isUpdated && columnUtm.x !== "" && columnUtm.y !== "" && columnUtm.zone !== "" && modeView === "UTM"){
          let xStr = columnUtm.x;
          let yStr = columnUtm.y;
          let zoneStr = columnUtm.zone;
          if (_isMapping_.value) {
            xStr = "";
            yStr = "";
            zoneStr = "";
            Buffer.from(columnUtm.x, "utf-8").forEach((hex) => {
              xStr += `_${hex.toString(16).toUpperCase()}`;
            });
            Buffer.from(columnUtm.y, "utf-8").forEach((hex) => {
              yStr += `_${hex.toString(16).toUpperCase()}`;
            });
            Buffer.from(columnUtm.zone, "utf-8").forEach((hex) => {
              zoneStr += `_${hex.toString(16).toUpperCase()}`;
            });
          }
          url = `${_baseUrl_.value}/graphql?id=${datasetId}&dsname=${dsname}&query={records${filter}{${xStr}, ${yStr}, ${zoneStr}}}`
        }
        if(url){
          let _mapData = await getMapData(url);
          if (_isMapping_.value) {
            _mapData = dataMapping(_mapData, _utfMapping_);
          }
          if(modeView === "UTM"){
            //map to lat lng
            _mapData.value = _mapData.value.map(elm => {
              if(elm[columnUtm.x] && elm[columnUtm.y] && elm[columnUtm.zone] >= 1 && elm[columnUtm.zone] <= 60){
                let item = L.utm({x: elm[columnUtm.x], y: elm[columnUtm.y], zone: elm[columnUtm.zone], southHemi: false});
                let coord = item.latLng();
                elm["utm_latitude"] = coord.lat
                elm["utm_longitude"] = coord.lng
                return elm
              }
              elm["utm_latitude"] = null
              elm["utm_longitude"] = null
              return elm
            })
          }
          setMapData(_mapData);
          if(currLimitOffset.offset === 0){
            setDisabledPrev(true)
          }
          else{
            setDisabledPrev(false)
          }
          let getData = _mapData.value.slice(currLimitOffset.offset, currLimitOffset.limit)
          setCurrData({value: getData, isUpdated: true})
          if(currLimitOffset.offset+getData.length < _mapData.value.length){
            setDisabledNext(false)
          }
          else if(currLimitOffset.offset+getData.length === _mapData.value.length){
            setDisabledNext(true)
          }
          else{
            setDisabledNext(true)
            setDisabledPrev(false)
          }
          if((mapRef.current.hasLayer(allMarkerGroupRef.current) || mapRef.current.hasLayer(layerClusterGroupRef.current)) || (!mapRef.current.hasLayer(allMarkerGroupRef.current) && !mapRef.current.hasLayer(layerClusterGroupRef.current) && !mapRef.current.hasLayer(layerGroupRef.current))){
            setIsMapText(true)
            setIsMapPage(false)
          }
          else{
            setIsMapPage(true)
            setIsMapText(false)
          }
        }
      } catch (error) {
        console.log("error >> ", error);
        history.push(window.location.pathname + "/error");
      }
    };
    if (
      _baseUrl_.isUpdated &&
      _isMapping_.isUpdated &&
      _utfMapping_.isUpdated &&
      (
        (
          columnLatLng.isUpdated &&
          columnLatLng.lat !== "" &&
          columnLatLng.lng !== "" &&
          modeView === "LatLng"
        )
        ||
        (
          columnUtm.isUpdated &&
          columnUtm.x !== "" &&
          columnUtm.y !== "" &&
          columnUtm.zone !== "" &&
          modeView === "UTM"
        )
      )
    ) {
      callGetMapData();
    }
    // eslint-disable-next-line
  }, [_baseUrl_, _mapFilterStr_, columnLatLng, _isMapping_, _utfMapping_, history, _schema_, _limitOffsetMap_, columnUtm]);

  // init map
  const mapRef = React.useRef(null);
  const mainControlRef = React.useRef(null);
  useEffect(() => {
    let currBase;
    switch (_currBaseMap_) {
      case "light-v10":
        currBase = MapCanvas.light;
        break;
      case "dark-v10":
        currBase = MapCanvas.dark;
        break;
      case "outdoors-v11":
        currBase = MapCanvas.outdoor;
        break;
      case "satellite-v9":
        currBase = MapCanvas.satellite;
        break;
      default:
        currBase = MapCanvas.street;
        break;
    }
    mapRef.current = L.map("map-view", {
      center: _coordinate_ ? [_latitude_, _longitude_] : [11, 102],
      zoom: _zoomLevel_ ? Number(_zoomLevel_) : 6,
      layers: currBase,
      attributionControl: false,
      zoomControl: _showZoom_
    });

    // add base layers
    const baseLayers = {
      Streets: MapCanvas.street,
      Light: MapCanvas.light,
      Dark: MapCanvas.dark,
      Outdoor: MapCanvas.outdoor,
      Satellite: MapCanvas.satellite,
    };
    const groupedOverlays = { ข้อมูล: { } };

    mainControlRef.current = L.control
      .groupedLayers(baseLayers, groupedOverlays, {
        position: "topright",
        exclusiveGroups: ["ข้อมูล"],
        collapsed: false,
        // groupCheckboxes: true,
      })
      .addTo(mapRef.current);
      if(!_showType_){
        document.getElementsByClassName('leaflet-control-layers')[0].style.visibility = 'hidden'
      }
    mapRef.current.on("baselayerchange", (e) => {
      const base = e.layer.options.id;
      _setCurrBaseMap_(base.substring(base.indexOf("/") + 1, base.length));
    });
    mapRef.current.on("overlayadd", (e) => {
      /*if(e.name === "นำข้อมูลออก"){
        setIsMapPage(false)
        setIsMapText(false)
      }
      else */
      // setCurrentSelectedLayer(e.name)
      _setCurrentMapLayer_(e.name)
      if(e.name === "Circle" || e.name === "Cluster" || e.name === "Heat map"){
        setIsMapText(true)
        setIsMapPage(false)
      }
      else{
        setIsMapPage(true)
        setIsMapText(false)
      }
    });

    // custom control
    L.Control.Custom = L.Control.Layers.extend({
      options: {
        className: undefined,
      },
      onAdd: function () {
        this._initLayout();
        this._addButton();
        this._update();
        return this._container;
      },
      _initLayout: function () {
        L.Control.Layers.prototype._initLayout.call(this);
        if (this.options.className) {
          L.DomUtil.addClass(this._layersLink, this.options.className);
        }
      },
      _addButton: function () {
        const elements = this._container.getElementsByClassName(
          "leaflet-control-layers-list"
        );
        const button = L.DomUtil.create(
          "button",
          "map-view-create-data-group-button",
          elements[0]
        );
        button.textContent = "สร้างกลุ่มข้อมูล";
        L.DomEvent.on(
          button,
          "click",
          (e) => {
            setGModalVisible(true);
          },
          this
        );
      },
    });

    //create control from custom control
    !_embed_ &&
      new L.Control.Custom(null, null, {
        position: "topright",
        className: "map-view-create-group-control-layer",
        collapsed: false,
      }).addTo(mapRef.current);

    //re icon
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
    // eslint-disable-next-line
  }, [_embed_, _setCurrBaseMap_]);

  const layerGroupRef = React.useRef(null);
  useEffect(() => {
    if(_currentMapLayer_ === "พิกัด"){
      layerGroupRef.current = L.layerGroup().addTo(mapRef.current);
    }
    else{
      layerGroupRef.current = L.layerGroup()
    }
    mainControlRef.current.addOverlay(
      layerGroupRef.current,
      "พิกัด",
      "ข้อมูล"
    );
  // eslint-disable-next-line
  }, []);

  const layerClusterGroupRef = React.useRef(null);
  const allMarkerGroupRef = React.useRef(null);
  const heatLayerRef = React.useRef(null);
  useEffect(() => {
    if(_currentMapLayer_ === "Circle"){
      allMarkerGroupRef.current = L.layerGroup().addTo(mapRef.current)
    }
    else{
      allMarkerGroupRef.current = L.layerGroup()
    }
    mainControlRef.current.addOverlay(
      allMarkerGroupRef.current,
      "Circle",
      "ข้อมูล"
    );
    // layerClusterGroupRef.current = L.markerClusterGroup({
    //   chunkedLoading: true,
    // }).addTo(mapRef.current);

    if(_currentMapLayer_ === "Cluster"){
      layerClusterGroupRef.current = L.markerClusterGroup().addTo(mapRef.current)
    }
    else{
      layerClusterGroupRef.current = L.markerClusterGroup()
    }
    mainControlRef.current.addOverlay(
      layerClusterGroupRef.current,
      "Cluster",
      "ข้อมูล"
    );

    if(_currentMapLayer_ === "Heat map"){
      heatLayerRef.current = L.heatLayer([], {radius: 30}).addTo(mapRef.current)
    }
    else{
      heatLayerRef.current = L.heatLayer([], {radius: 30})
    }
    mainControlRef.current.addOverlay(
      heatLayerRef.current,
      "Heat map",
      "ข้อมูล"
    );
  // eslint-disable-next-line
  }, []);

  // add marker cluster group to map
  // const allMarkerGroupRef = React.useRef(null);
  const onClickMarker = async (e) => {
    let url
    if(modeView === "LatLng"){
      let latStr = columnLatLng.lat;
      let lngStr = columnLatLng.lng;
      if (_isMapping_.value) {
        latStr = "";
        lngStr = "";
        Buffer.from(columnLatLng.lat, "utf-8").forEach((hex) => {
          latStr += `_${hex.toString(16).toUpperCase()}`;
        });
        Buffer.from(columnLatLng.lng, "utf-8").forEach((hex) => {
          lngStr += `_${hex.toString(16).toUpperCase()}`;
        });
      }
      url = `${_baseUrl_.value}/graphql?id=${datasetId}&dsname=${dsname}&query={records(${latStr}: "=${e.latlng.lat}", ${lngStr}: "=${e.latlng.lng}")${tooltip}}`
    }
    else{
      let xStr = columnUtm.x;
      let yStr = columnUtm.y;
      let zoneStr = columnUtm.zone;
      if (_isMapping_.value) {
        xStr = "";
        yStr = "";
        zoneStr = "";
        Buffer.from(columnUtm.x, "utf-8").forEach((hex) => {
          xStr += `_${hex.toString(16).toUpperCase()}`;
        });
        Buffer.from(columnUtm.y, "utf-8").forEach((hex) => {
          yStr += `_${hex.toString(16).toUpperCase()}`;
        });
        Buffer.from(columnUtm.zone, "utf-8").forEach((hex) => {
          zoneStr += `_${hex.toString(16).toUpperCase()}`;
        });
      }
      url = `${_baseUrl_.value}/graphql?id=${datasetId}&dsname=${dsname}&query={records(${xStr}: "=${e.sourceTarget.options[columnUtm.x]}", ${yStr}: "=${e.sourceTarget.options[columnUtm.y]}", ${zoneStr}: "=${e.sourceTarget.options[columnUtm.zone]}")${tooltip}}`
    }
    if(url){
      let dataInfo = await getMapData(url);
      // let dataInfo = await dataInfoResponse.data.data.records;
      if (_isMapping_.value) {
        dataInfo = dataMapping(dataInfo, _utfMapping_);
      }
      let content = "<div class='map-view-marker-popup-wraper'>";
      Object.keys(dataInfo.value[0]).forEach((key) => {
        if (key !== "uuid") {
          content += `<b>${key}</b>: ${dataInfo.value[0][key]}<br/>`;
        }
      });
      content += "</div>";
      e.target.setPopupContent(content);
    }
  };
  useEffect(() => {
    const addMarkerToClusterGroup = (dataLst) => {
      const markerList = [];
      const circleMarkerList = [];
      layerClusterGroupRef.current.clearLayers()
      allMarkerGroupRef.current.clearLayers()
      var myRenderer = L.canvas({ padding: 0.5 });
      for (let i = 0; i < dataLst.length; i++) {
        if (
          (
            dataLst[i][columnLatLng.lat] && dataLst[i][columnLatLng.lng] &&
            isLat(dataLst[i][columnLatLng.lat]) &&
            isLng(dataLst[i][columnLatLng.lng]) &&
            modeView === "LatLng"
          )
          ||
          (
            dataLst[i]["utm_latitude"] && dataLst[i]["utm_longitude"] &&
            isLat(dataLst[i]["utm_latitude"]) &&
            isLng(dataLst[i]["utm_longitude"]) &&
            modeView === "UTM"
          )
        ) {
          let lat, lng
          if(modeView === "LatLng"){
            lat = dataLst[i][columnLatLng.lat]
            lng = dataLst[i][columnLatLng.lng]
          }
          else{
            lat = dataLst[i]["utm_latitude"]
            lng = dataLst[i]["utm_longitude"]
          }
          markerList.push(
            L.marker([
              lat,
              lng,
            ],
            dataLst[i]
            )
              .bindPopup('<div>Loading...</div>')
              .on('click', onClickMarker).addTo(layerClusterGroupRef.current)
          );
          circleMarkerList.push(
            L.circleMarker(
              [lat, lng],
              { renderer: myRenderer, color: "#3388ff", ...dataLst[i] }
            )
              .bindPopup("<div>Loading...</div>")
              .on("click", onClickMarker).addTo(allMarkerGroupRef.current)
              
          );
        }
      }
      /* if (!isInitControl) {
        setIsInitControl(true);
      //   // allMarkerGroupRef.current = L.layerGroup(circleMarkerList).addTo(mapRef.current)
      //   mainControlRef.current.addOverlay(
      //     allMarkerGroupRef.current,
      //     "ข้อมูลทั้งหมด",
      //     "ข้อมูล"
      //   );

        const clusterAllGroup = L.featureGroup.subGroup(
          layerClusterGroupRef.current,
          markerList
        );
        mainControlRef.current.addOverlay(
          clusterAllGroup,
          "คลัสเตอร์ข้อมูลทั้งหมด",
          "ข้อมูล"
        );
      } */
    };
    if (
      mapData.isUpdated &&
      layerClusterGroupRef.current !== null &&
      (
        (
          columnLatLng.isUpdated &&
          columnLatLng.lat !== "" &&
          columnLatLng.lng !== "" &&
          modeView === "LatLng"
        )
        ||
        (
          columnUtm.isUpdated &&
          columnUtm.x !== "" &&
          columnUtm.y !== "" &&
          columnUtm.zone !== "" &&
          modeView === "UTM"
        )
      )
    ) {
      try {
        addMarkerToClusterGroup(mapData.value);
      } catch (error) {
        console.log("ERROR NO LAT/LONG >> ", error);
      }
    }
    // eslint-disable-next-line
  }, [mapData, layerClusterGroupRef, columnLatLng, columnUtm]);

  //add heatmap
  useEffect(() => {
    const addHeatmapLayer = (dataLst) => {
      const heatList = []
      for (let i = 0; i < dataLst.length; i++) {
        if (
          (
            dataLst[i][columnLatLng.lat] && dataLst[i][columnLatLng.lng] &&
            isLat(dataLst[i][columnLatLng.lat]) &&
            isLng(dataLst[i][columnLatLng.lng]) &&
            modeView === "LatLng"
          )
          ||
          (
            dataLst[i]["utm_latitude"] && dataLst[i]["utm_longitude"] &&
            isLat(dataLst[i]["utm_latitude"]) &&
            isLng(dataLst[i]["utm_longitude"]) &&
            modeView === "UTM"
          )
        ) {
          let lat, lng
          if(modeView === "LatLng"){
            lat = dataLst[i][columnLatLng.lat]
            lng = dataLst[i][columnLatLng.lng]
          }
          else{
            lat = dataLst[i]["utm_latitude"]
            lng = dataLst[i]["utm_longitude"]
          }
          heatList.push(
            [lat,lng]
          )
        }
      }
      heatLayerRef.current.setLatLngs(heatList)
    };
    if (
      mapData.isUpdated &&
      heatLayerRef.current !== null &&
      _currentMapLayer_ === "Heat map" &&
      (
        (
          columnLatLng.isUpdated &&
          columnLatLng.lat !== "" &&
          columnLatLng.lng !== "" &&
          modeView === "LatLng"
        )
        ||
        (
          columnUtm.isUpdated &&
          columnUtm.x !== "" &&
          columnUtm.y !== "" &&
          columnUtm.zone !== "" &&
          modeView === "UTM"
        )
      )
    ) {
      try {
        if(document.getElementsByClassName("leaflet-heatmap-layer").length > 0){
          document.getElementsByClassName("leaflet-heatmap-layer")[0].style.display = "block"
        }
        addHeatmapLayer(mapData.value);
      } catch (error) {
        console.log("ERROR NO LAT/LONG >> ", error);
      }
    }
    // eslint-disable-next-line
  }, [mapData, heatLayerRef, columnLatLng, columnUtm, _currentMapLayer_]);
  // add marker group to map
  useEffect(() => {
    const addMarkerToLayerGroup = (dataLst) => {
      layerGroupRef.current.clearLayers();

      for (let i = 0; i < dataLst.length; i++) {
        /* let content = "";
        Object.keys(dataLst[i]).forEach((key) => {
          if (key !== "uuid") {
            content += `<b>${key}</b>: ${dataLst[i][key]}<br/>`;
          }
        }); */
        if (
          (
            dataLst[i][columnLatLng.lat] && dataLst[i][columnLatLng.lng] &&
            isLat(dataLst[i][columnLatLng.lat]) &&
            isLng(dataLst[i][columnLatLng.lng]) &&
            modeView === "LatLng"
          )
          ||
          (
            dataLst[i]["utm_latitude"] && dataLst[i]["utm_longitude"] &&
            isLat(dataLst[i]["utm_latitude"]) &&
            isLng(dataLst[i]["utm_longitude"]) &&
            modeView === "UTM"
          )
        ) {
          let lat, lng
          if(modeView === "LatLng"){
            lat = dataLst[i][columnLatLng.lat]
            lng = dataLst[i][columnLatLng.lng]
          }
          else{
            lat = dataLst[i]["utm_latitude"]
            lng = dataLst[i]["utm_longitude"]
          }
          L.marker([lat, lng],dataLst[i])
          .bindPopup("<div>Loading...</div>")
          .on("click", onClickMarker)
            .addTo(layerGroupRef.current);
        }
      }
    };

    if (
      currData.isUpdated &&
      layerGroupRef.current !== null &&
      (
        (
          columnLatLng.isUpdated &&
          columnLatLng.lat !== "" &&
          columnLatLng.lng !== "" &&
          modeView === "LatLng"
        )
        ||
        (
          columnUtm.isUpdated &&
          columnUtm.x !== "" &&
          columnUtm.y !== "" &&
          columnUtm.zone !== "" &&
          modeView === "UTM"
        )
      )
    ) {
      try {
        addMarkerToLayerGroup(currData.value);
      } catch (error) {
        console.log("ERROR NO LAT/LONG >> ", error);
      }
    }
    // eslint-disable-next-line
  }, [currData, layerGroupRef, columnLatLng, columnUtm]);

  useEffect(()=>{
    if(_currentMapLayer_ === "Heat map"){
      if(
        (
          (
            !columnLatLng.isUpdated &&
            columnLatLng.lat === "" &&
            columnLatLng.lng === "" &&
            modeView === "LatLng"
          )
          ||
          (
            !columnUtm.isUpdated &&
            columnUtm.x === "" &&
            columnUtm.y === "" &&
            columnUtm.zone === "" &&
            modeView === "UTM"
          )
        )
      )
      {
        if(document.getElementsByClassName("leaflet-heatmap-layer").length > 0){
          document.getElementsByClassName("leaflet-heatmap-layer")[0].style.display = "none"
        }
      }
      else{
        if(document.getElementsByClassName("leaflet-heatmap-layer").length > 0){
          document.getElementsByClassName("leaflet-heatmap-layer")[0].style.display = "block"
        }
      }
    }
  },[_currentMapLayer_,columnLatLng, columnUtm, modeView])
  useImperativeHandle(ref, () => ({
    reRenderMap() {
      setTimeout(() => {
        mapRef.current.invalidateSize();
      }, 0);
    },
  }));

  const onCancelModal = () => {
    setGModalVisible(false);
  };


  return useMemo(() => {
    const updateMapData = (currState) =>{
      let offset = 0, limit = 0
      if(currState === "next"){
        offset = currLimitOffset.offset + 1000
        limit = currLimitOffset.limit + 1000
        setCurrLimitOffset({offset: offset, limit: limit})
      }else{
        offset = currLimitOffset.offset - 1000
        limit = currLimitOffset.limit - 1000
        setCurrLimitOffset({offset: offset, limit: limit})
      }
      if(offset === 0){
        setDisabledPrev(true)
      }
      else{
        setDisabledPrev(false)
      }
      let getData = mapData.value.slice(offset, limit)
      if(offset+getData.length < mapData.value.length){
        setDisabledNext(false)
      }
      else if(offset+getData.length === mapData.value.length){
        setDisabledNext(true)
      }
      else{
        setDisabledNext(true)
        setDisabledPrev(false)
      }
      setCurrData({value: getData, isUpdated: true})
      _setLimitOffsetMap_({offset: offset, limit: limit})
    }
    const onFinishModal = (values) => {
      if (columnLatLng.lat === "" && columnLatLng.lng === "") {
        return;
      }

      if (
        values["DynamicMapGroupingFormList"] === undefined ||
        values["DynamicMapGroupingFormList"].length === 0
      ) {
        return;
      }
      let overlayMaps = {};
      const groupName = values["groupName"];
      const dataLst = values["DynamicMapGroupingFormList"];
      dataLst.forEach((subGroup) => {
        const sgName = subGroup["name"];
        const dLst = subGroup["data"];
        const color = subGroup["color"];

        let mLst = [];
        dLst.forEach((d) => {
          const marker = L.ExtraMarkers.icon({
            svg: true,
            markerColor: color,
            shape: "circle",
            prefix: "fa",
            icon: "fa-circle",
          });
          if (isLat(d[columnLatLng.lat]) && isLng(d[columnLatLng.lng])) {
            mLst.push(
              L.marker([d[columnLatLng.lat], d[columnLatLng.lng]], {
                icon: marker,
              })
            );
          }
        });

        const subGroupLayer = L.layerGroup(mLst);
        overlayMaps[sgName] = subGroupLayer;
      });

      const overlaysGroup = {};
      overlaysGroup[groupName] = overlayMaps;
      new L.control.groupedLayers(null, overlaysGroup, {
        position: "topright",
        groupCheckboxes: true,
        collapsed: false,
      }).addTo(mapRef.current);

      setGModalVisible(false);
    };

    const updateLatLong = (loc) => {
      if(modeView === "LatLng"){
        if(loc.lat && loc.lng){
          setColumnLatLng({ lat: loc.lat, lng: loc.lng, isUpdated: true });
        }
        else{
          setCurrData({ value: [], isUpdated: true })
          setMapData({ value: [], isUpdated: true })
          setIsMapPage(false)
          setIsMapText(false)
          layerGroupRef.current.clearLayers();
          allMarkerGroupRef.current.clearLayers()
          layerClusterGroupRef.current.clearLayers()
          // if current checked heatmap be true
          if(document.getElementsByClassName("leaflet-heatmap-layer").length > 0){
            document.getElementsByClassName("leaflet-heatmap-layer")[0].style.display = "none"
          }
        }
      }
      else{
        if(loc.x && loc.y && loc.zone){
          setColumnUTM({x: loc.x, y: loc.y, zone: loc.zone, isUpdated: true})
        }
        else{
          setCurrData({ value: [], isUpdated: true })
          setMapData({ value: [], isUpdated: true })
          setIsMapPage(false)
          setIsMapText(false)
          layerGroupRef.current.clearLayers();
          allMarkerGroupRef.current.clearLayers()
          layerClusterGroupRef.current.clearLayers()
          // if current checked heatmap be true
          if(document.getElementsByClassName("leaflet-heatmap-layer").length > 0){
            document.getElementsByClassName("leaflet-heatmap-layer")[0].style.display = "none"
          }
        }
      }
    };
    const updateTooltipSelected = (val) =>{
      let tooltipField = ""
      val.forEach((element,index) => {
        let _elm = element
        if (_isMapping_.value) {
          _elm = ""
          Buffer.from(element, "utf-8").forEach((hex) => {
            _elm += `_${hex.toString(16).toUpperCase()}`;
          });
        }
        tooltipField += `${index === 0 ? "{":""}${_elm},${index === val.length - 1 ? "}":""}`
      });
      setTooltip(tooltipField)
    }

    /* const cardWidth = () => {
      if (_breakpoint_ === "xxl") {
        return "35vw";
      } else if (_breakpoint_ === "xl") {
        return "50vw";
      } else if (_breakpoint_ === "lg") {
        if (window.innerWidth < window.innerHeight) {
          return "98vw";
        } else {
          return "50vw";
        }
      } else if (_breakpoint_ === "md") {
        if (window.innerWidth < window.innerHeight) {
          return "97vw";
        } else {
          return "50vw";
        }
      } else {
        return "95vw";
      }
    }; */

    /* const cardTitleWidth = () => {
      return window.innerWidth - 100;
    }; */
    const modeOptions = [
      {
        label: 'Lat/Long',
        value: 'LatLng',
      },
      {
        label: 'UTM',
        value: 'UTM',
      },
    ];
    const onChangeMode = ({ target: { value } }) => {
      setModeView(value);
    };

    return (
      <div className={isFullScreen || (_sharedTitle_ === "" && !_showEdit_ && !_showBack_) ? "MapViewFullScreen" : "MapView"} style={{height: _embed_ && (isFullScreen || ( _sharedTitle_ === "" && !_showEdit_ && !_showBack_) ? "100vh" : "calc(100vh - 48px)")}}>
        <div id="map-view"></div>
        {
          isMapPage && mapData.value.length > 0 && _showInfo_ &&
          <Card style={{zIndex: '999',position: 'absolute',bottom: '0', left: '10px'}} bodyStyle={{ padding: 7 }}>
            <span style={{marginRight: '5px'}}>แสดง {new Intl.NumberFormat().format(currLimitOffset.offset+1)} - {new Intl.NumberFormat().format(currLimitOffset.offset+currData.value.length)} จาก {new Intl.NumberFormat().format(mapData.value.length)} รายการ</span>
            <Button onClick={() => updateMapData("prev")} disabled={disabledPrev}>ก่อนหน้า</Button>
            <Button onClick={() => updateMapData("next")} disabled={disabledNext}>ถัดไป</Button>
          </Card>
        }
        {
          isMapText && mapData.value.length > 0 && _showInfo_ &&
          <Card style={{zIndex: '999',position: 'absolute',bottom: '0', left: '10px'}} bodyStyle={{ padding: "12px 7px"}}>
            <span style={{marginRight: '5px'}}>แสดง {new Intl.NumberFormat().format(mapData.value.length)} รายการ</span>
          </Card>
        }
        {_schema_.isUpdated &&
          _isMapping_.isUpdated &&
          _utfMapping_.isUpdated &&(
            <>
              <LatLngSelect
                schema={_schema_}
                isMapping={_isMapping_}
                utfMapping={_utfMapping_}
                updateLatLong={updateLatLong}
                breakpoint={_breakpoint_}
                updateTooltipSelected={updateTooltipSelected}
                currentMode={modeView}
              />
              {!_embed_ &&
              <div style={{position: 'absolute', left: 55, top: 10, zIndex: 999}} className="radio-custom">
                <Radio.Group options={modeOptions} onChange={onChangeMode} value={modeView} optionType="button" />
              </div>
              }
            </>
          )}
        {/* {_embed_ && dataInfo.isUpdated && (
          <>
            <Card
              style={{
                position: "absolute",
                bottom: 55,
                left: 10,
                zIndex: 999,
                borderRadius: 6,
                width: cardWidth(),
              }}
            >
              <MapFilter />
            </Card>
            <div
              style={{
                position: "absolute",
                top: 10,
                left: 60,
                zIndex: 999,
                borderRadius: 6,
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                paddingRight: 10,
                paddingTop: 3,
                display: "inline-block",
                textAlign: "left",
                width: cardTitleWidth(),
              }}
            >
              <Title
                level={4}
                ellipsis={true}
                style={{ marginLeft: 10, marginBottom: 0, color: "#fff" }}
              >
                {`${dataInfo.value.resource_title}(${dataInfo.value.package_title})`}
              </Title>
              <Title
                ellipsis={true}
                style={{
                  fontSize: 16,
                  marginLeft: 10,
                  marginTop: 0,
                  color: "#fff",
                }}
              >
                อ้างอิงชุดข้อมูล:
                <a href={dataInfo.value.ref_url}> {dataInfo.value.ref_url}</a>
              </Title>
            </div>
          </>
        )} */}

        <MapGroupingModal
          visible={gModalVisible}
          onCancel={onCancelModal}
          onFinishModal={onFinishModal}
        />
      </div>
    );
  }, [
    _breakpoint_,
    _schema_,
    _isMapping_,
    _utfMapping_,
    columnLatLng,
    gModalVisible,
    setColumnLatLng,
    disabledPrev,
    disabledNext,
    currLimitOffset,
    setCurrLimitOffset,
    mapData,
    currData,
    isMapPage,
    _setLimitOffsetMap_,
    isMapText,
    modeView,
    isFullScreen,
    _embed_,
    _showInfo_,
    _sharedTitle_,
    _showBack_,
    _showEdit_
  ]);
});

export default React.memo(MapView);

const getMapData = async (url) => {
  return await axios
    .get(url)
    .then(async (res) => {
      const mapData = await res.data.data.records;
      return { value: mapData, isUpdated: true };
    })
    .catch((error) => console.log("error >> ", error));
};

const dataMapping = (data, utfMapping) => {
  const newData = [];
  data.value.forEach((item) => {
    let mapped = Object.keys(item).reduce((acc, key) => {
      acc[utfMapping.value[key]] = item[key];
      return acc;
    }, {});
    newData.push(mapped);
  });
  data.value = newData;
  return data;
};
