import React, { useState } from "react";
import { Card, Button, Form, Input, Spin, Space } from "antd";
import axios from "axios";
import Swal from 'sweetalert2'

const ExternalPage = () =>{
    const [form] = Form.useForm();
    const [submitLoading, setSubmitLoading] = useState(false)
    const onSubmitForm = async () => {
        const form_data = form.getFieldsValue()
        setSubmitLoading(true)
        try{
            const result = await getData(`${process.env.REACT_APP_API_HOST}/ckan/resource/graphql?url=${form_data.file_url}&resource_title=${form_data.resource_title}&package_title=${form_data.resource_title}`)
            if(result.status === 200){
                Swal.fire({
                    text: "สำเร็จ",
                    icon: "success",
                    timer: 3000, //timeOut for auto-close
                    confirmButtonText: "ปิด",
                    confirmButtonColor: '#1890ff',
                }).then(() => {
                    setSubmitLoading(false)
                    setTimeout(()=>window.location = `/${result.data.dsname}/ckan/${result.data.id}`, 500)
                });
            }
        }
        catch(error){
            setSubmitLoading(false)
        }
    };
    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', padding: '50px', background: '#ededee'}}>
            <Card title="อัพโหลดชุดข้อมูล" style={{width: '100%',}} headStyle={{textAlign: 'left', fontSize: '20px'}}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onSubmitForm}
                    autoComplete="off"
                >
                    <Form.Item
                        name="resource_title"
                        label="ชื่อชุดข้อมูล"
                        rules={[
                            {
                                required: true,
                                message: "กรุณากรอกข้อมูล"
                            },
                        ]}
                    >
                        <Input type="text" placeholder="ระบุชื่อชุดข้อมูล" />
                    </Form.Item>
                    <Form.Item
                        name="file_url"
                        label="URL"
                        rules={[
                            {
                                required: true,
                                message: "กรุณากรอกข้อมูล"
                            },
                            {
                                type: 'url',
                                message: "รูปแบบ url ไม่ถูกต้อง"
                                // warningOnly: true,
                            },
                        ]}
                    >
                        <Input type="text" placeholder="ระบุ url ในรูปแบบ excel หรือ csv" />
                    </Form.Item>
                    <Form.Item style={{marginTop: '2rem'}}>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                บันทึก
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
                <div style={{fontSize: '12px', textAlign: 'left', marginTop: '-10px'}}>
                    <b>หมายเหตุ: </b>ข้อมูลจำนวนไม่เกิน 300,000 รายการ
                </div>
            </Card>
            {
                submitLoading &&
                <div className="loading-wrapper">
                    <div className="bg-loading" style={{opacity: '0.5'}}></div>
                    <Spin size="large" className="loading-spin" />
                </div>
            }
        </div>
    );
}

export default ExternalPage;
const getData = async (url) => {
    return await axios
      .get(url)
      .then((res) => {
        return res
      })
      .catch((error) => {
        console.log("error >> ", error.response)
        if(error.response.status === 500){
            let msg = ""
            if(error.response.data === "data > 300,000")
                msg = "จำนวนข้อมูลเกิน 300,000 รายการ"
            else if(error.response.data === "Invalid format")
                msg = "รูปแบบไฟล์ไม่ถูกต้อง"
            Swal.fire({
                html: `<h3>เกิดข้อผิดพลาด!</h3><div>${msg}</div>`,
                timer: 5000,
                icon: 'error',
                confirmButtonColor: '#1890ff',
                confirmButtonText: 'ปิด'
            })
        }
      });
}